const postMessage = (state: string) => {
  window.parent.postMessage(
    {
      location: null,
      query: {
        state,
      },
      url: null,
    },
    '*',
  );
};

export default postMessage;
