export type File = {
  id: string;
  downloadInProgress: boolean;
  errorMessage: string;
  fileUrl?: string;
};

export interface IIdentificationFileInitialState {
  files: File[];
  downloadedFileUrl: string;
}

const identificationFileInitialState: IIdentificationFileInitialState = {
  files: [],
  downloadedFileUrl: '',
};

export default identificationFileInitialState;
