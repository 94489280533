import { useDispatch } from 'react-redux';
import {
  navigateToBankIdentStart,
  navigateToVideoIdentStart,
} from 'redux/actions/navigationActions';
import {
  useConfigSelector,
  useIdentificationMethodsSelector,
  useMobileNumberSelector,
  useSessionSelector,
} from 'redux/selectors';
import postMessage from 'utils/postMessage';

const useStart = () => {
  const dispatch = useDispatch();

  const identificationMethods = useIdentificationMethodsSelector();
  const session = useSessionSelector();
  const config = useConfigSelector();
  const mobileNumber = useMobileNumberSelector();

  const handleStartVideoIdent = () => {
    dispatch(navigateToVideoIdentStart());
  };

  const handleStartBankIdent = () => {
    dispatch(navigateToBankIdentStart());
  };

  const handleCallbackClick = () => {
    postMessage('cancel');

    const { callbackUrl } = session;
    window.location.assign(callbackUrl);
  };

  const hasMobileNumber = mobileNumber.number?.length > 0;

  const bankIdentAvailable = identificationMethods.bank && hasMobileNumber;

  return {
    handleStartVideoIdent,
    handleCallbackClick,
    handleStartBankIdent,
    bankIdentAvailable,
    errorMessages: [config.errorMessage, session.errorMessage],
  };
};

export default useStart;
