import { createIntl, createIntlCache } from 'react-intl';
import { downloadDocument } from 'api/endpoints';
import {
  FILE_DOWNLOAD_INIT,
  FILE_SHARE_INIT,
  fileDownloadError,
  fileDownloadSuccess,
  fileShareError,
  fileShareSuccess,
  IFileDownloadAction,
} from 'redux/actions/identificationFileActions';
import { configSelector, sessionSelector } from 'redux/selectors';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { loadLocaleData } from 'utils/locales';

export function* getIdentificationFile(
  action: IFileDownloadAction,
): SagaIterator {
  try {
    const { baseUrl, sessionToken } = yield select(configSelector);

    const { requestMethod, getUrl, getErrorCode } = downloadDocument;

    const response = yield call(
      requestMethod,
      getUrl(baseUrl, action.data?.id),
      sessionToken,
    );

    if (response.errors) {
      throw new Error(getErrorCode(response.errors));
    }
    let fileBlob = new Blob([response], {
      type: 'application/pdf',
    });

    action.type === FILE_DOWNLOAD_INIT
      ? yield call(fileDownloadOrView, action, fileBlob)
      : yield call(fileShare, action, fileBlob);
  } catch (error) {
    yield put(fileDownloadError({ message: error.message }, action.data?.id!));
  }
}

function* fileShare(action: IFileDownloadAction, fileBlob: Blob) {
  const { language } = yield select(sessionSelector);
  const { formatMessage } = createIntl(
    { locale: language, messages: loadLocaleData(language) },
    createIntlCache(),
  );

  const shareData = {
    files: [
      new File([fileBlob], action.data?.fileName || 'document_preview.pdf', {
        type: fileBlob.type,
      }),
    ],
  };

  if (window.navigator.canShare && window.navigator.canShare(shareData)) {
    window.navigator
      .share(shareData)
      .then(yield put(fileShareSuccess(action.data?.id!)));
  } else {
    yield put(
      fileShareError(
        { message: formatMessage({ id: 'error.shareError' }) },
        action.data?.id!,
      ),
    );
  }
}

function* fileDownloadOrView(action: IFileDownloadAction, fileBlob: Blob) {
  const fileUrl = window.URL.createObjectURL(fileBlob) || '';

  if (typeof window?.URL?.createObjectURL === 'function') {
    window.open(fileUrl, action.data?.fileName || 'document_preview.pdf');
  }

  yield put(fileDownloadSuccess(action.data?.id!, fileUrl));
}

function* watchFileDownloadInit() {
  yield takeLatest(FILE_DOWNLOAD_INIT, getIdentificationFile);
}

function* watchFileShareInit() {
  yield takeLatest(FILE_SHARE_INIT, getIdentificationFile);
}

export {
  watchFileDownloadInit as fileDownloadSaga,
  watchFileShareInit as fileShareSaga,
};
