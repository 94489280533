import { ActionState } from 'redux/types';

export interface IConfigInitialState {
  state: ActionState;
  baseUrl: string;
  sessionToken: string;
  errorMessage: string;
  isMobile: boolean;
  showSkip: boolean;
}

const configInitialState: IConfigInitialState = {
  state: ActionState.IDLE,
  baseUrl: '',
  sessionToken: '',
  errorMessage: '',
  isMobile: true,
  showSkip: false,
};

export default configInitialState;
