import styled from 'styled-components';

export const StyledLayout = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 1.5rem;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: stretch;
    min-height: auto;
    padding-bottom: 0;
  }
`;

export const StyledContent = styled.div`
  background-color: ${({ theme }) => theme.baseColors.white};
  display: block;
  min-width: 32rem;
  padding: 2rem;
  border-radius: ${({ theme }) => theme.cardBorderRadius};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    border-radius: 0;
    min-width: 100%;
    width: 100%;
  }
`;
