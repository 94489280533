// These actions outline the process within the app for the continuation flow
export const RECOVER_IDENT_PROCESS_INIT = 'RECOVER_IDENT_PROCESS_INIT';
export const RECOVER_IDENT_PROCESS_SUCCESS = 'RECOVER_IDENT_PROCESS_SUCCESS';
export const RECOVER_IDENT_PROCESS_ERROR = 'RECOVER_IDENT_PROCESS_ERROR';

// These actions trigger the handling for each ident state
export const RECOVER_IDENT_PENDING = 'RECOVER_IDENT_PENDING';
export const RECOVER_IDENT_AUTHORIZATION_REQUIRED =
  'RECOVER_IDENT_AUTHORIZATION_REQUIRED';
export const RECOVER_IDENT_CONFIRMATION_REQUIRED =
  'RECOVER_IDENT_CONFIRMATION_REQUIRED';
export const RECOVER_IDENT_CONFIRMED = 'RECOVER_IDENT_CONFIRMED';
export const RECOVER_IDENT_SUCCESSFUL = 'RECOVER_IDENT_SUCCESSFUL';
export const RECOVER_IDENT_EXPIRED = 'RECOVER_IDENT_EXPIRED';
export const RECOVER_IDENT_FAILED = 'RECOVER_IDENT_FAILED';

export interface IRecoverIdentificationActions {
  type:
    | typeof RECOVER_IDENT_PROCESS_INIT
    | typeof RECOVER_IDENT_PROCESS_SUCCESS
    | typeof RECOVER_IDENT_PROCESS_ERROR
    | typeof RECOVER_IDENT_PENDING
    | typeof RECOVER_IDENT_AUTHORIZATION_REQUIRED
    | typeof RECOVER_IDENT_CONFIRMATION_REQUIRED
    | typeof RECOVER_IDENT_CONFIRMED
    | typeof RECOVER_IDENT_SUCCESSFUL
    | typeof RECOVER_IDENT_EXPIRED
    | typeof RECOVER_IDENT_FAILED;
  data: {
    id?: string;
    message?: string;
  };
}

export const recoverIdentProcessInit = (id: string) => ({
  type: RECOVER_IDENT_PROCESS_INIT,
  data: { id },
});

export const recoverIdentProcessSuccess = () => ({
  type: RECOVER_IDENT_PROCESS_SUCCESS,
});

export const recoverIdentProcessError = (data = { errorMessage: 'Error' }) => ({
  type: RECOVER_IDENT_PROCESS_ERROR,
  data,
});

export const recoverIdentPending = () => ({
  type: RECOVER_IDENT_PENDING,
});

export const recoverIdentAuthorizationRequired = () => ({
  type: RECOVER_IDENT_AUTHORIZATION_REQUIRED,
});

export const recoverIdentConfirmationRequired = () => ({
  type: RECOVER_IDENT_CONFIRMATION_REQUIRED,
});

export const recoverIdentConfirmed = () => ({
  type: RECOVER_IDENT_CONFIRMED,
});

export const recoverIdentSuccessful = () => ({
  type: RECOVER_IDENT_SUCCESSFUL,
});

export const recoverIdentExpired = () => ({
  type: RECOVER_IDENT_EXPIRED,
});

export const recoverIdentFailed = () => ({
  type: RECOVER_IDENT_FAILED,
});
