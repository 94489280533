import styled from 'styled-components';

export const StyledHeaderLogo = styled.div`
  height: 1.75rem;
  max-width: 8.5rem;
  min-width: 6rem;
  text-align: center;
  align-content: center;
  width: 20%;

  svg {
    fill: ${({ theme }) => theme.baseColors.white};
  }
`;
