import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';

export enum ContainerVariants {
  INFO = 'info',
  DEFAULT = 'default',
}
interface IFlexContainer extends FlexboxProps, LayoutProps, SpaceProps {
  variant?: ContainerVariants;
}

export default IFlexContainer;
