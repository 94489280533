import { flattenMessages } from '../utils';
import { deErrors } from './deErrors';
import { deMessages } from './deMessages';

const deLocals = flattenMessages({
  message: deMessages,
  error: deErrors,
});

export default deLocals;
