import React, { Fragment, MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import FlexContainer from 'components/FlexContainer';
import Header from 'components/Header';
import Message from 'components/Message';
import IdentRetryWithVideo from 'sections/IdentRetryWithVideo';

interface IBankIdentificationIbanError {
  errorMessage: string;
  onRetryClick: MouseEventHandler<HTMLButtonElement>;
  navigateToVideoIdentStart: () => void;
}

export const BankIdentificationIbanError: React.FC<
  IBankIdentificationIbanError
> = ({ errorMessage, onRetryClick, navigateToVideoIdentStart }) => (
  <FlexContainer flexDirection="column" alignItems="flex-start">
    <Fragment>
      <Header type="error">
        <FormattedMessage id="message.paymentInitiation.error.title" />
      </Header>

      <Message
        leadingIcon="ExclamationTriangle"
        type="error"
        marginBottom="2.5rem"
      >
        <FormattedMessage id={`error.${errorMessage}`} />
      </Message>
    </Fragment>
    <IdentRetryWithVideo
      retryIdent={onRetryClick}
      navigateToVideoIdentStart={navigateToVideoIdentStart}
    />
  </FlexContainer>
);
