import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Errors from 'components/Errors';
import Layout from 'components/Layout';
import { StyledLeadingIcon } from '../../components/Button/Button.styled';
import { ButtonVariants } from '../../components/Button/types';
import SkipIdentification from '../../components/SkipIdentification/SkipIdentification';
import useUniversalStart from '../../utils/hooks/useUniversalStart';
import { CtaButton, StyledCtaSection } from './UniversalStart.styled';

const UniversalStart = () => {
  const {
    handleStartVideoIdent,
    handleStartBankIdent,
    handleCallbackClick,
    errorMessages,
  } = useUniversalStart();
  return (
    <Layout>
      <SkipIdentification />
      <h2>
        <FormattedMessage id="message.selectIdentificationMethod.title" />
      </h2>
      <StyledCtaSection>
        <CtaButton
          variant={ButtonVariants.SECONDARY}
          onClick={handleStartVideoIdent}
        >
          <StyledLeadingIcon name="VideoEid" width="calc(1.25rem + 40%)" />
          <span>
            <h3>
              <FormattedMessage id="message.selectIdentificationMethod.videoEid.heading" />
            </h3>
            <p>
              <small>
                <FormattedMessage id="message.selectIdentificationMethod.videoEid.languageDescription" />
              </small>
            </p>
          </span>
        </CtaButton>

        <CtaButton
          variant={ButtonVariants.SECONDARY}
          onClick={handleStartBankIdent}
        >
          <StyledLeadingIcon name="Landmark" width="calc(0.5rem + 40%)" />
          <span>
            <h3>
              <FormattedMessage id="message.selectIdentificationMethod.bankIdent.heading" />
            </h3>
            <p>
              <small>
                <FormattedMessage id="message.selectIdentificationMethod.bankIdent.description" />
                <FormattedMessage id="message.selectIdentificationMethod.bankIdent.hint" />
              </small>
            </p>
          </span>
        </CtaButton>
      </StyledCtaSection>

      <Errors errorMessages={errorMessages} />
      <Button variant={ButtonVariants.SECONDARY} onClick={handleCallbackClick}>
        <FormattedMessage id="message.button.cancel" />
      </Button>
    </Layout>
  );
};

export default UniversalStart;
