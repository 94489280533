import React from 'react';
import { FormattedMessage } from 'react-intl';
import FlexContainer from 'components/FlexContainer';
import { ContainerVariants } from 'components/FlexContainer/types';
import Icon from 'components/Icon';
import IIcon from 'components/Icon/types';
import Paragraph from 'components/Paragraph';
import { defaultTheme } from 'theme';

interface ISignDocumentTransactionIdHint {
  reference?: string;
}

const SignDocumentTransactionIdHint: React.FC<
  ISignDocumentTransactionIdHint
> = ({ reference }) => {
  const iconProps: IIcon = {
    name: 'InfoLock',
    color: defaultTheme.baseColors.base100,
    margin: '-0.5em 0.75em 0 1rem',
    top: '1.5rem',
  };

  return (
    <FlexContainer marginTop="2rem" variant={ContainerVariants.INFO}>
      <Icon {...iconProps} />
      <div>
        <Paragraph marginTop="1rem" marginBottom="0">
          <FormattedMessage id="message.signDocuments.enterTan.transactionId" />
          <strong>{reference}</strong>.<br />
        </Paragraph>
        <Paragraph lineHeight="1.1" marginTop="0.125rem">
          <small>
            <FormattedMessage id="message.signDocuments.enterTan.transactionInfo" />
          </small>
        </Paragraph>
      </div>
    </FlexContainer>
  );
};

export default SignDocumentTransactionIdHint;
