import React from 'react';
import { FormattedMessage } from 'react-intl';
import FlexContainer from 'components/FlexContainer';
import { FieldErrorMessage } from 'components/InputField/InputField.styled';
import {
  StyledCheckbox,
  StyledCheckmark,
  StyledError,
  StyledLabel,
} from './Checkbox.styled';
import ICheckbox from './types';

const Checkbox: React.FC<ICheckbox> = ({
  label,
  checked,
  error,
  errorMessage,
  onChange,
  disabled,
}) => (
  <FlexContainer flexDirection="column" width="100%">
    <StyledLabel>
      {label}
      <StyledCheckbox
        checked={checked}
        disabled={disabled}
        error={error}
        onChange={onChange}
      />
      <StyledCheckmark />
    </StyledLabel>
    {error ? (
      <StyledError>
        <FieldErrorMessage>
          <FormattedMessage id={errorMessage} />
        </FieldErrorMessage>
      </StyledError>
    ) : null}
  </FlexContainer>
);

export default Checkbox;
