import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ViewPdfDocumentStyled } from './ViewPdfDocument.styled';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const ViewDocumentsInSafari = ({ downloadedFileUrl = '' }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({
    numPages: nextNumPages,
  }: {
    numPages: number;
  }) => {
    setNumPages(nextNumPages);
  };

  return (
    <ViewPdfDocumentStyled>
      <Document file={downloadedFileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1} />
        ))}
      </Document>
    </ViewPdfDocumentStyled>
  );
};

export default ViewDocumentsInSafari;
