import { notifyPaymentInit } from 'api/endpoints';
import { identFailed } from 'redux/actions/identificationActions';
import {
  IPaymentInitiationCreateAction,
  PAYMENT_INIT_NOTIFY_INIT,
  paymentInitNotifyError,
  paymentInitNotifySuccess,
} from 'redux/actions/paymentInitiationActions';
import { configSelector } from 'redux/selectors';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { snakeToCamelCase } from 'utils/formatters';

export function* notifyPaymentInitiation(
  action: IPaymentInitiationCreateAction,
): SagaIterator {
  try {
    const config = yield select(configSelector);
    const { baseUrl, sessionToken } = config;

    const { sessionKey, status, errorCode } = action.data;

    const { requestMethod, getUrl, getRequestBody, getErrorCode } =
      notifyPaymentInit;

    const response = yield call(
      requestMethod,
      getUrl(baseUrl),
      getRequestBody(sessionKey, status, errorCode),
      sessionToken,
    );

    if (response.errors) {
      throw new Error(getErrorCode(response.errors));
    }

    yield put(identFailed({ errorMessage: snakeToCamelCase(errorCode) }));
    yield put(paymentInitNotifySuccess());
  } catch (error) {
    yield put(paymentInitNotifyError());
  }
}

export function* watchNotifyPaymentInitiation() {
  yield takeLatest(PAYMENT_INIT_NOTIFY_INIT, notifyPaymentInitiation);
}
