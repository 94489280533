import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  identAuthorizationInit,
  identTanAcceptanceInit,
} from 'redux/actions/identificationActions';
import {
  useIdentificationSelector,
  useMobileNumberSelector,
} from 'redux/selectors';

export interface IFormInputs {
  tan: string[];
}

const useBankIdentificationSignDocumentsEnterTan = () => {
  const dispatch = useDispatch();

  const { errorMessage } = useIdentificationSelector();
  const { number } = useMobileNumberSelector();
  const { control, handleSubmit, errors, reset } = useForm();

  const onSubmit = (values: IFormInputs) => {
    dispatch(identTanAcceptanceInit(values.tan.join('')));
    // reset form to default values
    reset();
  };

  const onRetry = () => {
    dispatch(identAuthorizationInit());
    // reset form to default values
    reset();
  };

  return {
    errorMessage,
    number,
    onSubmit,
    onRetry,
    handleSubmit,
    reset,
    control,
    formErrors: errors,
  };
};

export default useBankIdentificationSignDocumentsEnterTan;
