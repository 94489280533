import React, { useState } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { useSessionSelector } from '../../redux/selectors';
import Icon from '../Icon';
import {
  HelpPopupButtonStyled,
  HelpPopupCloseButtonStyled,
  HelpPopupCloseIconStyled,
  HelpPopupLink,
  HelpPopupLinkIconStyled,
  HelpPopupPhone,
  HelpPopupSectionStyled,
  HelpPopupStyled,
  HelpPopupTeaserStyled,
  HelpPopupWrapperStyled,
} from './HelpPopup.styled';

const HelpPopup: React.FC<{ intl: IntlShape }> = ({
  intl: { formatMessage },
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isTeaserPresent, setIsTeaserPresent] = useState<boolean>(true);
  const handleOnToggle = () => {
    setIsOpen(!isOpen);
    setIsTeaserPresent(false);
  };
  const handleOnClose = () => {
    setIsOpen(false);
  };

  const { custom_content: customContent, language } = useSessionSelector();
  const supportUrl = customContent?.[language]?.support_url;
  const supportPhone = customContent?.[language]?.support_phone;
  const supportText = customContent?.[language]?.support_text;

  return supportUrl || supportPhone ? (
    <HelpPopupSectionStyled>
      <HelpPopupWrapperStyled>
        {isOpen && (
          <HelpPopupStyled>
            {supportUrl && (
              <p>
                <HelpPopupLink
                  href={supportUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatMessage({ id: 'message.helpPopup.linkTextPart1' })}
                  <span>
                    {formatMessage({ id: 'message.helpPopup.linkTextPart2' })}
                    <HelpPopupLinkIconStyled name="ArrowRight" />
                  </span>
                </HelpPopupLink>
              </p>
            )}
            {supportPhone && (
              <p>
                {supportText || formatMessage({ id: 'message.helpPopup.text' })}
                <br />
                <HelpPopupPhone href={supportPhone.replace(/\s/g, '')}>
                  <Icon
                    name="PhoneAltSolid"
                    size="1rem"
                    top="0.15rem"
                    marginRight="0.5rem"
                  />
                  {supportPhone}
                </HelpPopupPhone>
              </p>
            )}
            <HelpPopupCloseButtonStyled onClick={handleOnClose} title="close">
              <HelpPopupCloseIconStyled name="TimesLight" size="0.875rem" />
            </HelpPopupCloseButtonStyled>
          </HelpPopupStyled>
        )}
        {isTeaserPresent && (
          <HelpPopupTeaserStyled>
            <FormattedMessage id="message.helpPopup.teaserText" />
          </HelpPopupTeaserStyled>
        )}
        <HelpPopupButtonStyled onClick={handleOnToggle}>
          <Icon name="QuestionCircleSolid" size="1.5rem" />
        </HelpPopupButtonStyled>
      </HelpPopupWrapperStyled>
    </HelpPopupSectionStyled>
  ) : null;
};

export default injectIntl(HelpPopup);
