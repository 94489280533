import Button from 'components/Button';
import styled from 'styled-components';

export const PreviewButton = styled(Button)`
  width: 100%;
  justify-content: flex-start;
`;

export const ShareButton = styled(Button)`
  margin-left: 0.75rem;
  width: auto;
  min-width: 15%;
`;
