import * as React from 'react';

export const BitpandaLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <rect width="64" height="64" rx="32" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.5435 16.9848L35.7398 12.5085C36.8744 12.1358 38.0752 12.0084 39.2627 12.1346L37.4825 10.8351C35.6172 9.52524 33.2499 9.15479 31.0737 9.83221L17.5895 14.079L21.5435 16.9848ZM43.1713 29.2663C43.1713 29.2663 45.6794 26.9896 45.6794 22.0999V20.0309C45.6794 16.8107 41.9706 11.5768 36.0326 13.454L21.947 17.8946V44.0046L25.9031 42.7584V36.3378L38.6159 32.3303C40.9895 31.5905 42.9675 33.4479 42.9675 35.5149V38.0606C42.9675 40.6518 40.679 44.327 37.0908 45.4545L17.5974 51.6002L21.5534 54.5L39.1143 48.9615C42.8212 47.7747 46.9256 44.6099 46.9256 37.849V35.2498C46.9157 31.1751 43.1713 29.2663 43.1713 29.2663ZM25.911 32.1879L37.2372 28.6274H37.2293C39.9471 27.7749 41.7253 25.4309 41.7313 22.1889V20.3434C41.7313 18.3911 39.593 16.5021 37.2313 17.2439L25.911 20.8043V32.1879ZM37.524 18.1676L36.7822 18.401V21.1999C36.7795 22.6081 35.8735 23.8555 34.5352 24.2936L26.8941 26.7028V30.8567L36.9365 27.6918C39.3102 26.9501 40.7403 24.8929 40.7403 22.1909V20.3355C40.7383 19.0597 39.2864 17.6098 37.524 18.1676ZM41.9706 35.5268V38.0685C41.9706 40.3868 39.8877 43.5516 36.7803 44.5189L17 50.7556V14.8821L20.9561 17.7819V45.3615L35.2453 40.8556C36.8959 40.3418 38.0189 38.8122 38.0146 37.0835V33.5626L38.9106 33.2817C40.586 32.7575 41.9706 34.0887 41.9706 35.5268Z"
      fill="#161616"
    />
  </svg>
);
