import React from 'react';
import { FormattedMessage } from 'react-intl';
import FlexContainer from 'components/FlexContainer';
import {
  FieldErrorMessage,
  FieldHeader,
  FieldInfo,
  FieldLabel,
} from './InputField.styled';
import IFormField from './types';

const InputField: React.FC<IFormField> = ({
  label,
  info,
  errorMessage,
  children,
}) => (
  <FlexContainer flexDirection="column" width="100%">
    <FieldHeader>
      {label && (
        <FieldLabel>
          <FormattedMessage id={label} />
        </FieldLabel>
      )}
      {info && (
        <FieldInfo>
          <FormattedMessage id={info} />
        </FieldInfo>
      )}
    </FieldHeader>
    {children}
    {errorMessage ? (
      <FieldErrorMessage>
        <FormattedMessage id={errorMessage} />
      </FieldErrorMessage>
    ) : null}
  </FlexContainer>
);

export default InputField;
