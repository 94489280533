import styled from 'styled-components';

export const StepsList = styled.ol`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 1.4;
  margin: 1.5rem 0;
  max-width: 30rem;
  padding: 0 0 0 1.15em;

  li {
    padding: 0.325em 0 0.325em 0.25em;
    position: relative;

    &.po-step-phone--done {
      color: ${({ theme }) => theme.baseColors.base50};
      list-style-type: none;
    }
  }

  small {
    color: ${({ theme }) => theme.baseColors.base50};
    display: block;
    margin-top: 0.25em;
  }

  strong {
    white-space: nowrap;
  }
`;
