import { FormattedMessage } from 'react-intl';
import Errors from 'components/Errors';
import Layout from 'components/Layout';
import SkipIdentification from 'components/SkipIdentification/SkipIdentification';
import ButtonOptions from 'sections/BankIdentificationWindow/ButtonOptions';
import StepsList from 'sections/BankIdentificationWindow/StepsList/StepsList';
import useStartBankIdentification from '../../utils/hooks/useStartBankIdentification';

const StartBankIdentification = () => {
  const {
    handleAuthorizeMobileNumber,
    handleCallbackClick,
    callbackButtonText,
    errorMessages,
    mobileNumber,
    identification,
    bankIdentAvailable,
    handleNavigateToBankIdentIban,
  } = useStartBankIdentification();

  return (
    <Layout>
      <SkipIdentification />
      <h2>
        <FormattedMessage id="message.startBankIdentification.title" />
      </h2>
      <StepsList
        mobileNumber={mobileNumber}
        bankIdentAvailable={bankIdentAvailable}
      />
      <Errors errorMessages={errorMessages} />
      <ButtonOptions
        mobileNumber={mobileNumber}
        identification={identification}
        callbackAction={handleCallbackClick}
        callbackActionText={callbackButtonText()}
        mobileNumberAuthorizationAction={handleAuthorizeMobileNumber}
        bankIdentAction={handleNavigateToBankIdentIban}
      />
    </Layout>
  );
};

export default StartBankIdentification;
