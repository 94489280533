import { isValidIBAN } from 'ibantools';

// due to how validators work in react-hook-forms, validators must either return a value to display
// or true to bypass the validation
const validateIban = (value: string) => {
  return isValidIBAN(value)
    ? true
    : 'message.paymentInitiation.ibanValidationError.invalidIban';
};

export default validateIban;
