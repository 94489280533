import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ButtonSizes, ButtonVariants } from 'components/Button/types';
import FlexContainer from 'components/FlexContainer';
import {
  fileDownloadInit,
  fileShareInit,
} from 'redux/actions/identificationFileActions';
import { isSafari } from '../../../utils/browserDetection';
import { PreviewButton, ShareButton } from './DocumentsListElement.styled';
import IDocumentsListElement from './types';

const DocumentsListElement: React.FC<IDocumentsListElement> = ({
  document,
  downloading = false,
}) => {
  const dispatch = useDispatch();

  const handlePreviewClick = () => {
    dispatch(fileDownloadInit(document.id, document.name));
  };

  const handleFileShare = () => {
    dispatch(fileShareInit(document.id, document.name));
  };

  return (
    <FlexContainer marginBottom="1rem">
      <PreviewButton
        variant={ButtonVariants.SECONDARY}
        onClick={handlePreviewClick}
        loading={downloading}
        leadingIcon="ViewDocument"
      >
        <FormattedMessage id="message.signDocuments.viewDocument" />
      </PreviewButton>

      {isSafari && window.navigator.share && (
        <ShareButton
          variant={ButtonVariants.SECONDARY}
          buttonSize={ButtonSizes.SMALL}
          onClick={handleFileShare}
          loading={downloading}
          centeredIcon="Share"
        ></ShareButton>
      )}
    </FlexContainer>
  );
};

export default DocumentsListElement;
