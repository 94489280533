import * as React from 'react';

const SvgVideoEid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 39 31" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 7V3.571A2.566 2.566 0 0020.444 1H2.556A2.566 2.566 0 000 3.571v12.857A2.566 2.566 0 002.556 19H11l1.286-1.286h-.147v-.606c0-.559-.168-1.077-.455-1.507-.483-.72-1.306-1.101-2.149-1.101-.375 0-.615.066-.864.133-.266.073-.542.148-1.004.148-.463 0-.739-.075-1.005-.148a2.936 2.936 0 00-.864-.133c-.847 0-1.67.378-2.148 1.1-.288.43-.456.95-.456 1.508v.606h-.638a1.285 1.285 0 01-1.278-1.285V6.143h20.444v2.135L23 7zM4.472 17.108v.606h6.397v-.606c0-.282-.084-.555-.24-.788-.223-.33-.63-.53-1.09-.53-.204 0-.381.047-.59.103-.301.08-.668.178-1.278.178-.614 0-.983-.099-1.283-.179-.208-.055-.384-.102-.586-.102-.46 0-.867.197-1.09.53-.156.233-.24.506-.24.788zM1.278 4.858h20.444V3.57c0-.707-.575-1.285-1.278-1.285H2.556c-.703 0-1.278.578-1.278 1.285v1.286z"
    />
    <path d="M18.714 11.286h-4.339a.321.321 0 00-.32.321v.643c0 .177.144.321.32.321h3.054l1.285-1.285zM16.143 13.857h-1.768a.321.321 0 00-.32.322v.642c0 .177.144.322.32.322h.482l1.286-1.286zM18.847 10h-4.472a.321.321 0 01-.32-.321v-.643c0-.177.144-.322.32-.322h4.472c.176 0 .32.145.32.322v.643a.321.321 0 01-.32.32z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.861 10.643a3.204 3.204 0 01-3.194 3.214 3.204 3.204 0 01-3.195-3.214 3.204 3.204 0 013.195-3.214 3.204 3.204 0 013.194 3.214zm-1.278 0a1.923 1.923 0 00-1.916-1.929 1.923 1.923 0 00-1.917 1.929c0 1.064.859 1.928 1.917 1.928a1.923 1.923 0 001.916-1.928zM15 22.707v2.301c0 1.1.967 1.992 2.159 1.992h13.019c1.191 0 2.158-.892 2.158-1.992v-2.491l4.568 2.912c.237.167.504.238.758.238.692 0 1.338-.542 1.338-1.313V13.646c.004-.771-.642-1.313-1.334-1.313-.258 0-.52.075-.758.238l-4.572 2.912v-2.491c0-1.1-.966-1.992-2.158-1.992h-3.471l-1.333 1.333h4.804c.445 0 .825.3.825.659v12.016c0 .359-.38.659-.825.659h-13.02c-.445 0-.824-.3-.824-.659v-3.635L15 22.707zM37.62 24.3l.05.054V13.667l-5.334 3.396v3.87l5.285 3.367zM3 30L33 0l.707.707-30 30L3 30z"
    />
  </svg>
);

export default SvgVideoEid;
