import BankIdentificationIban from 'pages/BankIdentificationIban';
import BankIdentificationSignDocuments from 'pages/BankIdentificationSignDocuments';
import LoadingPage from 'pages/LoadingPage';
import PhoneVerification from 'pages/PhoneVerification';
import StartBankIdentification from 'pages/StartBankIdentification';
import StartVideoIdentification from 'pages/StartVideoIdentification';
import UniversalStart from 'pages/UniversalStart/UniversalStart';
import VideoIdentification from 'pages/VideoIdentification';
import { useNavigationSelector } from 'redux/selectors';
import { NavigationRoutesState } from 'redux/types';
import useInitializeApp from 'utils/hooks/useInitializeApp';

const AppRouter = () => {
  const { errorMessages, loaded } = useInitializeApp();
  const navigation = useNavigationSelector();

  const { route } = navigation;
  const routeIsStart = route === NavigationRoutesState.UNIVERSAL_START;

  const routeIsVideoIdentStart =
    route === NavigationRoutesState.VIDEO_IDENT_START;
  const routeIsVideoIdentVideo =
    route === NavigationRoutesState.VIDEO_IDENT_VIDEO;

  const routeIsStartBankIdent =
    route === NavigationRoutesState.BANK_IDENT_START;
  const routeIsBankIdentIban = route === NavigationRoutesState.BANK_IDENT_IBAN;
  const routeIsDocumentSigning =
    route === NavigationRoutesState.BANK_IDENT_DOCUMENT_SIGNING;

  const routeIsMobileNumber =
    route === NavigationRoutesState.BANK_IDENT_MOBILE_NUMBER;

  if (loaded) {
    if (routeIsDocumentSigning) {
      return <BankIdentificationSignDocuments />;
    }
    if (routeIsVideoIdentStart) {
      return <StartVideoIdentification />;
    }
    if (routeIsVideoIdentVideo) {
      return <VideoIdentification />;
    }
    if (routeIsStartBankIdent) {
      return <StartBankIdentification />;
    }
    if (routeIsBankIdentIban) {
      return <BankIdentificationIban />;
    }
    if (routeIsMobileNumber) {
      return <PhoneVerification />;
    }
    if (routeIsStart) {
      return <UniversalStart />;
    }
  }

  return <LoadingPage errorMessages={errorMessages} />;
};

export default AppRouter;
