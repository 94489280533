import { ApiError } from './consts';
import { getErrorCode } from './helpers';
import { fetchData, fetchFile, patchData, postData } from './request';

const getMobileNumber = {
  requestMethod: fetchData,
  getUrl: (baseUrl: string) => `${baseUrl}/person_onboarding/mobile_number`,
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const authorizeMobileNumber = {
  requestMethod: postData,
  getUrl: (baseUrl: string) =>
    `${baseUrl}/person_onboarding/mobile_number/authorize`,
  getRequestBody: () => ({}),
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const confirmMobileNumber = {
  requestMethod: postData,
  getUrl: (baseUrl: string) =>
    `${baseUrl}/person_onboarding/mobile_number/confirm`,
  getRequestBody: (token: string) => ({
    token,
  }),
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const createBankIdentification = {
  requestMethod: postData,
  getUrl: (baseUrl: string) => `${baseUrl}/person_onboarding/iban/verify`,
  getRequestBody: (iban: string, termsSignedAt: string) => ({
    iban,
    terms_and_conditions_signed_at: termsSignedAt,
  }),
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const createIdNowIdentification = {
  requestMethod: postData,
  getUrl: (baseUrl: string) =>
    `${baseUrl}/person_onboarding/idnow_identification`,
  getRequestBody: () => ({}),
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const getIdent = {
  requestMethod: fetchData,
  getUrl: (baseUrl: string, identificationId: string) =>
    `${baseUrl}/person_onboarding/identifications/${identificationId}`,
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const authorizeIdent = {
  requestMethod: patchData,
  getUrl: (baseUrl: string, identificationId: string) =>
    `${baseUrl}/person_onboarding/sign_documents/${identificationId}/authorize`,
  getRequestBody: () => null,
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const confirmIdent = {
  requestMethod: patchData,
  getUrl: (baseUrl: string, identificationId: string) =>
    `${baseUrl}/person_onboarding/sign_documents/${identificationId}/confirm`,
  getRequestBody: (token: string) => ({
    token,
  }),
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const downloadDocument = {
  requestMethod: fetchFile,
  getUrl: (baseUrl: string, documentId?: string) =>
    `${baseUrl}/person_onboarding/sign_documents/${documentId}/download`,
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const getSessionInfo = {
  requestMethod: fetchData,
  getUrl: (baseUrl: string) => `${baseUrl}/person_onboarding/info`,
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const getIdentificationMethods = {
  requestMethod: fetchData,
  getUrl: (baseUrl: string) =>
    `${baseUrl}/person_onboarding/identification_methods`,
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const checkEligibility = {
  requestMethod: postData,
  getUrl: (baseUrl: string) =>
    `${baseUrl}/person_onboarding/eligibility_checks`,
  getRequestBody: (method: string) => ({ method }),
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

const notifyPaymentInit = {
  requestMethod: postData,
  getUrl: (baseUrl: string) => `${baseUrl}/person_onboarding/fintec/errors`,
  getRequestBody: (sessionKey: string, status: string, reason: string) => ({
    session_key: sessionKey,
    status,
    reason,
  }),
  getErrorCode: (errors: ApiError[]) => getErrorCode(errors),
};

export {
  getMobileNumber,
  authorizeMobileNumber,
  confirmMobileNumber,
  createBankIdentification,
  createIdNowIdentification,
  getIdent,
  authorizeIdent,
  confirmIdent,
  downloadDocument,
  getSessionInfo,
  checkEligibility,
  getIdentificationMethods,
  notifyPaymentInit,
};
