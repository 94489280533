import styled from 'styled-components';
import { layout, space } from 'styled-system';
import { IIconBaseWrapper } from './types';

export const IconBaseWrapper = styled.span<IIconBaseWrapper>`
  display: inline-block;
  fill: currentColor;
  height: ${({ size }) => size};
  left: ${({ left }) => left};
  position: ${({ position }) => position || 'relative'};
  top: ${({ top }) => top};
  width: ${({ size }) => size};
  ${space};
  ${layout};

  svg {
    fill: ${({ color }) => color};
  }
`;
