import React from 'react';
import Spinner from 'components/Spinner';
import {
  SpinnerOverlay,
  StyledButton,
  StyledCenteredIcon,
  StyledLeadingIcon,
  StyledTrailingIcon,
} from './Button.styled';
import IButton, { ButtonSizes, ButtonVariants } from './types';

const Button: React.FC<IButton> = ({
  variant = ButtonVariants.PRIMARY,
  buttonSize = ButtonSizes.REGULAR,
  leadingIcon,
  trailingIcon,
  centeredIcon,
  loading = false,
  onClick = () => {},
  children,
  disabled,
  ...props
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (loading) return;
    onClick(e);
  };

  return (
    <StyledButton
      variant={variant}
      buttonSize={buttonSize}
      onClick={handleClick}
      disabled={disabled || loading}
      {...props}
    >
      {leadingIcon && <StyledLeadingIcon name={leadingIcon} size="0.875rem" />}
      {centeredIcon && <StyledCenteredIcon name={centeredIcon} />}
      {children}
      {trailingIcon && (
        <StyledTrailingIcon name={trailingIcon} size="0.875rem" />
      )}
      {loading && (
        <SpinnerOverlay>
          <Spinner scale={0.65} />
        </SpinnerOverlay>
      )}
    </StyledButton>
  );
};

export default Button;
