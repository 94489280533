export enum ActionState {
  IDLE = 'IDLE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELLED = 'CANCELLED',
}

export enum IdentificationStatus {
  NONE = 'none',
  PENDING = 'pending',
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
  AUTHORIZATION_REQUIRED = 'authorization_required',
  CONFIRMATION_REQUIRED = 'confirmation_required',
  CONFIRMED = 'confirmed',
  EXPIRED = 'expired',
  ABORTED = 'aborted',
}

export enum IdentificationMethod {
  BANK = 'bank',
  IDNOW = 'idnow',
  NONE = 'none',
}

export enum PaymentInitiationProviderTypes {
  NONE = 'none',
  FTS = 'fts',
  FLC = 'flc',
}

export enum PaymentInitiationFailureStatus {
  FAILURE = 'FAILURE',
  EXPIRED = 'EXPIRED',
}

export enum PollingState {
  IDLE = 'idle',
  POLLING = 'polling',
}

export enum NavigationRoutesState {
  UNIVERSAL_START = 'universal_start',
  VIDEO_IDENT_START = 'video_ident_start',
  VIDEO_IDENT_VIDEO = 'video_ident_video',
  BANK_IDENT_START = 'bank_ident_start',
  BANK_IDENT_MOBILE_NUMBER = 'bank_ident_mobile_number',
  BANK_IDENT_IBAN = 'bank_ident_iban',
  BANK_IDENT_DOCUMENT_SIGNING = 'bank_ident_document_signing',
}
