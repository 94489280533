import styled from 'styled-components';

export const StyledBankIdentificationIbanForm = styled.form`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputWrapper = styled.div`
  width: 100%;

  &.iban-input {
    /* leaves space for validation errors to prevent jumpy behaviour when validation errors are rendered */
    min-height: 8em;
  }
`;
