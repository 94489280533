import configInitialState, { IConfigInitialState } from './configInitialState';
import identificationFileInitialState, {
  IIdentificationFileInitialState,
} from './identificationFileInitialState';
import identificationInitialState, {
  IIdentificationInitialState,
} from './identificationInitialState';
import identificationMethodsInitialState, {
  IIdentificationMethodsInitialState,
} from './identificationMethodsInitialState';
import localStorageInitialState, {
  ILocalstorageInitialState,
} from './localStorageInitialState';
import mobileNumberInitialState, {
  IMobileNumberInitialState,
} from './mobileNumberInitialState';
import navigationInitialState, {
  INavigationInitialState,
} from './navigationReducerInitialState';
import recoverIdentificationInitialState, {
  IRecoverIdentificationInitialState,
} from './recoverIdentificationInitialState';
import sessionInitialState, {
  ISessionInitialState,
} from './sessionInitialState';

export interface IInitialState {
  mobileNumber: IMobileNumberInitialState;
  identification: IIdentificationInitialState;
  identificationMethods: IIdentificationMethodsInitialState;
  identificationFile: IIdentificationFileInitialState;
  config: IConfigInitialState;
  session: ISessionInitialState;
  localStorage: ILocalstorageInitialState;
  navigation: INavigationInitialState;
  recoverIdentification: IRecoverIdentificationInitialState;
}

const initialState: IInitialState = {
  mobileNumber: mobileNumberInitialState,
  identification: identificationInitialState,
  identificationMethods: identificationMethodsInitialState,
  identificationFile: identificationFileInitialState,
  config: configInitialState,
  session: sessionInitialState,
  localStorage: localStorageInitialState,
  navigation: navigationInitialState,
  recoverIdentification: recoverIdentificationInitialState,
};

export default initialState;
