import deLocals from 'utils/locales/de/index';
import enLocals from 'utils/locales/en/index';

export const loadLocaleData = (locale: string) => {
  switch (locale) {
    case 'en':
      return enLocals;
    case 'de':
    default:
      return deLocals;
  }
};
