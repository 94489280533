import styled, { css } from 'styled-components';
import { IProgressBarFragment } from './types';

export const CurrentStep = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  color: ${({ theme }) => theme.baseColors.base100};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const NextStep = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.baseColors.base50};
`;

export const ProgressBarFragment = styled.div<IProgressBarFragment>`
  border-radius: 1px;
  height: 2px;
  background-color: ${({ theme }) => theme.baseColors.base25};
  margin: 0 -2px;

  ${({ index, currentStep }) =>
    index <= currentStep
      ? css`
          background-color: ${({ theme }) => theme.colors.secondary};
        `
      : null}
`;
