import {
  ILocalStorageAction,
  LOCAL_STORAGE_GET_ERROR,
  LOCAL_STORAGE_GET_INIT,
  LOCAL_STORAGE_GET_SUCCESS,
  LOCAL_STORAGE_RESET,
  LOCAL_STORAGE_SET_ERROR,
  LOCAL_STORAGE_SET_INIT,
  LOCAL_STORAGE_SET_SUCCESS,
} from 'redux/actions/localStorageActions';
import localStorageInitialState, {
  ILocalstorageInitialState,
} from 'redux/initialState/localStorageInitialState';
import { ActionState } from 'redux/types';

export const localStorageReducer = (
  state = localStorageInitialState,
  action: ILocalStorageAction,
): ILocalstorageInitialState => {
  switch (action.type) {
    case LOCAL_STORAGE_GET_INIT:
    case LOCAL_STORAGE_SET_INIT:
      return {
        ...state,
        state: ActionState.IN_PROGRESS,
      };
    case LOCAL_STORAGE_SET_SUCCESS:
    case LOCAL_STORAGE_GET_SUCCESS:
      return {
        ...state,
        state: ActionState.SUCCESS,
      };
    case LOCAL_STORAGE_SET_ERROR:
    case LOCAL_STORAGE_GET_ERROR:
      return {
        ...state,
        state: ActionState.ERROR,
        errorMessage: action.data!.message!,
      };
    case LOCAL_STORAGE_RESET:
      return { ...localStorageInitialState, state: ActionState.SUCCESS };
    default:
      return state;
  }
};

export default localStorageReducer;
