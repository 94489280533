import React from 'react';
import { FormattedMessage } from 'react-intl';
import FlexContainer from 'components/FlexContainer';
import Spinner from 'components/Spinner';
import Paragraph from '../Paragraph';

export interface ILoading {
  type: 'connecting' | 'processing' | 'initializing' | 'noText';
}

const Loading: React.FC<ILoading> = ({ type }) => {
  const renderLoadingText = () => {
    switch (type) {
      case 'connecting':
        return (
          <Paragraph
            textAlign="center"
            marginTop="1.5em"
            paddingLeft="1.5em"
            paddingRight="1.5em"
          >
            <span>
              <FormattedMessage id="message.identificationLoading.connecting" />
            </span>
            <br />
            <FormattedMessage id="message.identificationLoading.credentials.part1" />
            <strong>
              <FormattedMessage id="message.identificationLoading.credentials.part2" />
            </strong>
            <FormattedMessage id="message.identificationLoading.credentials.part3" />
          </Paragraph>
        );
      case 'processing':
        return (
          <Paragraph textAlign="center" marginTop="1.5em">
            <FormattedMessage id="message.identificationLoading.processing" />
          </Paragraph>
        );
      case 'noText':
        return null;
      default:
        return (
          <Paragraph textAlign="center" marginTop="1.5em">
            <FormattedMessage id="message.identificationLoading.initializing" />
          </Paragraph>
        );
    }
  };
  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="12rem"
      paddingTop="1.5rem"
      paddingBottom="2rem"
    >
      <Spinner />
      {renderLoadingText()}
    </FlexContainer>
  );
};

export default Loading;
