import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import DocumentsList from 'components/DocumentsList';
import FlexContainer from 'components/FlexContainer';
import Icon from 'components/Icon';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import Message from 'components/Message';
import Paragraph from 'components/Paragraph';
import ProgressBar from 'components/ProgressBar';
import SkipIdentification from 'components/SkipIdentification/SkipIdentification';
import { useSessionSelector } from 'redux/selectors';
import { defaultTheme } from 'theme';
import { isSafari } from 'utils/browserDetection';
import useBankIdentificationSignDocuments from 'utils/hooks/useBankIdentificationSignDocuments';
import ViewDocumentsInSafari from './BrowserSpecificModification/ViewPdfDocument/Safari';
import SignDocumentsEnterTan from './BankIdentificationSignDocumentsEnterTan';

const BankIdentificationSignDocuments = () => {
  const {
    identification,
    fileDownload,
    isSuccessful,
    isConfirmed,
    isCofirmationRequired,
    handleSendCodeClick,
    handleFinishClick,
  } = useBankIdentificationSignDocuments();

  const { partner_name } = useSessionSelector();
  const successfulDescription =
    partner_name === 'Coinbase'
      ? 'message.signDocuments.successful.description.coinbase'
      : 'message.signDocuments.successful.description.default';
  const { files, downloadedFileUrl } = fileDownload;

  const { method, documents, loading, reference, errorMessage } =
    identification;

  return (
    <Layout>
      <SkipIdentification />
      {!isSuccessful && <ProgressBar step={2} type={method} />}
      {isSuccessful ? (
        <FlexContainer flexDirection="column" alignItems="flex-start">
          <h2>
            <Icon
              name="Check"
              color={defaultTheme.baseColors.success}
              marginRight="0.5rem"
              width="1.5rem"
              height="1.5rem"
              top="0.2rem"
            />
            <FormattedMessage id="message.signDocuments.successful.title" />
          </h2>
          <Paragraph marginBottom="2rem" paddingLeft="2rem">
            <FormattedMessage id={successfulDescription} />
          </Paragraph>
          <DocumentsList
            filesDownloads={files}
            documents={documents}
            signed={isSuccessful}
          />
          {downloadedFileUrl && isSafari && (
            <ViewDocumentsInSafari downloadedFileUrl={downloadedFileUrl} />
          )}
          <FlexContainer width="100%" justifyContent="flex-end">
            <Button onClick={handleFinishClick}>
              <FormattedMessage id="message.button.finish" />
            </Button>
          </FlexContainer>
        </FlexContainer>
      ) : isConfirmed ? (
        <Loading type="processing" />
      ) : isCofirmationRequired ? (
        <SignDocumentsEnterTan loading={loading} reference={reference} />
      ) : (
        <FlexContainer flexDirection="column" alignItems="flex-start">
          <h2>
            <FormattedMessage id="message.signDocuments.initial.title" />
          </h2>
          <Paragraph marginBottom="2rem">
            <FormattedMessage id="message.signDocuments.initial.description" />
          </Paragraph>
          <DocumentsList
            filesDownloads={files}
            documents={documents}
            signed={isSuccessful}
          />
          {downloadedFileUrl && isSafari && (
            <ViewDocumentsInSafari downloadedFileUrl={downloadedFileUrl} />
          )}
          {files.some((fd) => fd.errorMessage !== '') && (
            <Message type="error" marginBottom="1rem">
              {files.find((fd) => fd.errorMessage !== '')?.errorMessage}
            </Message>
          )}
          {errorMessage && <Message type="error">{errorMessage}</Message>}
          <FlexContainer width="100%" justifyContent="flex-end">
            <Button
              onClick={handleSendCodeClick}
              loading={loading}
              trailingIcon="ArrowRight"
              data-test-id="signDocumentsChecked"
            >
              <FormattedMessage id="message.button.next" />
            </Button>
          </FlexContainer>
        </FlexContainer>
      )}
    </Layout>
  );
};

export default BankIdentificationSignDocuments;
