import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import { ButtonVariants } from 'components/Button/types';
import ButtonsGroup from 'components/ButtonsGroup';
import Errors from 'components/Errors';
import Layout from 'components/Layout';
import Paragraph from 'components/Paragraph';
import SkipIdentification from '../../components/SkipIdentification/SkipIdentification';
import useStartVideoIdentification from '../../utils/hooks/useStartVideoIdentification';

const StartVideoIdentification = () => {
  const {
    handleCallbackClick,
    callbackButtonText,
    errorMessages,
    submitInProgress,
    handleCreateVideoIdent,
  } = useStartVideoIdentification();

  return (
    <Layout>
      <SkipIdentification />
      <h2>
        <FormattedMessage id="message.startVideoIdentification.title" />
      </h2>
      <Paragraph>
        <FormattedMessage id="message.startVideoIdentification.description.part1" />
        <strong>
          <FormattedMessage id="message.startVideoIdentification.description.part2" />
        </strong>
        <FormattedMessage id="message.startVideoIdentification.description.part3" />
      </Paragraph>

      <Errors errorMessages={errorMessages} />
      <ButtonsGroup>
        <Button
          variant={ButtonVariants.SECONDARY}
          onClick={handleCallbackClick}
        >
          <FormattedMessage id={callbackButtonText()} />
        </Button>
        <Button
          onClick={handleCreateVideoIdent}
          loading={submitInProgress}
          data-test-id="startVideoIdent"
        >
          <FormattedMessage id="message.button.start" />
        </Button>
      </ButtonsGroup>
    </Layout>
  );
};

export default StartVideoIdentification;
