import { IFileDownloadAction } from 'redux/actions/identificationFileActions';
import identificationFileInitialState, {
  IIdentificationFileInitialState,
} from 'redux/initialState/identificationFileInitialState';

export const identificationFileReducer = (
  state = identificationFileInitialState,
  action: IFileDownloadAction,
): IIdentificationFileInitialState => {
  switch (action.type) {
    case 'LOAD_FILE_LIST':
      return {
        ...state,
        files: action.data?.files!,
      };
    case 'FILE_DOWNLOAD_INIT':
    case 'FILE_SHARE_INIT':
      return {
        ...state,
        files: state.files.map((f) =>
          f.id === action.data?.id
            ? { ...f, downloadInProgress: true, errorMessage: '' }
            : { ...f, errorMessage: '' },
        ),
      };
    case 'FILE_DOWNLOAD_SUCCESS':
      return {
        ...state,
        files: state.files.map((f) =>
          f.id === action.data?.id
            ? { ...f, downloadInProgress: false, errorMessage: '' }
            : { ...f, errorMessage: '' },
        ),
        downloadedFileUrl: action.data?.fileUrl!,
      };
    case 'FILE_SHARE_SUCCESS':
      return {
        ...state,
        files: state.files.map((f) =>
          f.id === action.data?.id
            ? { ...f, downloadInProgress: false, errorMessage: '' }
            : { ...f, errorMessage: '' },
        ),
        downloadedFileUrl: state.downloadedFileUrl,
      };
    case 'FILE_DOWNLOAD_ERROR':
    case 'FILE_SHARE_ERROR':
      return {
        ...state,
        files: state.files.map((f) =>
          f.id === action.data?.id
            ? {
                ...f,
                downloadInProgress: false,
                errorMessage: action.data.message!,
              }
            : { ...f, errorMessage: '' },
        ),
      };
    default:
      return state;
  }
};
