import styled, { css } from 'styled-components';
import { space, typography } from 'styled-system';
import IHeader from './types';

export const StyledHeader = styled.h2<IHeader>`
  margin-top: 2.25rem;
  margin-bottom: 1rem;

  ${({ type }) => {
    switch (type) {
      case 'error': {
        return css`
          color: ${({ theme }) => theme.baseColors.error};
        `;
      }
      default: {
        return css`
          color: ${({ theme }) => theme.baseColors.base100};
        `;
      }
    }
  }}

  ${typography}
  ${space}
`;
