import {
  IMobileNumberAction,
  MOBILE_NUMBER_AUTHORIZE_CANCELLED,
  MOBILE_NUMBER_AUTHORIZE_ERROR,
  MOBILE_NUMBER_AUTHORIZE_INIT,
  MOBILE_NUMBER_AUTHORIZE_SUCCESS,
  MOBILE_NUMBER_CONFIRM_ERROR,
  MOBILE_NUMBER_CONFIRM_INIT,
  MOBILE_NUMBER_CONFIRM_SUCCESS,
  MOBILE_NUMBER_GET_ERROR,
  MOBILE_NUMBER_GET_INIT,
  MOBILE_NUMBER_GET_SUCCESS,
} from 'redux/actions/mobileNumberActions';
import mobileNumberInitialState, {
  IMobileNumberInitialState,
} from 'redux/initialState/mobileNumberInitialState';
import { ActionState } from 'redux/types';

export const mobileNumberReducer = (
  state = mobileNumberInitialState,
  action: IMobileNumberAction,
): IMobileNumberInitialState => {
  switch (action.type) {
    case MOBILE_NUMBER_GET_INIT:
      return {
        ...state,
        state: ActionState.IN_PROGRESS,
      };
    case MOBILE_NUMBER_AUTHORIZE_INIT:
      return {
        ...state,
        retry: ++state.retry,
        state: ActionState.IN_PROGRESS,
      };
    case MOBILE_NUMBER_CONFIRM_INIT:
      return {
        ...state,
        state: ActionState.IN_PROGRESS,
        retry: state.retry > 0 ? --state.retry : 0,
      };
    case MOBILE_NUMBER_GET_SUCCESS:
    case MOBILE_NUMBER_AUTHORIZE_SUCCESS:
    case MOBILE_NUMBER_CONFIRM_SUCCESS:
      return {
        ...state,
        state: ActionState.SUCCESS,
        number: action.data!.number!,
        verified: action.data!.verified!,
      };
    case MOBILE_NUMBER_GET_ERROR:
    case MOBILE_NUMBER_AUTHORIZE_ERROR:
    case MOBILE_NUMBER_CONFIRM_ERROR:
      return {
        ...state,
        state: ActionState.ERROR,
        errorMessage: action.data!.message!,
      };
    case MOBILE_NUMBER_AUTHORIZE_CANCELLED:
      return {
        ...state,
        state: ActionState.CANCELLED,
        errorMessage: '',
      };
    default:
      return state;
  }
};
