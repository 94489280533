import { applyMiddleware, createStore } from 'redux';
import initialState from 'redux/initialState/initialState';
import rootReducer from 'redux/reducers';
import rootSaga from 'redux/sagas';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

const setupAndCreateStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers = composeWithDevTools({});

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export default setupAndCreateStore;
