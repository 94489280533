import {
  INavigationAction,
  NAV_BANK_IDENT_IBAN,
  NAV_BANK_IDENT_MOBILE_NUMBER,
  NAV_BANK_IDENT_SIGNING,
  NAV_BANK_IDENT_START,
  NAV_UNIVERSAL_START,
  NAV_VIDEO_IDENT_START,
  NAV_VIDEO_IDENT_VIDEO,
} from 'redux/actions/navigationActions';
import navigationInitialState, {
  INavigationInitialState,
} from 'redux/initialState/navigationReducerInitialState';
import { NavigationRoutesState } from 'redux/types';

export const navigationReducer = (
  state = navigationInitialState,
  action: INavigationAction,
): INavigationInitialState => {
  switch (action.type) {
    case NAV_UNIVERSAL_START:
      return { route: NavigationRoutesState.UNIVERSAL_START };
    case NAV_VIDEO_IDENT_START:
      return { route: NavigationRoutesState.VIDEO_IDENT_START };
    case NAV_VIDEO_IDENT_VIDEO:
      return { route: NavigationRoutesState.VIDEO_IDENT_VIDEO };
    case NAV_BANK_IDENT_MOBILE_NUMBER:
      return {
        route: NavigationRoutesState.BANK_IDENT_MOBILE_NUMBER,
      };
    case NAV_BANK_IDENT_START:
      return {
        route: NavigationRoutesState.BANK_IDENT_START,
      };
    case NAV_BANK_IDENT_IBAN:
      return {
        route: NavigationRoutesState.BANK_IDENT_IBAN,
      };
    case NAV_BANK_IDENT_SIGNING:
      return {
        route: NavigationRoutesState.BANK_IDENT_DOCUMENT_SIGNING,
      };
    default:
      return state;
  }
};

export default navigationReducer;
