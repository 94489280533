import styled from 'styled-components';

export const SignDocumentsEnterTanForm = styled.form`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TanInputWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(6, auto);
  margin-top: 1rem;
`;

export const TanValidationError = styled.p`
  color: ${({ theme }) => theme.baseColors.error};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 0.5rem 0 0 0;
`;
