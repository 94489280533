import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useConfigSelector, useSessionSelector } from 'redux/selectors';
import Button from '../Button';
import { ButtonSizes, ButtonVariants } from '../Button/types';
import { StyledSkipIdentification } from './SkipIdentification.styled';

const SkipIdentification = () => {
  const { skipUrl } = useSessionSelector();
  const { showSkip } = useConfigSelector();

  return (
    <Fragment>
      {skipUrl && showSkip && (
        <StyledSkipIdentification>
          <Button
            variant={ButtonVariants.LINK}
            buttonSize={ButtonSizes.SMALL}
            onClick={() => {
              window.location.replace(skipUrl);
            }}
          >
            <FormattedMessage id="message.button.skip" />
          </Button>
        </StyledSkipIdentification>
      )}
    </Fragment>
  );
};

export default SkipIdentification;
