export const NAV_UNIVERSAL_START = 'NAV_UNIVERSAL_START';

export const NAV_VIDEO_IDENT_START = 'NAV_VIDEO_IDENT_START';
export const NAV_VIDEO_IDENT_VIDEO = 'NAV_VIDEO_IDENT_VIDEO';

export const NAV_BANK_IDENT_START = 'NAV_BANK_IDENT_START';
export const NAV_BANK_IDENT_MOBILE_NUMBER = 'NAV_BANK_IDENT_MOBILE_NUMBER';
export const NAV_BANK_IDENT_IBAN = 'NAV_BANK_IDENT_IBAN';
export const NAV_BANK_IDENT_SIGNING = 'NAV_BANK_IDENT_SIGNING';

export interface INavigationAction {
  type:
    | typeof NAV_UNIVERSAL_START
    | typeof NAV_VIDEO_IDENT_START
    | typeof NAV_VIDEO_IDENT_VIDEO
    | typeof NAV_BANK_IDENT_START
    | typeof NAV_BANK_IDENT_MOBILE_NUMBER
    | typeof NAV_BANK_IDENT_IBAN
    | typeof NAV_BANK_IDENT_SIGNING;
}

export const navigateToUniversalStart = () => ({
  type: NAV_UNIVERSAL_START,
});

export const navigateToVideoIdentStart = () => ({
  type: NAV_VIDEO_IDENT_START,
});

export const navigateToVideoIdentVideo = () => ({
  type: NAV_VIDEO_IDENT_VIDEO,
});

export const navigateToBankIdentStart = () => ({
  type: NAV_BANK_IDENT_START,
});

export const navigateToBankIdentMobileNumber = () => ({
  type: NAV_BANK_IDENT_MOBILE_NUMBER,
});

export const navigateToBankIdentIban = () => ({
  type: NAV_BANK_IDENT_IBAN,
});

export const navigateToBankIdentSigning = () => ({
  type: NAV_BANK_IDENT_SIGNING,
});

export const navigateToMobileNumber = () => ({
  type: NAV_BANK_IDENT_MOBILE_NUMBER,
});
