import {
  IRecoverIdentificationActions,
  RECOVER_IDENT_AUTHORIZATION_REQUIRED,
  RECOVER_IDENT_CONFIRMATION_REQUIRED,
  RECOVER_IDENT_CONFIRMED,
  RECOVER_IDENT_EXPIRED,
  RECOVER_IDENT_FAILED,
  RECOVER_IDENT_PENDING,
  RECOVER_IDENT_PROCESS_ERROR,
  RECOVER_IDENT_PROCESS_INIT,
  RECOVER_IDENT_PROCESS_SUCCESS,
  RECOVER_IDENT_SUCCESSFUL,
} from 'redux/actions/recoverIdentificationActions';
import recoverIdentificationInitialState, {
  IRecoverIdentificationInitialState,
} from 'redux/initialState/recoverIdentificationInitialState';
import { ActionState } from 'redux/types';

export const recoverIdentificationReducer = (
  state = recoverIdentificationInitialState,
  action: IRecoverIdentificationActions,
): IRecoverIdentificationInitialState => {
  switch (action.type) {
    case RECOVER_IDENT_PROCESS_INIT:
      return {
        state: ActionState.IN_PROGRESS,
      };
    case RECOVER_IDENT_PROCESS_SUCCESS:
      return {
        state: ActionState.SUCCESS,
      };
    case RECOVER_IDENT_PROCESS_ERROR:
      return {
        state: ActionState.ERROR,
      };
    case RECOVER_IDENT_AUTHORIZATION_REQUIRED:
    case RECOVER_IDENT_CONFIRMATION_REQUIRED:
    case RECOVER_IDENT_CONFIRMED:
    case RECOVER_IDENT_EXPIRED:
    case RECOVER_IDENT_FAILED:
    case RECOVER_IDENT_PENDING:
    case RECOVER_IDENT_SUCCESSFUL:
    default:
      return state;
  }
};

export default recoverIdentificationReducer;
