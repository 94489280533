import { combineReducers } from 'redux';
import configReducer from './configReducers';
import { identificationFileReducer } from './identificationFileReducer';
import { identificationMethodsReducer } from './identificationMethodsReducer';
import { identificationReducer } from './identificationReducers';
import { localStorageReducer } from './localStorageReducers';
import { mobileNumberReducer } from './mobileNumberReducer';
import { navigationReducer } from './navigationReducer';
import { recoverIdentificationReducer } from './recoverIdentificationReducer';
import { sessionReducer } from './sessionReducers';

export default combineReducers({
  mobileNumber: mobileNumberReducer,
  identification: identificationReducer,
  identificationMethods: identificationMethodsReducer,
  identificationFile: identificationFileReducer,
  config: configReducer,
  session: sessionReducer,
  localStorage: localStorageReducer,
  navigation: navigationReducer,
  recoverIdentification: recoverIdentificationReducer,
});
