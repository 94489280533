import styled, { css } from 'styled-components';
import { flexbox, layout, space } from 'styled-system';
import { defaultTheme } from 'theme';
import IFlexContainer, { ContainerVariants } from './types';

export const StyledFlexContainer = styled.div<IFlexContainer>`
  position: relative;
  display: flex;
  ${flexbox}
  ${layout}
  ${space}

  ${({ variant }) => {
    switch (variant) {
      case ContainerVariants.INFO:
        return css`
          background-color: ${defaultTheme.baseColors.base05};
          width: 100%;
        `;
      default:
        return null;
    }
  }}
`;
