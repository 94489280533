export const CONFIG_INIT = 'CONFIG_INIT';
export const CONFIG_SUCCESS = 'CONFIG_SUCCESS';
export const CONFIG_ERROR = 'CONFIG_ERROR';

export interface IConfigAction {
  type: typeof CONFIG_SUCCESS | typeof CONFIG_ERROR;
  data?: {
    sessionToken?: string;
    baseUrl?: string;
    isMobile?: boolean;
    message?: string;
    showSkip?: boolean;
  };
}

interface IConfigSuccessData {
  sessionToken: string;
  baseUrl: string;
  isMobile: boolean;
  showSkip: boolean;
}

export const configInit = () => ({
  type: CONFIG_INIT,
});

export const configSuccess = (data: IConfigSuccessData) => ({
  type: CONFIG_SUCCESS,
  data,
});

export const configError = (data = { message: 'Error' }) => ({
  type: CONFIG_ERROR,
  data,
});
