import { snakeToCamelCase } from 'utils/formatters';
import { ApiError, ApiErrorCode, DEFAULT_ERROR_CODE } from './consts';

export const getErrorCode = (errors: ApiError[]): string => {
  const error = errors[0];
  const ErrorCodeValues = Object.values(ApiErrorCode);

  if (error.code && ErrorCodeValues.includes(error.code)) {
    const { code, source } = error;

    if (source) {
      const { field } = source;
      return snakeToCamelCase(`${code}_${field}`);
    } else {
      return snakeToCamelCase(code);
    }
  } else {
    return DEFAULT_ERROR_CODE;
  }
};
