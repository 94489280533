import { useDispatch } from 'react-redux';
import { identInit } from 'redux/actions/identificationActions';
import { navigateToBankIdentStart } from 'redux/actions/navigationActions';
import { sessionInit } from 'redux/actions/sessionActions';
import {
  useConfigSelector,
  useIdentificationMethodsSelector,
  useIdentificationSelector,
  useMobileNumberSelector,
  useSessionSelector,
} from 'redux/selectors';
import { ActionState, IdentificationMethod } from 'redux/types';
import postMessage from 'utils/postMessage';

const useStartVideoIdentification = () => {
  const dispatch = useDispatch();
  const config = useConfigSelector();
  const mobileNumber = useMobileNumberSelector();
  const session = useSessionSelector();
  const identificationMethods = useIdentificationMethodsSelector();
  const identification = useIdentificationSelector();

  const bankIdentAvailable = identificationMethods.bank;
  const stateRequiresSessionInfo =
    config.state === ActionState.SUCCESS && session.state === ActionState.IDLE;

  const submitInProgress = [identification.state].includes(
    ActionState.IN_PROGRESS,
  );

  const handleCreateVideoIdent = () => {
    dispatch(identInit(IdentificationMethod.IDNOW, ''));
  };

  const handleCallbackClick = () => {
    if (bankIdentAvailable) {
      dispatch(navigateToBankIdentStart());
    } else {
      postMessage('cancel');

      const { callbackUrl } = session;
      window.location.assign(callbackUrl);
    }
  };

  const callbackButtonText = () => {
    return bankIdentAvailable
      ? 'message.selectIdentificationMethod.otherMethod'
      : 'message.button.cancel';
  };

  if (stateRequiresSessionInfo) {
    dispatch(sessionInit());
  }

  return {
    handleCallbackClick,
    callbackButtonText,
    errorMessages: [
      config.errorMessage,
      mobileNumber.errorMessage,
      session.errorMessage,
    ],
    isMobile: config.isMobile,
    mobileNumber,
    submitInProgress,
    handleCreateVideoIdent,
  };
};

export default useStartVideoIdentification;
