import { DefaultTheme } from 'styled-components';

export const breakPoints = {
  xs: 320,
  sm: 567,
  md: 1024,
  lg: 1440,
};

export const defaultTheme: DefaultTheme = {
  colors: {
    primary: '#445566',
    primaryButtonLabel: '#445566',
    primaryButtonHoverBackground: '#3B4A59',
    primaryDark: '#3B4A59',
    secondary: '#5C738A',
    stageBackground: '#445566',
    stageText: '#fff',
  },
  baseColors: {
    base100: '#0f1926',
    base75: '#414d5d',
    base50: '#7e8794',
    base25: '#c9cfd6',
    base20: '#d9d9d9',
    base10: '#e9edf2',
    base05: '#f7f8fa',
    error: '#e23636',
    errorTint: '#fff5f5',
    success: '#09aa67',
    white: '#fff',
  },
  fontFamily: ['Roboto', 'Arial', 'sans-serif'],
  fontSizes: {
    extraSmall: '0.625rem',
    small: '0.75rem',
    regular: '0.875rem',
    medium: '1rem',
    big: '1.125rem',
    large: '1.375rem',
    huge: '1.875rem',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  cardBorderRadius: '0.5rem',
  buttonBorderRadius: '0.25rem',
  inputBorderRadius: '0.25rem',
  inputBorderWidth: '1px',
  breakpoints: {
    xs: `${breakPoints.xs}px`,
    sm: `${breakPoints.sm}px`,
    md: `${breakPoints.md}px`,
    lg: `${breakPoints.lg}px`,
  },
};
