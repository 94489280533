import * as Sentry from '@sentry/react';
import AppRouter from 'components/AppRouter';
import ThemeProvider from 'components/ThemeProvider';
import HelpPopup from '../HelpPopup';

const App = () => (
  <ThemeProvider>
    <HelpPopup />
    <AppRouter />
  </ThemeProvider>
);

export default Sentry.withProfiler(App);
