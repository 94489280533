import React, { useEffect, useState } from 'react';
import Input from 'components/Input';
import ITanInput from './types';

export const handleOnPaste = (
  e: React.ClipboardEvent,
  callback: (args: { tan: string[] }) => void,
) => {
  e.clipboardData.items[0].getAsString((text: string) => {
    if (!text?.length) {
      return;
    }
    const tanCode = text
      .substring(0, 6)
      .split('')
      .map((char: string) => {
        if (Number.isInteger(Number(char))) {
          return String(char);
        }
        return '';
      });
    callback({ tan: tanCode });
  });
};

const TanInput: React.FC<ITanInput> = ({
  value = ['', '', '', '', '', ''],
  onChange = () => {},
  error,
}) => {
  const [focusedInput, setFocusedInput] = useState(-1);

  const handleChange = (
    idx: number,
    target: EventTarget & HTMLInputElement,
  ) => {
    const newValue = target.value;
    if (newValue.length > 1) return;

    const newTan = [...value];
    newTan[idx] = newValue;
    onChange(newTan);

    if (newValue.length === 1 && idx !== 5) {
      setFocusedInput(idx + 1);
    } else if (newValue.length === 0) {
      setFocusedInput(idx - 1);
    } else {
      setFocusedInput(-1);
      target.blur();
    }
  };

  const handleFocus = (idx: number, e: React.FocusEvent<HTMLInputElement>) => {
    setFocusedInput(idx);
    e.target.select();
  };

  useEffect(() => setFocusedInput(0), []);

  return (
    <>
      {[0, 1, 2, 3, 4, 5].map((idx) => (
        <Input
          key={idx}
          oneDigitInput
          name={`tan-${idx}`}
          data-test-id={`tan-${idx}`}
          autoComplete="one-time-code"
          value={value[idx]}
          onChange={({ target }) => handleChange(idx, target)}
          isFocused={focusedInput === idx}
          onFocus={(e) => handleFocus(idx, e)}
          error={error}
        />
      ))}
    </>
  );
};

export default TanInput;
