import {
  ActionState,
  IdentificationMethod,
  IdentificationStatus,
  PaymentInitiationProviderTypes,
  PollingState,
} from 'redux/types';

export type DocumentForSign = {
  id: string;
  name: string;
  content_type: string;
  document_type: string;
  size: number;
  customer_accessible: boolean;
  created_at: string;
};

export interface IIdentificationInitialState {
  state: ActionState;
  status: IdentificationStatus;
  method: IdentificationMethod;
  polling: PollingState;
  documents: DocumentForSign[];
  iban: string;
  url: string;
  new_idnow_url?: string;
  id: string;
  reference: string;
  errorMessage: string;
  loading: boolean;
  paymentInitiationProvider: PaymentInitiationProviderTypes;
}

const identificationInitialState: IIdentificationInitialState = {
  state: ActionState.IDLE,
  status: IdentificationStatus.NONE,
  method: IdentificationMethod.NONE,
  polling: PollingState.IDLE,
  documents: [],
  iban: '',
  url: '',
  new_idnow_url: undefined,
  id: '',
  reference: '',
  errorMessage: '',
  loading: false,
  paymentInitiationProvider: PaymentInitiationProviderTypes.NONE,
};

export default identificationInitialState;
