import { IDENT_CANCEL } from 'redux/actions/identificationActions';
import {
  ILocalStorageAction,
  LOCAL_STORAGE_GET_INIT,
  LOCAL_STORAGE_RESET,
  LOCAL_STORAGE_SET_INIT,
  localStorageGetError,
  localStorageGetSuccess,
  localStorageSetError,
  localStorageSetSuccess,
} from 'redux/actions/localStorageActions';
import {
  recoverIdentProcessInit,
  recoverIdentProcessSuccess,
} from 'redux/actions/recoverIdentificationActions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

export function* setLocalStorage(action: ILocalStorageAction): SagaIterator {
  try {
    const { id } = action.data!;
    if (id) {
      yield call([localStorage, localStorage.setItem], 'id', id);
      yield put(localStorageSetSuccess());
    }
  } catch (error) {
    yield call([localStorage, localStorage.clear]);
    // TODO
    // WHAT IS THIS ERROR CASES SUPPOSSED TO DO
    yield put(localStorageSetError({ message: error.message }));
  }
}

export function* watchSetLocalStorage() {
  yield takeLatest(LOCAL_STORAGE_SET_INIT, setLocalStorage);
}

export function* getLocalStorage(): SagaIterator {
  try {
    const id = yield call([localStorage, localStorage.getItem], 'id');
    if (id) {
      yield put(localStorageGetSuccess());
      yield put(recoverIdentProcessInit(id));
    } else {
      yield put(localStorageGetSuccess());
      yield put(recoverIdentProcessSuccess());
      yield call([localStorage, localStorage.clear]);
    }
  } catch (error) {
    // TODO
    // WHAT IS THIS ERROR CASES SUPPOSSED TO DO
    yield put(localStorageGetError({ message: error.message }));
  }
}

export function* watchGetLocalStorage() {
  yield takeLatest(LOCAL_STORAGE_GET_INIT, getLocalStorage);
}

export function* clearLocalStorage() {
  yield call([localStorage, localStorage.clear]);
}

export function* watchClearLocalStorage() {
  yield takeLatest(LOCAL_STORAGE_RESET, clearLocalStorage);
}

export function* watchClearLocalStorageOnCancellation() {
  yield takeLatest(IDENT_CANCEL, clearLocalStorage);
}
