import React, { useEffect, useState } from 'react';
import { useSessionSelector } from '../../redux/selectors';
import { getColorLightness } from '../../utils/colorHelpers';
import { StyledFooterBranding } from './FooterBranding.styled';
import { ReactComponent as LogoDark } from './powered-by-sb.svg';
import { ReactComponent as LogoLight } from './powered-by-sb_white.svg';

export const FooterBranding: React.FC = () => {
  const style = useSessionSelector().style;
  const stageBackground = style.colors?.stageBackground;

  const [isStageLight, setIsStageLight] = useState<boolean>(false);

  useEffect(() => {
    setIsStageLight(getColorLightness(stageBackground) === 'light');
  }, [stageBackground]);

  return (
    <StyledFooterBranding>
      {isStageLight ? <LogoDark /> : <LogoLight />}
    </StyledFooterBranding>
  );
};
