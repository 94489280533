import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from 'components/Layout';
import Paragraph from 'components/Paragraph';
import ProgressBar from 'components/ProgressBar';
import SkipIdentification from 'components/SkipIdentification/SkipIdentification';
import { IdentificationMethod } from 'redux/types';
import IdentificationIframe from 'sections/IdentificationIframe';
import IdentRetryWithVideo from 'sections/IdentRetryWithVideo';
import useBankIdent from 'utils/hooks/useBankIdent';
import Loading from '../../components/Loading/Loading';
import { BankIdentificationIbanError } from './BankIdentificationIbanError';
import { BankIdentificationIbanForm } from './BankIdentificationIbanForm';
import { BankIdentificationIbanSuccessful } from './BankIdentificationIbanSuccessful';

export interface IFormInputs {
  iban: string;
  terms_and_conditions_signed: boolean;
}

const BankIdentificationIban = () => {
  const {
    startBankIdent,
    errorMessage,
    handleNavigateToVideoIdent,
    reset,
    identificationPollingInProgress,
    identificationIsCancelled,
    identificationIsSuccessful,
    identificationInFailedState,
    identificationInErrorState,
    identificationIsPending,
    identificationIsAuthorizationRequired,
    hasNoIdentificationMethod,
    identificationMethod,
    identificationUrl,
    isLoading,
    identCancel,
  } = useBankIdent();

  const onSubmit = (values: IFormInputs) => {
    startBankIdent(values.iban);
  };

  let content = null;
  if (hasNoIdentificationMethod && !identificationInFailedState) {
    content = (
      <BankIdentificationIbanForm
        onSubmit={onSubmit}
        error={identificationInErrorState}
        errorMessage={errorMessage}
        loading={isLoading}
        navigateToVideoIdentStart={handleNavigateToVideoIdent}
      />
    );
  }

  if (
    identificationIsCancelled &&
    identificationMethod === IdentificationMethod.IDNOW
  ) {
    content = (
      <BankIdentificationIbanForm
        onSubmit={onSubmit}
        error={identificationInErrorState}
        errorMessage={errorMessage}
        loading={isLoading}
        navigateToVideoIdentStart={handleNavigateToVideoIdent}
      />
    );
  }

  if (errorMessage) {
    content = (
      <BankIdentificationIbanError
        errorMessage={errorMessage}
        navigateToVideoIdentStart={handleNavigateToVideoIdent}
        onRetryClick={reset}
      />
    );
  }

  if (identificationIsAuthorizationRequired) {
    content = <BankIdentificationIbanSuccessful />;
  }
  if (isLoading && !identificationPollingInProgress) {
    content = <Loading type="connecting" />;
  }

  if (identificationPollingInProgress) {
    content = <Loading type="processing" />;
  }

  if (identificationIsPending && !identificationPollingInProgress) {
    // TW: 16-07 WILL BE REMOVED ASAP
    // https://jira.solarisbank.de/browse/IDENT-2092
    const modifiedUrl = identificationUrl.includes('finleap')
      ? identificationUrl + '&product=ekyc1'
      : identificationUrl;
    content = (
      <IdentificationIframe
        type={IdentificationMethod.BANK}
        iframeUrl={modifiedUrl}
        cancelAction={identCancel}
      />
    );
  }

  if (
    identificationIsCancelled &&
    identificationMethod === IdentificationMethod.BANK
  ) {
    content = (
      <Fragment>
        <h2>
          <FormattedMessage id="message.startBankIdentification.titleContinue" />
        </h2>
        <Paragraph marginBottom="2em">
          <FormattedMessage id="message.startBankIdentification.descriptionContinue" />
        </Paragraph>
        <IdentRetryWithVideo
          retryIdent={reset}
          navigateToVideoIdentStart={handleNavigateToVideoIdent}
        />
      </Fragment>
    );
  }

  return (
    <Layout>
      <SkipIdentification />
      {!identificationIsSuccessful && (
        <ProgressBar step={1} type={identificationMethod} />
      )}
      {content}
    </Layout>
  );
};

export default BankIdentificationIban;
