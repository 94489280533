import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { configInit } from 'redux/actions/configActions';
import {
  useConfigSelector,
  useIdentificationMethodsSelector,
  useIdentificationSelector,
  useLocalStorageSelector,
  useMobileNumberSelector,
  useRecoverIdentSelector,
  useSessionSelector,
} from 'redux/selectors';
import { ActionState } from 'redux/types';

const useInitializeApp = () => {
  const dispatch = useDispatch();
  const session = useSessionSelector();
  const config = useConfigSelector();
  const localStorage = useLocalStorageSelector();
  const identRecover = useRecoverIdentSelector();
  const identification = useIdentificationSelector();
  const identificationMethods = useIdentificationMethodsSelector();
  const mobileNumber = useMobileNumberSelector();

  const sessionLoaded = session.state === ActionState.SUCCESS;
  const configLoaded = config.state === ActionState.SUCCESS;
  const localStorageChecked = localStorage.state === ActionState.SUCCESS;
  const identRecoverCompleted = identRecover.state === ActionState.SUCCESS;
  const identificationMethodsLoaded =
    identificationMethods.state === ActionState.SUCCESS;
  const hasIdentificationMethodBank = identificationMethods.bank;

  const mobileNumberPresent = mobileNumber.number?.length > 0;

  const defaultInitializationTasks =
    configLoaded &&
    sessionLoaded &&
    localStorageChecked &&
    identRecoverCompleted &&
    identificationMethodsLoaded;

  const bankSpecificInitializationTasks =
    defaultInitializationTasks && mobileNumberPresent;

  const initializationCompleted = hasIdentificationMethodBank
    ? bankSpecificInitializationTasks
    : defaultInitializationTasks;

  useEffect(() => {
    dispatch(configInit());
  }, [dispatch]);

  return {
    errorMessages: [
      config.errorMessage,
      session.errorMessage,
      localStorage.errorMessage,
      identification.errorMessage,
      mobileNumber.errorMessage,
      identificationMethods.errorMessage,
    ],
    loaded: initializationCompleted,
  };
};

export default useInitializeApp;
