const validateTan = (value: string[]) => {
  if (value.every((v) => v === ''))
    return 'message.phoneVerification.tanValidationError.required';
  if (value.some((v) => v === ''))
    return 'message.phoneVerification.tanValidationError.invalidLength';
  if (!/^[0-9]{1,6}$/.test(value.join('')))
    return 'message.phoneVerification.tanValidationError.invalidFormat';
  return true;
};
export default validateTan;
