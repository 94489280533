import styled from 'styled-components';

export const StyledHeaderBranding = styled.div`
  color: ${({ theme }) => theme.colors.stageText || theme.baseColors.white};
  display: grid;
  gap: calc(5vh - 1rem);
  justify-items: center;
  padding-top: calc(1rem + 5vh);
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: 1rem;
    padding-top: 1.5rem;
  }
`;
