import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  identCancel,
  identPollInit,
} from 'redux/actions/identificationActions';
import {
  navigateToUniversalStart,
  navigateToVideoIdentStart,
} from 'redux/actions/navigationActions';
import {
  useConfigSelector,
  useIdentificationMethodsSelector,
  useIdentificationSelector,
} from 'redux/selectors';
import {
  ActionState,
  IdentificationMethod,
  IdentificationStatus,
  PollingState,
} from 'redux/types';

const useVideoIdent = () => {
  const dispatch = useDispatch();
  const identification = useIdentificationSelector();
  const identificationId = identification.id;
  const { isMobile } = useConfigSelector();
  const identificationMethods = useIdentificationMethodsSelector();

  const hasIdentificationMethodBank = identificationMethods.bank;
  const identificationPollingInProgress =
    identification.polling === PollingState.POLLING;

  const identificationIsPending =
    identification.status === IdentificationStatus.PENDING &&
    identification.url;
  const identificationIsCancelled =
    identification.state === ActionState.CANCELLED;

  const isBankIdentification =
    identification.method === IdentificationMethod.BANK;
  const identificationIsSuccessful =
    identification.status === IdentificationStatus.SUCCESSFUL;
  const identificationUrl = identification.new_idnow_url || identification.url;
  const identificationReference = identification.reference;
  const isLoading = identification.loading;

  const handleCancel = () => {
    dispatch(identCancel());
  };

  useEffect(() => {
    if (identificationIsPending && !identificationPollingInProgress) {
      dispatch(identPollInit());
    }
  }, [dispatch, identificationIsPending, identificationPollingInProgress]);

  useEffect(() => {
    if (identificationIsCancelled && hasIdentificationMethodBank) {
      dispatch(navigateToUniversalStart());
    }
    if (identificationIsCancelled && !hasIdentificationMethodBank) {
      dispatch(navigateToVideoIdentStart());
    }
  }, [
    dispatch,
    hasIdentificationMethodBank,
    identification,
    identificationIsCancelled,
    isBankIdentification,
  ]);

  return {
    identCancel: handleCancel,
    identificationIsPending,
    identificationIsSuccessful,
    identificationUrl,
    identificationId,
    identificationReference,
    isLoading,
    isMobile,
  };
};

export default useVideoIdent;
