import { flattenMessages } from '../utils';
import { enErrors } from './enErrors';
import { enMessages } from './enMessages';

const enLocals = flattenMessages({
  message: enMessages,
  error: enErrors,
});

export default enLocals;
