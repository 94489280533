import { ApiErrorCode } from 'api/consts';
import {
  authorizeIdent,
  confirmIdent,
  createBankIdentification,
  createIdNowIdentification,
  getIdent,
} from 'api/endpoints';
import {
  IDENT_AUTHORIZATION_INIT,
  IDENT_INIT,
  IDENT_POLL_INIT,
  IDENT_TAN_ACCEPTANCE_INIT,
  identAuthorizationError,
  identAuthorizationInit,
  identAuthorizationSuccess,
  identFailed,
  identPollError,
  identPollInit,
  identPollSuccess,
  identSuccess,
  identTanAcceptanceError,
  identTanAcceptanceSuccess,
  IIdentificationAction,
} from 'redux/actions/identificationActions';
import { loadFileList } from 'redux/actions/identificationFileActions';
import { localStorageSetInit } from 'redux/actions/localStorageActions';
import {
  navigateToBankIdentIban,
  navigateToBankIdentSigning,
  navigateToVideoIdentVideo,
} from 'redux/actions/navigationActions';
import { DocumentForSign } from 'redux/initialState/identificationInitialState';
import { configSelector, identificationSelector } from 'redux/selectors';
import { IdentificationMethod, IdentificationStatus } from 'redux/types';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { resolvePaymentInitiationProvider } from 'utils/environments';
import { snakeToCamelCase } from 'utils/formatters';

export function* createIdentification(
  action: IIdentificationAction,
): SagaIterator {
  try {
    const config = yield select(configSelector);
    const { baseUrl, sessionToken } = config;
    const method = action.data!.method!;

    if (method === IdentificationMethod.IDNOW) {
      yield put(navigateToVideoIdentVideo());

      const { requestMethod, getUrl, getRequestBody, getErrorCode } =
        createIdNowIdentification;

      const response = yield call(
        requestMethod,
        getUrl(baseUrl),
        getRequestBody(),
        sessionToken,
      );

      if (response.errors) {
        throw new Error(getErrorCode(response.errors));
      }
      const { id, url, new_idnow_url, status, iban, method, reference } =
        response;
      const paymentInitiationProvider = resolvePaymentInitiationProvider(url);

      yield put(
        identSuccess(
          id,
          url,
          status,
          iban,
          method,
          paymentInitiationProvider,
          reference,
          new_idnow_url,
        ),
      );
      yield put(localStorageSetInit({ id }));
      yield put(identPollInit());
    } else {
      const currentDateTime = new Date().toISOString();

      const { requestMethod, getUrl, getRequestBody, getErrorCode } =
        createBankIdentification;

      const response = yield call(
        requestMethod,
        getUrl(baseUrl),
        getRequestBody(action.data!.iban!, currentDateTime),
        sessionToken,
      );

      if (response.errors) {
        throw new Error(getErrorCode(response.errors));
      }
      const { id, url, status, iban, method, reference } = response;
      const paymentInitiationProvider = resolvePaymentInitiationProvider(url);

      yield put(
        identSuccess(
          id,
          url,
          status,
          iban,
          method,
          paymentInitiationProvider,
          reference,
        ),
      );
      yield put(localStorageSetInit({ id }));
    }
  } catch (error) {
    yield put(identFailed({ errorMessage: error.message }));
  }
}

function* watchIdentInit() {
  yield takeLatest(IDENT_INIT, createIdentification);
}

export function* identPoll(): SagaIterator {
  try {
    const config = yield select(configSelector);
    const { baseUrl, sessionToken } = config;
    const identification = yield select(identificationSelector);
    let poll = true;

    const { requestMethod, getUrl, getErrorCode } = getIdent;
    while (poll) {
      const response = yield call(
        requestMethod,
        getUrl(baseUrl, identification.id),
        sessionToken,
      );
      if (response.errors) {
        throw new Error(getErrorCode(response.errors));
      }
      if (response.status === IdentificationStatus.FAILED) {
        yield put(identFailed());
        throw new Error(snakeToCamelCase(response.failure_reason));
      }
      if (response.status === IdentificationStatus.AUTHORIZATION_REQUIRED) {
        poll = false;
        if (response.documents && response.documents.length > 0) {
          yield put(
            loadFileList(
              response.documents.map((d: DocumentForSign) => ({
                id: d.id,
                downloadInProgress: false,
                errorMessage: '',
              })),
            ),
          );
        }
        yield put(identPollSuccess(response.status, response.documents));
      }
      if (response.status === IdentificationStatus.CONFIRMED) {
        // PERHAPS A LOADING SCREEN FOR BANK IDENT
      }
      if (response.status === IdentificationStatus.SUCCESSFUL) {
        poll = false;
        let signedDocuments = [];
        if (response.documents && response.documents.length > 0) {
          signedDocuments = response.documents.filter((d: DocumentForSign) =>
            d.document_type.startsWith('SIGNED_'),
          );
          yield put(
            loadFileList(
              signedDocuments.map((d: DocumentForSign) => ({
                id: d.id,
                downloadInProgress: false,
                errorMessage: '',
              })),
            ),
          );
        }
        yield put(identPollSuccess(response.status, signedDocuments));
      }
      yield delay(2000);
    }
  } catch (error) {
    yield put(identPollError({ errorMessage: error.message }));
  }
}

function* watchIdentPollInit() {
  yield takeLatest(IDENT_POLL_INIT, identPoll);
}

export function* identAuthorization(): SagaIterator {
  try {
    const config = yield select(configSelector);
    const { baseUrl, sessionToken } = config;
    const identification = yield select(identificationSelector);

    const { requestMethod, getUrl, getRequestBody, getErrorCode } =
      authorizeIdent;

    const response = yield call(
      requestMethod,
      getUrl(baseUrl, identification.id),
      getRequestBody(),
      sessionToken,
    );
    if (response.errors) {
      throw new Error(getErrorCode(response.errors));
    }
    yield put(
      identAuthorizationSuccess(
        response.current_reference_token,
        response.status,
      ),
    );
    yield put(navigateToBankIdentSigning());
  } catch (error) {
    if (error.message === snakeToCamelCase(ApiErrorCode.EXPECTATION_MISMATCH)) {
      yield put(identFailed());
      yield put(navigateToBankIdentIban());
    } else {
      yield put(identAuthorizationError({ errorMessage: error.message }));
    }
  }
}

function* watchIdentAuthorizationInit() {
  yield takeLatest(IDENT_AUTHORIZATION_INIT, identAuthorization);
}

export function* identConfirmation(
  action: IIdentificationAction,
): SagaIterator {
  try {
    const config = yield select(configSelector);
    const { baseUrl, sessionToken } = config;
    const identification = yield select(identificationSelector);

    const { requestMethod, getUrl, getRequestBody, getErrorCode } =
      confirmIdent;

    const response = yield call(
      requestMethod,
      getUrl(baseUrl, identification.id),
      getRequestBody(action.data!.tan!),
      sessionToken,
    );

    if (response.errors) {
      throw new Error(getErrorCode(response.errors));
    }
    const { status } = response;
    yield put(identTanAcceptanceSuccess(status));
    yield put(identPollInit());
  } catch (error) {
    const camelCasedRecoverableErrors = [
      snakeToCamelCase(ApiErrorCode.INVALID_TOKEN),
      snakeToCamelCase(ApiErrorCode.DEPENDENCY_ERROR),
    ];
    if (camelCasedRecoverableErrors.includes(error.message)) {
      yield put(identTanAcceptanceError({ errorMessage: error.message }));
      yield put(identAuthorizationInit());
    } else {
      yield put(identFailed());
      yield put(navigateToBankIdentIban());
      yield put(identTanAcceptanceError({ errorMessage: error.message }));
    }
  }
}

function* watchIdentConfirmationInit() {
  yield takeLatest(IDENT_TAN_ACCEPTANCE_INIT, identConfirmation);
}

export {
  watchIdentInit as identSaga,
  watchIdentPollInit as identPollSaga,
  watchIdentAuthorizationInit as identAuthorizationSaga,
  watchIdentConfirmationInit as identConfirmationSaga,
};
