import React from 'react';
import { useSessionSelector } from 'redux/selectors';
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import { defaultTheme } from 'theme';
import RobotoBold from './fonts/Roboto-Bold.ttf';
import RobotoMedium from './fonts/Roboto-Medium.ttf';
import RobotoRegular from './fonts/Roboto-Regular.ttf';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoRegular}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoMedium}) format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBold}) format('truetype');
    font-weight: 700;
  }

  body {
    background-color: ${({ theme }) =>
      theme.colors.stageBackground || theme.colors.primary};
    font-family: ${({ theme }) => `'${theme.fontFamily.join("', '")}'`};
    font-size: ${({ theme }) => theme.fontSizes.regular};
    color: ${({ theme }) => theme.baseColors.base75};
    transition: all 1s ease-in-out;
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.huge};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    margin: 0.625em 0 2em 0;
    line-height: 1.2;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: ${({ theme }) => `calc(${theme.fontSizes.huge} * 0.8)`};
    }
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: 1.2;
    margin: 0 0 0.5em 0;
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: 1.4;
  }

  p {
    max-width: 32em;
    line-height: 1.5;
  }

  label {
    font-size: ${({ theme }) => theme.fontSizes.regular};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.baseColors.base75};
  }

  small {
    font-size: 0.875em;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primaryDark};

    &:hover {
      opacity: 0.7;
      text-decoration: underline;
    }
  }

`;

const ThemeProvider: React.FC = ({ children }) => {
  const themeOverrides: Partial<DefaultTheme> = useSessionSelector().style;
  const theme = { ...defaultTheme, ...themeOverrides };
  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyles />
      <>{children}</>
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
