import React, { useEffect, useRef, useState } from 'react';
import { CountdownClock, StyledCountdownTimer } from './CountdownTimer.styled';
import ICountdownTimer from './types';

const DEFAULT_COUNTDOWN_DURATION = 20;

const CountdownTimer: React.FC<ICountdownTimer> = ({
  durationInSecond = DEFAULT_COUNTDOWN_DURATION,
  afterTimeEndAction,
  children,
}) => {
  const [seconds, setSeconds] = useState(durationInSecond);
  const [timeLeft, setTimeLeft] = useState('');
  const intervalRef = useRef<number>(0);

  const buildTimeLeft = (seconds: number) => {
    const secondsLeft = String(Math.floor(seconds % 60)).padStart(2, '0');

    return `00:${secondsLeft}`;
  };

  useEffect(() => {
    if (seconds <= 0) {
      afterTimeEndAction();
    } else {
      const tick = () => setSeconds((prevSeconds) => prevSeconds - 1);
      intervalRef.current = window.setInterval(() => tick(), 1000);

      setTimeLeft(buildTimeLeft(seconds));
    }
    return () => clearInterval(intervalRef.current);
  }, [seconds, afterTimeEndAction]);

  return (
    <StyledCountdownTimer>
      {children} <CountdownClock>{timeLeft}</CountdownClock>
    </StyledCountdownTimer>
  );
};

export default CountdownTimer;
