import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import CountdownTimer from 'components/CountdownTimer';
import FlexContainer from 'components/FlexContainer';
import Paragraph from 'components/Paragraph';
import { useSessionSelector } from 'redux/selectors';
import postMessage from 'utils/postMessage';
import Icon from '../../components/Icon';
import { defaultTheme } from '../../theme';

const VideoIdentificationSuccessful = () => {
  const COUNTDOWN_TIMER_IN_SECONDS = 3;
  const session = useSessionSelector();

  const completeIdentWithRedirect = () => {
    postMessage('finish');

    const { callbackUrl } = session;
    const url = new URL(callbackUrl);
    url.searchParams.append('solaris_redirect', 'true');

    window.location.assign(url);
  };

  return (
    <FlexContainer flexDirection="column" alignItems="flex-start">
      <h2>
        <Icon
          name="Check"
          color={defaultTheme.baseColors.success}
          marginRight="0.5rem"
          width="1.5rem"
          height="1.5rem"
          top="0.2rem"
        />
        <FormattedMessage id="message.videoIdentification.successful.title" />
      </h2>
      <Paragraph marginBottom="2.5rem" paddingLeft="2rem">
        <FormattedMessage id="message.videoIdentification.successful.description" />
      </Paragraph>
      <CountdownTimer
        afterTimeEndAction={completeIdentWithRedirect}
        durationInSecond={COUNTDOWN_TIMER_IN_SECONDS}
      >
        <FormattedMessage id="message.videoIdentification.successful.automaticRedirect" />
      </CountdownTimer>
      <FlexContainer width="100%" justifyContent="flex-end">
        <Button onClick={completeIdentWithRedirect}>
          <FormattedMessage id="message.button.finish" />
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default VideoIdentificationSuccessful;
