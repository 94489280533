import * as React from 'react';

const SvgInfoLock = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <rect
      fill="none"
      x="1"
      y="6.5"
      width="11"
      height="9.16667"
      rx="1"
      stroke="#7F7F7F"
      stroke-width="1.2"
      stroke-linecap="round"
    />
    <path
      fill="none"
      d="M2.83301 6.5V3C2.83301 1.89543 3.72844 1 4.83301 1H8.16634C9.27091 1 10.1663 1.89543 10.1663 3V6.5"
      stroke="#7F7F7F"
      stroke-width="1.2"
    />
  </svg>
);

export default SvgInfoLock;
