const inBrowser = typeof window !== 'undefined';
const userAgent = inBrowser && window.navigator.userAgent.toLowerCase();

const isSafari =
  userAgent &&
  /safari/i.test(userAgent) &&
  !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(
    userAgent,
  );

export { isSafari };
