import React from 'react';
import FlexContainer from 'components/FlexContainer';
import { DocumentForSign } from 'redux/initialState/identificationInitialState';
import DocumentsListElement from './DocumentsListElement';
import IDocumentsList from './types';

const DocumentsList: React.FC<IDocumentsList> = ({
  documents,
  filesDownloads = [],
  signed,
}) => {
  const documentsToShow = signed
    ? documents.filter((d: DocumentForSign) =>
        d.document_type.startsWith('SIGNED_'),
      )
    : documents;

  return (
    <FlexContainer flexDirection="column" width="100%" marginBottom="1.5rem">
      {documentsToShow?.map((d) => (
        <DocumentsListElement
          document={d}
          signed={signed}
          downloading={
            filesDownloads.find((fd) => fd.id === d.id)?.downloadInProgress
          }
          key={d.id}
        />
      ))}
    </FlexContainer>
  );
};

export default DocumentsList;
