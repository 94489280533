import { ActionState } from 'redux/types';

export interface IRecoverIdentificationInitialState {
  state: ActionState;
}

const recoverIdentificationInitialState: IRecoverIdentificationInitialState = {
  state: ActionState.IDLE,
};

export default recoverIdentificationInitialState;
