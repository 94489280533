import styled from 'styled-components';

export const StyledFooterBranding = styled.div`
  margin: 1.5rem 0;
  text-align: center;
  width: 100%;

  svg {
    height: 1.25rem;
    width: auto;
  }
`;
