import Layout from 'components/Layout';
import ProgressBar from 'components/ProgressBar';
import { IdentificationMethod } from 'redux/types';
import PhoneVerificationNeeded from 'sections/PhoneVerificationNeeded';
import usePhoneVerification from 'utils/hooks/usePhoneVerification';
import SkipIdentification from '../../components/SkipIdentification/SkipIdentification';
import PhoneVerificationSuccess from './PhoneVerificationSuccess';

const PhoneVerification = () => {
  const {
    mobileNumberConfirmationIsSuccessful,
    bankIdentAvailable,
    mobileNumber,
    mobileNumberConfirmInit,
    mobileNumberAuthorizeInit,
    navigateToUniversalStart,
  } = usePhoneVerification();

  return (
    <Layout>
      <SkipIdentification />
      <ProgressBar step={0} type={IdentificationMethod.NONE} />
      {mobileNumberConfirmationIsSuccessful ? (
        <PhoneVerificationSuccess bankIdentAvailable={bankIdentAvailable} />
      ) : (
        <PhoneVerificationNeeded
          mobileNumber={mobileNumber}
          onSubmitAction={mobileNumberConfirmInit}
          retryAction={mobileNumberAuthorizeInit}
          navigateToStart={navigateToUniversalStart}
        />
      )}
    </Layout>
  );
};

export default PhoneVerification;
