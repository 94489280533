import {
  IDENT_METHOD_ELIGIBILTY_ERROR,
  IDENT_METHOD_ELIGIBILTY_INIT,
  IDENT_METHOD_ELIGIBILTY_SUCCESS,
  IDENT_METHODS_GET_ERROR,
  IDENT_METHODS_GET_INIT,
  IDENT_METHODS_GET_SUCCESS,
} from 'redux/actions/identificationMethodsActions';
import identificationMethodsInitialState, {
  IIdentificationMethodsInitialState,
} from 'redux/initialState/identificationMethodsInitialState';
import { ActionState } from 'redux/types';

export const identificationMethodsReducer = (
  state = identificationMethodsInitialState,
  action: any,
): IIdentificationMethodsInitialState => {
  switch (action.type) {
    case IDENT_METHOD_ELIGIBILTY_INIT:
    case IDENT_METHODS_GET_INIT:
      return {
        ...state,
        state: ActionState.IN_PROGRESS,
        loading: true,
      };
    case IDENT_METHOD_ELIGIBILTY_SUCCESS:
      return {
        ...state,
        state: ActionState.SUCCESS,
        bank: action.data.bank,
        video: action.data.idnow,
      };
    case IDENT_METHODS_GET_SUCCESS:
      return {
        ...state,
        state: ActionState.IN_PROGRESS,
        bank: action.data.bank,
        video: action.data.idnow,
      };

    case IDENT_METHODS_GET_ERROR:
    case IDENT_METHOD_ELIGIBILTY_ERROR:
      return {
        ...state,
        state: ActionState.ERROR,
        errorMessage: action.data!.errorMessage!,
        loading: false,
      };
    default:
      return state;
  }
};
