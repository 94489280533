import {
  IdentificationMethod,
  IdentificationStatus,
  PaymentInitiationProviderTypes,
} from 'redux/types';
import { DocumentForSign } from '../initialState/identificationInitialState';

export const IDENT_INIT = 'IDENT_INIT';
export const IDENT_SUCCESS = 'IDENT_SUCCESS';
export const IDENT_FAILED = 'IDENT_FAILED';
export const IDENT_ERROR = 'IDENT_ERROR';

export const IDENT_GET_INIT = 'IDENT_GET_INIT';
export const IDENT_GET_SUCCESS = 'IDENT_GET_SUCCESS';
export const IDENT_GET_ERROR = 'IDENT_GET_ERROR';

export const IDENT_REQUEST_INIT = 'IDENT_REQUEST_INIT';
export const IDENT_REQUEST_SUCCESS = 'IDENT_REQUEST_SUCCESS';
export const IDENT_REQUEST_ERROR = 'IDENT_REQUEST_ERROR';

export const IDENT_POLL_INIT = 'IDENT_POLL_INIT';
export const IDENT_POLL_SUCCESS = 'IDENT_POLL_SUCCESS';
export const IDENT_POLL_ERROR = 'IDENT_POLL_ERROR';

export const IDENT_AUTHORIZATION_INIT = 'IDENT_AUTHORIZATION_INIT';
export const IDENT_AUTHORIZATION_SUCCESS = 'IDENT_AUTHORIZATION_SUCCESS';
export const IDENT_AUTHORIZATION_ERROR = 'IDENT_AUTHORIZATION_ERROR';

export const IDENT_TAN_ACCEPTANCE_INIT = 'IDENT_TAN_ACCEPTANCE_INIT';
export const IDENT_TAN_ACCEPTANCE_SUCCESS = 'IDENT_TAN_ACCEPTANCE_SUCCESS';
export const IDENT_TAN_ACCEPTANCE_ERROR = 'IDENT_TAN_ACCEPTANCE_ERROR';

export const IDENT_RESET = 'IDENT_RESET';
export const IDENT_CANCEL = 'IDENT_CANCEL';

export interface IIdentificationAction {
  type:
    | typeof IDENT_INIT
    | typeof IDENT_SUCCESS
    | typeof IDENT_FAILED
    | typeof IDENT_ERROR
    | typeof IDENT_REQUEST_INIT
    | typeof IDENT_REQUEST_SUCCESS
    | typeof IDENT_REQUEST_ERROR
    | typeof IDENT_POLL_INIT
    | typeof IDENT_POLL_SUCCESS
    | typeof IDENT_POLL_ERROR
    | typeof IDENT_AUTHORIZATION_INIT
    | typeof IDENT_AUTHORIZATION_SUCCESS
    | typeof IDENT_AUTHORIZATION_ERROR
    | typeof IDENT_TAN_ACCEPTANCE_INIT
    | typeof IDENT_TAN_ACCEPTANCE_SUCCESS
    | typeof IDENT_TAN_ACCEPTANCE_ERROR
    | typeof IDENT_RESET
    | typeof IDENT_CANCEL
    | typeof IDENT_GET_INIT
    | typeof IDENT_GET_SUCCESS
    | typeof IDENT_GET_ERROR;
  data?: {
    message?: string;
    iban?: string;
    id?: string;
    url?: string;
    new_idnow_url?: string;
    documents?: DocumentForSign[];
    tan?: string;
    reference?: string;
    method?: IdentificationMethod;
    paymentInitiationProvider?: PaymentInitiationProviderTypes;
    status?: IdentificationStatus;
    errorMessage?: string;
  };
}

export const getIdentInit = (id: string) => ({
  type: IDENT_GET_INIT,
  data: { id },
});

export const getIdentSuccess = (
  id: string,
  status: IdentificationStatus,
  method: IdentificationMethod,
  paymentInitiationProvider: PaymentInitiationProviderTypes,
  iban?: string,
  url?: string,
  documents?: DocumentForSign[],
  reference?: string,
  errorMessage?: string,
) => ({
  type: IDENT_GET_SUCCESS,
  data: {
    id,
    url,
    status,
    iban,
    method,
    paymentInitiationProvider,
    documents,
    reference,
    errorMessage,
  },
});

export const getIdentError = (data = { errorMessage: 'Error' }) => ({
  type: IDENT_GET_ERROR,
  data,
});

export const identInit = (method: IdentificationMethod, iban: string) => ({
  type: IDENT_INIT,
  data: { method, iban },
});

export const identSuccess = (
  id: string,
  url: string,
  status: IdentificationStatus,
  iban: string,
  method: IdentificationMethod,
  paymentInitiationProvider: PaymentInitiationProviderTypes,
  reference?: string,
  new_idnow_url?: string,
) => ({
  type: IDENT_SUCCESS,
  data: {
    id,
    url,
    new_idnow_url,
    status,
    iban,
    method,
    paymentInitiationProvider,
    reference,
  },
});

export const identError = (data = { errorMessage: 'Error' }) => ({
  type: IDENT_ERROR,
  data,
});

export const identFailed = (data = { errorMessage: 'Error' }) => ({
  type: IDENT_FAILED,
  data,
});

export const identRequestInit = () => ({
  type: IDENT_REQUEST_INIT,
});

export const identRequestSuccess = (
  url: string,
  status: IdentificationStatus,
) => ({
  type: IDENT_REQUEST_SUCCESS,
  data: { url, status },
});

export const identRequestError = (data = { errorMessage: 'Error' }) => ({
  type: IDENT_REQUEST_ERROR,
  data,
});

export const identPollInit = () => ({
  type: IDENT_POLL_INIT,
});

export const identPollSuccess = (
  status: IdentificationStatus,
  documents: DocumentForSign[],
) => ({
  type: IDENT_POLL_SUCCESS,
  data: { documents, status },
});

export const identPollError = (data = { errorMessage: 'Error' }) => ({
  type: IDENT_POLL_ERROR,
  data,
});

export const identAuthorizationInit = () => ({
  type: IDENT_AUTHORIZATION_INIT,
});

export const identAuthorizationSuccess = (
  reference: string,
  status: IdentificationStatus,
) => ({
  type: IDENT_AUTHORIZATION_SUCCESS,
  data: { reference, status },
});

export const identAuthorizationError = (data = { errorMessage: 'Error' }) => ({
  type: IDENT_AUTHORIZATION_ERROR,
  data,
});

export const identTanAcceptanceInit = (tan: string) => ({
  type: IDENT_TAN_ACCEPTANCE_INIT,
  data: { tan },
});

export const identTanAcceptanceSuccess = (status: IdentificationStatus) => ({
  type: IDENT_TAN_ACCEPTANCE_SUCCESS,
  data: { status },
});

export const identTanAcceptanceError = (data = { errorMessage: 'Error' }) => ({
  type: IDENT_TAN_ACCEPTANCE_ERROR,
  data,
});

export const identReset = () => ({
  type: IDENT_RESET,
});

export const identCancel = () => ({
  type: IDENT_CANCEL,
});
