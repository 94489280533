import React from 'react';
import { CbSpinner } from 'components/Icon/Icons';
import { StyledSpinner } from './Spinner.styled';
import ISpinner from './types';

const Spinner: React.FC<ISpinner> = ({ scale = 1 }) => (
  <StyledSpinner scale={scale}>
    <CbSpinner width="48" height="48" />
  </StyledSpinner>
);

export default Spinner;
