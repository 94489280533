import React from 'react';
import { FormattedMessage } from 'react-intl';
import FlexContainer from 'components/FlexContainer';
import { IdentificationMethod } from 'redux/types';
import { BankProgressSteps, videoProgressSteps } from './consts';
import {
  CurrentStep,
  NextStep,
  ProgressBarFragment,
} from './ProgressBar.styled';
import IProgressBar, { Step } from './types';

const ProgressBar: React.FC<IProgressBar> = ({ step, type }) => {
  const progressSteps =
    type === IdentificationMethod.IDNOW
      ? videoProgressSteps
      : BankProgressSteps;

  return (
    <FlexContainer flexDirection="column" width="100%" marginBottom="2rem">
      <FlexContainer
        justifyContent="space-between"
        alignItems="baseline"
        marginBottom="0.75rem"
      >
        <CurrentStep>
          <FormattedMessage
            id={`message.progressBar.steps.${progressSteps[step]}`}
          />
        </CurrentStep>
        {step < progressSteps.length - 1 ? (
          <NextStep>
            <FormattedMessage id="message.progressBar.steps.next" />
            <FormattedMessage
              id={`message.progressBar.steps.${progressSteps[step + 1]}`}
            />
          </NextStep>
        ) : null}
      </FlexContainer>
      <FlexContainer
        width="100%"
        justifyContent="space-between"
        padding="0 2px"
      >
        {progressSteps.map((_, i) => (
          <ProgressBarFragment
            key={i}
            index={i as Step}
            currentStep={step as Step}
            style={{ width: Math.floor(100 / progressSteps.length) + '%' }}
          />
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

export default ProgressBar;
