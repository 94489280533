import styled, { css } from 'styled-components';
import Button from '../Button';
import Icon from '../Icon';

export const HelpPopupSectionStyled = styled.div`
  bottom: calc(0.3rem + 3vw);
  position: fixed;
  right: calc(0.3rem + 3vw);
  z-index: 100;

  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }

    20% {
      transform: translateY(0);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes showAndHide {
    0%,
    100% {
      opacity: 0;
      transform: translateY(50%);
    }

    10% {
      transform: translateY(0);
    }

    15% {
      opacity: 1;
    }

    85% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const HelpPopupWrapperStyled = styled.div`
  position: relative;
`;

export const HelpPopupButtonStyled = styled(Button)`
  animation-delay: 3s;
  animation-duration: 1s;
  animation-fill-mode: backwards;
  animation-iteration-count: 1;
  animation-name: appear;
  background-color: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.15);
  min-height: calc(3rem + 1vw);
  min-width: calc(3rem + 1vw);
  padding: 0;

  @keyframes pulse {
    0%,
    10%,
    100% {
      opacity: 0.8;
      transform: scale(1);
    }

    5% {
      opacity: 1;
      transform: scale(1.25);
    }
  }

  svg {
    animation-delay: 15s;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-name: pulse;
    opacity: 0.8;
  }

  &:hover svg,
  &:focus svg {
    animation-play-state: paused;
  }
`;

export const HelpPopupLinkIconStyled = styled(Icon)`
  margin-left: 0.5em;
  vertical-align: text-bottom;
  width: 0.75em;
`;

export const HelpPopupCloseButtonStyled = styled(Button)`
  background-color: ${({ theme }) => theme.baseColors.white};
  border-radius: 2rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.baseColors.base50};
  min-height: 2.5rem;
  padding: 0;
  position: absolute;
  right: calc(-0.5rem - 0.5vw);
  top: calc(-0.5rem - 0.5vw);
  width: 2.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.baseColors.white};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const HelpPopupCloseIconStyled = styled(Icon)`
  display: flex;
  place-items: center;
`;

const helpPopupSharedStyles = css`
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.69, 0.39, 0.44, 0.76);
  background-color: ${({ theme }) => theme.baseColors.white};
  border-radius: ${({ theme }) => theme.cardBorderRadius};
  bottom: calc(4.5rem + 1vw);
  box-shadow: 0 0.25rem 1.5rem 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0;

  &::before {
    border-bottom: 0.75rem solid transparent;
    border-left: 1rem solid transparent;
    border-right: 1rem solid ${({ theme }) => theme.baseColors.white};
    border-top: 0.75rem solid ${({ theme }) => theme.baseColors.white};
    bottom: -1rem;
    content: '';
    height: 0;
    position: absolute;
    right: 2rem;
    width: 0;
  }
`;

export const HelpPopupTeaserStyled = styled.div`
  animation-delay: 3.5s;
  animation-duration: 3s;
  animation-name: showAndHide;
  opacity: 0;
  padding: 1.5rem;
  pointer-events: none;
  white-space: nowrap;
  ${helpPopupSharedStyles}
`;

export const HelpPopupStyled = styled.div`
  animation-duration: 1s;
  animation-name: appear;
  max-width: 25rem;
  min-width: 18rem;
  padding: 0.75rem 2rem 2rem 2rem;
  width: 20vw;

  p {
    line-height: 1.4;
    margin: 0.75rem 0 0 0;
  }
  ${helpPopupSharedStyles}
`;

export const HelpPopupLink = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  > span {
    white-space: nowrap;
  }
`;

export const HelpPopupPhone = styled.a`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin: 0.75rem 0 0 0;
`;
