import { RefObject, useEffect } from 'react';

const useFocusingRef = <T extends HTMLElement>(
  ref: RefObject<T>,
  isFocused: boolean | undefined,
): void => {
  useEffect(() => {
    if (isFocused && ref && ref.current) {
      ref.current.focus();
    }
  }, [isFocused, ref]);
};

export default useFocusingRef;
