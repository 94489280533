import { ActionState } from 'redux/types';

export interface ILocalstorageInitialState {
  state: ActionState;
  errorMessage: string;
}

const localStorageInitialState: ILocalstorageInitialState = {
  state: ActionState.IDLE,
  errorMessage: '',
};

export default localStorageInitialState;
