import { useDispatch } from 'react-redux';
import { identInit } from 'redux/actions/identificationActions';
import { navigateToBankIdentIban } from 'redux/actions/navigationActions';
import { IdentificationMethod } from 'redux/types';

const usePhoneVerificationSuccess = () => {
  const dispatch = useDispatch();

  const handleNavigateToBankIdent = () => {
    dispatch(navigateToBankIdentIban());
  };

  const handleCreateVideoIdent = () => {
    dispatch(identInit(IdentificationMethod.IDNOW, ''));
  };

  return {
    handleNavigateToBankIdent,
    handleCreateVideoIdent,
  };
};

export default usePhoneVerificationSuccess;
