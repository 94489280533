import * as React from 'react';

const SvgCbSpinner = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className="cb-spinner_svg__Circle-sc-44hwb2-0 cb-spinner_svg__ezIlLb"
    {...props}
  >
    <circle
      r={20}
      cx={24}
      cy={24}
      strokeWidth={3}
      fill="rgba(0,0,0,0)"
      stroke="#F4F7FD"
    />
    <circle
      id="bar"
      r={20}
      cx={24}
      cy={24}
      strokeWidth={3}
      fill="rgba(0,0,0,0)"
      stroke="#1652f0"
      strokeDasharray="20 90"
    />
  </svg>
);

export default SvgCbSpinner;
