const headers = (token: string | null) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['X-Solaris-Session-Token'] = token;
  }
  return headers;
};

export const fetchFile = async (url: string, token: string | null = null) => {
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/pdf',
      Accept: '*/*',
      'X-Solaris-Session-Token': `${token}`,
    },
  });
  const fileBlob = await response.blob();
  return fileBlob;
};

export const fetchData = async (url: string, token: string | null = null) => {
  const response = await fetch(url, {
    method: 'GET',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: new Headers(headers(token)),
  });

  return response.json();
};

export const postData = async (
  url: string,
  data: any,
  token: string | null = null,
) => {
  const response = await fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: new Headers(headers(token)),
    body: JSON.stringify(data),
  });
  return response.json();
};

export const patchData = async (
  url: string,
  data: any,
  token: string | null = null,
) => {
  const response = await fetch(url, {
    method: 'PATCH',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: new Headers(headers(token)),
    body: data ? JSON.stringify(data) : null,
  });
  return response.json();
};
