import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/Icon';
import { IMobileNumberInitialState } from 'redux/initialState/mobileNumberInitialState';
import { defaultTheme } from 'theme';
import { StepsList as StepsListStyling } from './StepsList.styled';

interface IStepsList {
  bankIdentAvailable: boolean;
  mobileNumber: IMobileNumberInitialState;
}

const StepsList: React.FC<IStepsList> = ({ mobileNumber }) => {
  const { verified, number } = mobileNumber;

  const step1 = verified ? (
    <li className="po-step-phone--done">
      <Icon
        name="Check"
        color={defaultTheme.baseColors.success}
        left="-1.2rem"
        top="0.6rem"
        width="1rem"
        height="1rem"
        position="absolute"
      />
      <FormattedMessage
        id="message.startBankIdentification.step1Done"
        values={{ number }}
      />
    </li>
  ) : (
    <li>
      <FormattedMessage id="message.startBankIdentification.step1" />
    </li>
  );

  const step2 = (
    <li>
      <FormattedMessage id="message.startBankIdentification.bank.step2" />
    </li>
  );

  const step3 = (
    <li>
      <FormattedMessage id="message.startBankIdentification.bank.step3" />
    </li>
  );

  return (
    <Fragment>
      <StepsListStyling>
        {step1}
        {step2}
        {step3}
      </StepsListStyling>
    </Fragment>
  );
};

export default StepsList;
