import { formatToComponentName } from '../../utils/formatters';
import * as PartnerLogos from './LogoComponents';

export enum PartnerLogoComponentNames {
  BinanceLogo,
  BitpandaLogo,
  CoinbaseLogo,
}

export const PartnerLogo: React.FC<{ partnerName?: string | null }> = ({
  partnerName,
}) => {
  const partnerLogoComponentName = `${formatToComponentName(partnerName)}Logo`;
  if (
    Object.values(PartnerLogoComponentNames).includes(partnerLogoComponentName)
  ) {
    const LogoComponent = PartnerLogos[partnerLogoComponentName];
    return <LogoComponent />;
  } else {
    return null;
  }
};
