import * as React from 'react';

const SvgShare = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.467 9.056v6.037c0 .4.182.784.507 1.067.326.283.766.442 1.226.442h10.4c.46 0 .9-.159 1.226-.442.325-.283.508-.667.508-1.067V9.056M13.866 4.528L10.4 1.509 6.933 4.528M10.4 1.51v9.81"
      stroke="#0F1926"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgShare;
