export const LOCAL_STORAGE_SET_INIT = 'LOCAL_STORAGE_SET_INIT';
export const LOCAL_STORAGE_SET_SUCCESS = 'LOCAL_STORAGE_SET_SUCCESS';
export const LOCAL_STORAGE_SET_ERROR = 'LOCAL_STORAGE_SET_ERROR';

export const LOCAL_STORAGE_GET_INIT = 'LOCAL_STORAGE_GET_INIT';
export const LOCAL_STORAGE_GET_SUCCESS = 'LOCAL_STORAGE_GET_SUCCESS';
export const LOCAL_STORAGE_GET_ERROR = 'LOCAL_STORAGE_GET_ERROR';

export const LOCAL_STORAGE_RESET = 'LOCAL_STORAGE_RESET';

const localStorageActionTypes = [
  LOCAL_STORAGE_SET_INIT,
  LOCAL_STORAGE_SET_SUCCESS,
  LOCAL_STORAGE_SET_ERROR,
  LOCAL_STORAGE_GET_INIT,
  LOCAL_STORAGE_GET_SUCCESS,
  LOCAL_STORAGE_GET_ERROR,
  LOCAL_STORAGE_RESET,
];

export interface ILocalStorageAction {
  type: typeof localStorageActionTypes[number];
  data: ILocalStorageActionData;
}

export interface ILocalStorageActionData {
  id?: string;
  message?: string;
}

export const localStorageSetInit = (data: ILocalStorageActionData) => ({
  type: LOCAL_STORAGE_SET_INIT,
  data,
});

export const localStorageSetSuccess = () => ({
  type: LOCAL_STORAGE_SET_SUCCESS,
});

export const localStorageSetError = (data = { message: 'Error' }) => ({
  type: LOCAL_STORAGE_SET_ERROR,
  data,
});

export const localStorageGetInit = () => ({
  type: LOCAL_STORAGE_GET_INIT,
});

export const localStorageGetSuccess = () => ({
  type: LOCAL_STORAGE_GET_SUCCESS,
});

export const localStorageGetError = (data = { message: 'Error' }) => ({
  type: LOCAL_STORAGE_GET_ERROR,
  data,
});

export const localStorageReset = () => ({
  type: LOCAL_STORAGE_RESET,
});
