import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'components/App';
import AppIntlProvider from 'components/App/AppIntlProvider/AppIntlProvider';
import setupAndCreateStore from 'redux/setupAndCreateStore';
import { initiateSentry } from './utils/errorReporter';
import './index.scss';

const store = setupAndCreateStore();
initiateSentry();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppIntlProvider>
        <App />
      </AppIntlProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
