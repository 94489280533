import { ActionState } from 'redux/types';

export interface IIdentificationMethodsInitialState {
  state: ActionState;
  errorMessage: string;
  bank: boolean;
  video: boolean;
  fourthline: boolean;
  loading: boolean;
}

const identificationMethodsInitialState: IIdentificationMethodsInitialState = {
  state: ActionState.IDLE,
  errorMessage: '',
  bank: false,
  video: false,
  fourthline: false,
  loading: false,
};

export default identificationMethodsInitialState;
