export const SESSION_INIT = 'SESSION_INIT';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_ERROR = 'SESSION_ERROR';

export interface ISessionAction {
  type: typeof SESSION_SUCCESS | typeof SESSION_ERROR;
  data?: {
    callbackUrl?: string;
    status?: string;
    message?: string;
    language?: string;
  };
}

export const sessionInit = () => ({
  type: SESSION_INIT,
});

export const sessionSuccess = (data: {
  callbackUrl: string;
  language: string;
  style?: {};
  custom_content?: {};
  partner_name?: string | null;
  skipUrl?: string | null;
}) => ({
  type: SESSION_SUCCESS,
  data,
});

export const sessionError = (data: { message: string; language: string }) => ({
  type: SESSION_ERROR,
  data,
});
