import {
  IDENT_AUTHORIZATION_ERROR,
  IDENT_AUTHORIZATION_INIT,
  IDENT_AUTHORIZATION_SUCCESS,
  IDENT_CANCEL,
  IDENT_ERROR,
  IDENT_FAILED,
  IDENT_GET_ERROR,
  IDENT_GET_INIT,
  IDENT_GET_SUCCESS,
  IDENT_INIT,
  IDENT_POLL_ERROR,
  IDENT_POLL_INIT,
  IDENT_POLL_SUCCESS,
  IDENT_REQUEST_ERROR,
  IDENT_REQUEST_INIT,
  IDENT_REQUEST_SUCCESS,
  IDENT_RESET,
  IDENT_SUCCESS,
  IDENT_TAN_ACCEPTANCE_ERROR,
  IDENT_TAN_ACCEPTANCE_INIT,
  IDENT_TAN_ACCEPTANCE_SUCCESS,
  IIdentificationAction,
} from 'redux/actions/identificationActions';
import identificationInitialState, {
  IIdentificationInitialState,
} from 'redux/initialState/identificationInitialState';
import { ActionState, PollingState } from 'redux/types';

export const identificationReducer = (
  state = identificationInitialState,
  action: IIdentificationAction,
): IIdentificationInitialState => {
  switch (action.type) {
    case IDENT_INIT:
      return {
        ...state,
        state: ActionState.IN_PROGRESS,
        loading: true,
        method: action.data!.method!,
        errorMessage: identificationInitialState.errorMessage,
      };
    case IDENT_GET_INIT:
    case IDENT_REQUEST_INIT:
    case IDENT_TAN_ACCEPTANCE_INIT:
      return {
        ...state,
        state: ActionState.IN_PROGRESS,
        loading: true,
        errorMessage: identificationInitialState.errorMessage,
      };
    case IDENT_AUTHORIZATION_INIT:
      return {
        ...state,
        state: ActionState.IN_PROGRESS,
        loading: true,
      };
    case IDENT_GET_SUCCESS:
    case IDENT_SUCCESS:
      return {
        ...state,
        id: action.data!.id!,
        url: action.data!.url!,
        new_idnow_url: action.data!.new_idnow_url,
        status: action.data!.status!,
        iban: action.data!.iban!,
        method: action.data!.method!,
        paymentInitiationProvider: action.data!.paymentInitiationProvider!,
        documents: action.data!.documents!,
        reference: action.data!.reference!,
        errorMessage: action.data!.errorMessage!,
        loading: false,
        state: ActionState.SUCCESS,
      };
    case IDENT_REQUEST_SUCCESS:
      return {
        ...state,
        url: action.data!.url!,
        status: action.data!.status!,
        loading: false,
        state: ActionState.SUCCESS,
      };

    case IDENT_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        status: action.data!.status!,
        reference: action.data!.reference!,
        loading: false,
        state: ActionState.SUCCESS,
      };
    case IDENT_TAN_ACCEPTANCE_SUCCESS:
      return {
        ...state,
        status: action.data!.status!,
        errorMessage: identificationInitialState.errorMessage,
        loading: false,
        state: ActionState.SUCCESS,
      };
    case IDENT_POLL_INIT:
      return {
        ...state,
        polling: PollingState.POLLING,
      };
    case IDENT_POLL_SUCCESS:
      return {
        ...state,
        polling: PollingState.IDLE,
        status: action.data!.status!,
        documents: action.data!.documents!,
        loading: false,
      };
    case IDENT_POLL_ERROR:
      return {
        ...state,
        polling: PollingState.IDLE,
        errorMessage: action.data!.errorMessage!,
        loading: false,
        state: ActionState.ERROR,
      };
    case IDENT_ERROR:
    case IDENT_REQUEST_ERROR:
    case IDENT_AUTHORIZATION_ERROR:
    case IDENT_TAN_ACCEPTANCE_ERROR:
    case IDENT_GET_ERROR:
      return {
        ...state,
        errorMessage: action.data!.errorMessage!,
        loading: false,
        state: ActionState.ERROR,
      };

    case IDENT_FAILED:
      return {
        ...identificationInitialState,
        errorMessage: action.data!.errorMessage!,
        loading: false,
        state: ActionState.ERROR,
      };
    case IDENT_CANCEL:
      return {
        ...identificationInitialState,
        method: state.method,
        polling: PollingState.IDLE,
        loading: false,
        state: ActionState.CANCELLED,
      };
    case IDENT_RESET:
      return identificationInitialState;
    default:
      return state;
  }
};
