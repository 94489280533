import Icon from 'components/Icon';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { MessageType } from './types';

export const StyledMessage = styled.div<{ type: MessageType }>`
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  ${({ type }) => {
    switch (type) {
      case 'error':
        return css`
          background-color: ${({ theme }) => theme.baseColors.errorTint};
        `;
      default:
        return css`
          background-color: ${({ theme }) => theme.baseColors.base05};
        `;
    }
  }}

  ${space}
`;

export const StyledMessageText = styled.p`
  margin: -2px 0 0 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.baseColors.base100};
  white-space: pre-wrap;
`;

export const StyledMessageIcon = styled(Icon)`
  margin-right: 0.75rem;
`;
