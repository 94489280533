import React from 'react';
import { IconBaseWrapper } from './Icon.styled';
import * as Icons from './Icons';
import IIcon from './types';

const Icon: React.FC<IIcon> = ({
  name,
  color,
  left,
  top,
  position,
  size = '1.25rem',
  ...props
}) => {
  const IconComponent = Icons[name];
  return (
    <IconBaseWrapper
      color={color}
      position={position}
      left={left}
      top={top}
      size={size}
      {...props}
    >
      <IconComponent />
    </IconBaseWrapper>
  );
};

export default Icon;
