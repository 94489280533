import React from 'react';
import { FooterBranding } from 'components/FooterBranding/FooterBranding';
import { HeaderBranding } from 'components/HeaderBranding/HeaderBranding';
import { StyledContent, StyledLayout } from './Layout.styled';

const Layout: React.FC = ({ children }) => (
  <StyledLayout>
    <HeaderBranding />
    <StyledContent>{children}</StyledContent>
    <FooterBranding />
  </StyledLayout>
);

export default Layout;
