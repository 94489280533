import React from 'react';
import { StyledHeader } from './Header.styled';
import IHeader from './types';

const Header: React.FC<IHeader> = ({ children, type = 'info', ...props }) => (
  <StyledHeader type={type} {...props}>
    {children}
  </StyledHeader>
);

export default Header;
