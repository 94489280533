import {
  CONFIG_ERROR,
  CONFIG_SUCCESS,
  IConfigAction,
} from 'redux/actions/configActions';
import configInitialState, {
  IConfigInitialState,
} from 'redux/initialState/configInitialState';
import { ActionState } from 'redux/types';

export const configReducer = (
  state = configInitialState,
  action: IConfigAction,
): IConfigInitialState => {
  switch (action.type) {
    case CONFIG_SUCCESS:
      return {
        ...state,
        ...action.data!,
        state: ActionState.SUCCESS,
      };
    case CONFIG_ERROR:
      return {
        ...state,
        state: ActionState.ERROR,
        errorMessage: action.data!.message!,
      };
    default:
      return state;
  }
};

export default configReducer;
