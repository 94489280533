export const stripWhiteSpace = (value?: string): string =>
  value ? value.replace(/\s/g, '') : '';

export function formatIban(iban?: string): string {
  if (!iban) {
    return '';
  }
  const cleanIban = iban
    .replace(/\s\s+/g, ' ')
    .replace(/[^0-9a-zA-Z]/gi, '')
    .toUpperCase();
  const parts = cleanIban.match(/.{1,4}/g);
  return (parts && parts.join(' ')) || cleanIban;
}

export function snakeToCamelCase(inputString: string): string {
  const words = inputString.split('_');
  if (words.length > 0) {
    return (
      words.shift()?.toLocaleLowerCase() +
      words
        .map(
          (word) =>
            word.charAt(0).toUpperCase() +
            word.substring(1).toLocaleLowerCase(),
        )
        .join('')
    );
  } else {
    return inputString.toLocaleLowerCase();
  }
}

export const truncatePhoneNumber = (phone: string): string =>
  phone ? phone.substr(0, 3) + '****' + phone.substr(7) : '';

export const formatToComponentName = (value?: string | null): string | null => {
  if (!value) {
    return null;
  }
  // Make everything lowercase and remove all but alphanumeric and numeric characters
  const cleanedUpValue = value?.toLowerCase().replace(/[^a-z0-9]/gi, '');
  // Make first character uppercase
  return cleanedUpValue?.charAt(0).toUpperCase() + cleanedUpValue?.slice(1);
};
