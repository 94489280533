import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { IInitialState } from 'redux/initialState/initialState';
import { loadLocaleData } from 'utils/locales';

const mapStateToProps = (state: IInitialState) => ({
  locale: state.session.language,
  messages: loadLocaleData(state.session.language),
});

export default connect(mapStateToProps)(IntlProvider);
