import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSessionSelector } from 'redux/selectors';
import { PartnerLogo } from '../PartnerLogo';
import { StyledHeaderBranding } from './HeaderBranding.styled';
import { StyledHeaderLogo } from './HeaderLogo.styled';

export const HeaderBranding: React.FC = () => {
  const { partner_name } = useSessionSelector();

  return (
    <StyledHeaderBranding>
      <StyledHeaderLogo>
        <PartnerLogo partnerName={partner_name} />
      </StyledHeaderLogo>
      <h1>
        <FormattedMessage id="message.globalTitle" />
      </h1>
    </StyledHeaderBranding>
  );
};
