import { useSelector } from 'react-redux';
import { IInitialState } from './initialState/initialState';

export const mobileNumberSelector = (state: IInitialState) =>
  state.mobileNumber;
export const identificationFileSelector = (state: IInitialState) =>
  state.identificationFile;
export const configSelector = (state: IInitialState) => state.config;
export const sessionSelector = (state: IInitialState) => state.session;
export const navigationSelector = (state: IInitialState) => state.navigation;
export const localStorageSelector = (state: IInitialState) =>
  state.localStorage;

export const recoverIdentSelector = (state: IInitialState) =>
  state.recoverIdentification;
export const identificationSelector = (state: IInitialState) =>
  state.identification;
export const identificiationMethodsSelector = (state: IInitialState) =>
  state.identificationMethods;

const useMobileNumberSelector = () => useSelector(mobileNumberSelector);
const useFileDownloadSelector = () => useSelector(identificationFileSelector);
const useConfigSelector = () => useSelector(configSelector);
const useSessionSelector = () => useSelector(sessionSelector);
const useNavigationSelector = () => useSelector(navigationSelector);
const useLocalStorageSelector = () => useSelector(localStorageSelector);

const useRecoverIdentSelector = () => useSelector(recoverIdentSelector);
const useIdentificationSelector = () => useSelector(identificationSelector);
const useIdentificationMethodsSelector = () =>
  useSelector(identificiationMethodsSelector);

export {
  useMobileNumberSelector,
  useFileDownloadSelector,
  useConfigSelector,
  useSessionSelector,
  useNavigationSelector,
  useLocalStorageSelector,
  useRecoverIdentSelector,
  useIdentificationSelector,
  useIdentificationMethodsSelector,
};
