import { useDispatch } from 'react-redux';
import { mobileNumberAuthorizeInit } from 'redux/actions/mobileNumberActions';
import {
  navigateToBankIdentIban,
  navigateToUniversalStart,
  navigateToVideoIdentStart,
} from 'redux/actions/navigationActions';
import { sessionInit } from 'redux/actions/sessionActions';
import {
  useConfigSelector,
  useIdentificationMethodsSelector,
  useIdentificationSelector,
  useMobileNumberSelector,
  useSessionSelector,
} from 'redux/selectors';
import { ActionState } from 'redux/types';
import postMessage from 'utils/postMessage';

const useStartBankIdentification = () => {
  const dispatch = useDispatch();
  const config = useConfigSelector();
  const mobileNumber = useMobileNumberSelector();
  const session = useSessionSelector();
  const identificationMethods = useIdentificationMethodsSelector();
  const identification = useIdentificationSelector();
  const handleAuthorizeMobileNumber = () => {
    dispatch(mobileNumberAuthorizeInit());
  };

  const handleNavigateToVideoIdent = () => {
    dispatch(navigateToVideoIdentStart());
  };
  const bankIdentAvailable = identificationMethods.bank;
  const videoIdentAvailable = identificationMethods.video;

  const handleCallbackClick = () => {
    if (videoIdentAvailable) {
      dispatch(navigateToUniversalStart());
    } else {
      postMessage('cancel');

      const { callbackUrl } = session;
      window.location.assign(callbackUrl);
    }
  };

  const callbackButtonText = () => {
    return videoIdentAvailable
      ? 'message.button.back'
      : 'message.button.cancel';
  };

  const handleNavigateToBankIdentIban = () => {
    dispatch(navigateToBankIdentIban());
  };

  const stateRequiresSessionInfo =
    config.state === ActionState.SUCCESS && session.state === ActionState.IDLE;

  if (stateRequiresSessionInfo) {
    dispatch(sessionInit());
  }

  return {
    handleAuthorizeMobileNumber,
    handleCallbackClick,
    callbackButtonText,
    errorMessages: [
      config.errorMessage,
      mobileNumber.errorMessage,
      session.errorMessage,
    ],
    isMobile: config.isMobile,
    mobileNumber,
    identification,
    bankIdentAvailable,
    handleNavigateToVideoIdent,
    handleNavigateToBankIdentIban,
  };
};

export default useStartBankIdentification;
