import {
  ISessionAction,
  SESSION_ERROR,
  SESSION_SUCCESS,
} from 'redux/actions/sessionActions';
import sessionInitialState, {
  ISessionInitialState,
} from 'redux/initialState/sessionInitialState';
import { ActionState } from 'redux/types';

export const sessionReducer = (
  state = sessionInitialState,
  action: ISessionAction,
): ISessionInitialState => {
  switch (action.type) {
    case SESSION_SUCCESS:
      return {
        ...state,
        ...action.data!,
        state: ActionState.SUCCESS,
      };
    case SESSION_ERROR:
      return {
        ...state,
        state: ActionState.ERROR,
        language: action.data!.language!,
        errorMessage: action.data!.message!,
      };
    default:
      return state;
  }
};

export default sessionReducer;
