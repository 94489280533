import { PaymentInitiationFailureStatus } from 'redux/types';

export const PAYMENT_INIT_NOTIFY_INIT = 'PAYMENT_INIT_NOTIFY_INIT';
export const PAYMENT_INIT_NOTIFY_SUCCESS = 'PAYMENT_INIT_NOTIFY_SUCCESS';
export const PAYMENT_INIT_NOTIFY_ERROR = 'PAYMENT_INIT_NOTIFY_ERROR';

export interface IPaymentInitiationCreateAction {
  type: typeof PAYMENT_INIT_NOTIFY_INIT;
  data: {
    sessionKey: string;
    status: PaymentInitiationFailureStatus;
    errorCode: string;
  };
}

export const paymentInitNotifyInit = (
  sessionKey: string,
  status: PaymentInitiationFailureStatus,
  errorCode: string,
) => ({
  type: PAYMENT_INIT_NOTIFY_INIT,
  data: {
    sessionKey,
    status,
    errorCode,
  },
});

export const paymentInitNotifySuccess = () => ({
  type: PAYMENT_INIT_NOTIFY_SUCCESS,
});

export const paymentInitNotifyError = () => ({
  type: PAYMENT_INIT_NOTIFY_ERROR,
});
