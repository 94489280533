import React from 'react';
import { IconName } from 'components/Icon/Icons';
import { LayoutProps, SpaceProps } from 'styled-system';

export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
}

export enum ButtonSizes {
  SMALL = 'small',
  REGULAR = 'regular',
}

interface IButton
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    SpaceProps,
    LayoutProps {
  variant?: ButtonVariants;
  buttonSize?: ButtonSizes;
  leadingIcon?: IconName;
  trailingIcon?: IconName;
  centeredIcon?: IconName;
  loading?: boolean;
}

export default IButton;
