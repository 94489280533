import React from 'react';
import {
  StyledMessage,
  StyledMessageIcon,
  StyledMessageText,
} from './Message.styled';
import IMessage from './types';

const Message: React.FC<IMessage> = ({
  children,
  leadingIcon,
  type = 'info',
  ...props
}) => (
  <StyledMessage type={type} {...props}>
    {leadingIcon && <StyledMessageIcon name={leadingIcon} />}
    <StyledMessageText>{children}</StyledMessageText>
  </StyledMessage>
);

export default Message;
