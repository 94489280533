import Button from 'components/Button';
import styled from 'styled-components';

export const StyledCtaSection = styled('section')`
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  max-width: 38rem;
`;

export const CtaButton = styled(Button)`
  align-items: start;
  display: grid;
  grid-template-columns: calc(1rem + 10%) 1fr;
  justify-content: start;
  margin-bottom: 1rem;
  padding: 1.25rem 1rem 1.5rem 1.5rem;
  text-align: left;
  width: 100%;

  h3 {
    margin: 0 0 0.5em 0;
  }

  p {
    line-height: 1.2;
    margin: 0;
  }
`;
