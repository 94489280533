import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  identCancel,
  identFailed,
  identInit,
  identPollInit,
  identReset,
} from 'redux/actions/identificationActions';
import { navigateToVideoIdentStart } from 'redux/actions/navigationActions';
import { paymentInitNotifyInit } from 'redux/actions/paymentInitiationActions';
import { IIdentificationInitialState } from 'redux/initialState/identificationInitialState';
import {
  useConfigSelector,
  useIdentificationMethodsSelector,
  useIdentificationSelector,
} from 'redux/selectors';
import {
  ActionState,
  IdentificationMethod,
  IdentificationStatus,
  PaymentInitiationFailureStatus,
  PaymentInitiationProviderTypes,
  PollingState,
} from 'redux/types';
import { snakeToCamelCase } from 'utils/formatters';

const useBankIdent = () => {
  const dispatch = useDispatch();
  const identification = useIdentificationSelector();
  const { isMobile } = useConfigSelector();
  const identificationMethods = useIdentificationMethodsSelector();

  const reset = () => {
    dispatch(identReset());
  };

  const handleCancel = () => {
    dispatch(identCancel());
  };

  useEffect(() => {
    if (identification.state === ActionState.ERROR) {
      return;
    }

    const handleFtsFailure = (
      identification: IIdentificationInitialState,
      errorCode: string,
    ) => {
      const { url } = identification;
      const sessionKey = new URL(url).searchParams.get('wizard_session_key');
      if (sessionKey) {
        dispatch(
          paymentInitNotifyInit(
            sessionKey,
            PaymentInitiationFailureStatus.FAILURE,
            errorCode,
          ),
        );
      }
    };

    const handleFlcFailure = (errorCode: string) => {
      dispatch(
        identFailed({
          errorMessage: snakeToCamelCase(errorCode),
        }),
      );
    };

    const handleEventMessage = (message: MessageEvent) => {
      const { paymentInitiationProvider } = identification;
      const ftsIsPaymentProvider =
        paymentInitiationProvider === PaymentInitiationProviderTypes.FTS;

      if (message?.data?.query?.state) {
        const { state } = message.data.query;
        if (state === 'success') {
          dispatch(identPollInit());
        }
        if (state === 'error') {
          const errorCode = message?.data?.query?.code || 'TECH_ERROR';

          ftsIsPaymentProvider
            ? handleFtsFailure(identification, errorCode)
            : handleFlcFailure(errorCode);
        }
      }
    };

    window.addEventListener('message', handleEventMessage);
    return () => window.removeEventListener('message', handleEventMessage);
  }, [identification, dispatch]);

  const hasNoIdentificationMethod = () =>
    identification.method === IdentificationMethod.NONE;
  const isBankIdentification = () =>
    identification.method === IdentificationMethod.BANK;
  const isIdnowIdentification = () =>
    identification.method === IdentificationMethod.IDNOW;
  const hasIdentificationMethodBank = identificationMethods.bank;
  const identificationPollingInProgress = () =>
    identification.polling === PollingState.POLLING;
  const identificationMethod = () => identification.method;

  const identificationIsPending = () =>
    identification.status === IdentificationStatus.PENDING &&
    identification.url;
  const identificationIsAuthorizationRequired = () =>
    identification.status === IdentificationStatus.AUTHORIZATION_REQUIRED;
  const identificationIsSuccessful = () =>
    identification.status === IdentificationStatus.SUCCESSFUL;
  const identificationInErrorState = () =>
    identification.state === ActionState.ERROR;

  const identificationInFailedState = () =>
    identification.status === IdentificationStatus.FAILED;

  const identificationInCancelledState = () =>
    identification.state === ActionState.CANCELLED;

  const bankIdentAvailable = identificationMethods.bank;
  const identificationUrl = identification.url;
  const identificationReference = identification.reference;
  const errorMessage = identification.errorMessage;
  const isLoading = identification.loading;

  useEffect(() => {
    if (
      isIdnowIdentification() &&
      identificationIsPending() &&
      !identificationPollingInProgress()
    ) {
      dispatch(identPollInit());
    }
  });

  const handleNavigateToVideoIdent = () => {
    dispatch(navigateToVideoIdentStart());
  };

  return {
    startBankIdent: (iban: string) =>
      dispatch(identInit(IdentificationMethod.BANK, iban)),
    identCancel: handleCancel,
    identificationPollingInProgress: identificationPollingInProgress(),
    identificationIsPending: identificationIsPending(),
    identificationIsAuthorizationRequired:
      identificationIsAuthorizationRequired(),
    identificationIsSuccessful: identificationIsSuccessful(),
    identificationIsCancelled: identificationInCancelledState(),
    identificationInErrorState: identificationInErrorState(),
    identificationInFailedState: identificationInFailedState(),
    isBankIdentification: isBankIdentification(),
    isIdnowIdentification: isIdnowIdentification(),
    hasNoIdentificationMethod: hasNoIdentificationMethod(),
    hasIdentificationMethodBank,
    identificationMethod: identificationMethod(),
    identificationUrl,
    identificationReference,
    bankIdentAvailable,
    isLoading,
    isMobile,
    errorMessage,
    handleNavigateToVideoIdent,
    reset,
  };
};

export default useBankIdent;
