import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import FlexContainer from 'components/FlexContainer';
import Message from 'components/Message';
import TanInput, { handleOnPaste } from 'components/TanInput';
import SignDocumentTransactionIdHint from 'sections/SignDocumentTransactionIdHint';
import { truncatePhoneNumber } from 'utils/formatters';
import useBankIdentificationSignDocumentsEnterTan from 'utils/hooks/useBankIdentificationSignDocumentsEnterTan';
import validateTan from 'utils/validation/validateTan';
import Paragraph from '../../components/Paragraph';
import TanRetry from '../../sections/TanRetry';
import {
  SignDocumentsEnterTanForm,
  TanInputWrapper,
  TanValidationError,
} from './BankIdentificationSignDocumentsEnterTan.styled';

interface ISignDocumentsEnterTan {
  loading?: boolean;
  reference?: string;
}

const SignDocumentsEnterTan: React.FC<ISignDocumentsEnterTan> = ({
  loading,
  reference,
}) => {
  const {
    errorMessage,
    number,
    onSubmit,
    onRetry,
    handleSubmit,
    reset,
    control,
    formErrors,
  } = useBankIdentificationSignDocumentsEnterTan();
  return (
    <Fragment>
      <h2>
        <FormattedMessage id="message.signDocuments.enterTan.title" />
      </h2>
      <Paragraph>
        <FormattedMessage id="message.signDocuments.enterTan.description" />
        <strong>{truncatePhoneNumber(number)}</strong>.
      </Paragraph>
      <SignDocumentsEnterTanForm
        onSubmit={handleSubmit(onSubmit)}
        onPaste={(e: React.ClipboardEvent) => handleOnPaste(e, reset)}
      >
        <TanInputWrapper>
          <Controller
            name="tan"
            control={control}
            defaultValue={['', '', '', '', '', '']}
            rules={{
              validate: validateTan,
            }}
            render={({ onChange, value }) => (
              <TanInput
                onChange={(v: string[]) => onChange(v)}
                value={value}
                error={!!formErrors?.tan?.message}
              />
            )}
          />
        </TanInputWrapper>

        <TanRetry retryAction={onRetry}></TanRetry>

        {formErrors?.tan?.message && (
          <TanValidationError>
            <FormattedMessage id={formErrors.tan.message} />
          </TanValidationError>
        )}
        {errorMessage && (
          <Message type="error" marginTop="2rem">
            <FormattedMessage id={`error.${errorMessage}`} />
          </Message>
        )}
        {reference && <SignDocumentTransactionIdHint reference={reference} />}
        <FlexContainer
          width="100%"
          justifyContent="flex-end"
          marginTop="1.5rem"
        >
          <Button type="submit" loading={loading} data-test-id="submitTan">
            <FormattedMessage id="message.button.confirm" />
          </Button>
        </FlexContainer>
      </SignDocumentsEnterTanForm>
    </Fragment>
  );
};

export default SignDocumentsEnterTan;
