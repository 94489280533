import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import { ButtonSizes, ButtonVariants } from 'components/Button/types';
import CountdownTimer from 'components/CountdownTimer';

interface ITanRetry {
  retryAction: () => void;
}

const TanRetry: React.FC<ITanRetry> = ({ retryAction }) => {
  const [timerRunning, setTimerRunning] = useState(false);

  const retryActionWithTimerStart = () => {
    setTimerRunning(true);
    retryAction();
  };

  const afterTimerEndAction = () => {
    setTimerRunning(false);
  };

  useEffect(() => {
    setTimerRunning(true);
  }, []);

  return (
    <React.Fragment>
      {timerRunning ? (
        <CountdownTimer afterTimeEndAction={afterTimerEndAction}>
          <FormattedMessage id="message.tanRetry.requestCodeIn" />
        </CountdownTimer>
      ) : (
        <Button
          variant={ButtonVariants.LINK}
          buttonSize={ButtonSizes.SMALL}
          onClick={(e) => {
            e.preventDefault();
            retryActionWithTimerStart();
          }}
        >
          <FormattedMessage id="message.tanRetry.buttonSubmit" />
        </Button>
      )}
    </React.Fragment>
  );
};

export default TanRetry;
