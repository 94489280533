import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Message from 'components/Message';

interface IErrors {
  errorMessages: string[];
  marginTop?: string;
}

const Errors: React.FC<IErrors> = ({ errorMessages, marginTop = '0' }) => {
  errorMessages = Array.from(new Set(errorMessages)); // Making the error array unique

  return (
    <Fragment>
      {errorMessages.map((em) =>
        em ? (
          <Message
            type="error"
            marginBottom="1rem"
            marginTop={marginTop}
            key={em}
          >
            <FormattedMessage id={`error.${em}`} />
          </Message>
        ) : null,
      )}
    </Fragment>
  );
};

export default Errors;
