import FlexContainer from 'components/FlexContainer';
import styled from 'styled-components';

export const ButtonsContainer = styled(FlexContainer)`
  justify-content: space-between;
  width: 100%;
  margin-left: -0.5rem;
  width: calc(100% + 1rem);
  margin-top: 3rem;

  & > button {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  & > button:not(last-child) {
    margin-bottom: 0;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column-reverse;
    width: 100%;

    & > button:not(last-child) {
      margin-bottom: 1rem;
    }
  }
`;
