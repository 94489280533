import styled from 'styled-components';

export const StyledSkipIdentification = styled.div`
  margin-right: -0.5rem;
  margin-top: -0.875rem;
  button {
    width: auto;
    margin-left: auto;
  }
`;
