import React, { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import { ButtonSizes, ButtonVariants } from 'components/Button/types';
import ButtonsGroup from 'components/ButtonsGroup';
import { useIdentificationMethodsSelector } from '../../redux/selectors';

interface IIdentRetryWithVideo {
  retryIdent: MouseEventHandler<HTMLButtonElement>;
  navigateToVideoIdentStart: () => void;
}

const IdentRetryWithVideo: React.FC<IIdentRetryWithVideo> = ({
  retryIdent,
  navigateToVideoIdentStart,
}) => {
  const videoIdentAvailable = useIdentificationMethodsSelector().video;

  return (
    <ButtonsGroup>
      {videoIdentAvailable && (
        <Button
          variant={ButtonVariants.LINK}
          onClick={navigateToVideoIdentStart}
          buttonSize={ButtonSizes.SMALL}
        >
          <FormattedMessage id="message.paymentInitiation.error.buttonIdnowFallback" />
        </Button>
      )}
      <Button marginBottom="1rem" onClick={retryIdent}>
        <FormattedMessage id="message.paymentInitiation.error.buttonRetry" />
      </Button>
    </ButtonsGroup>
  );
};

export default IdentRetryWithVideo;
