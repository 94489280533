import { getSessionInfo } from 'api/endpoints';
import { localStorageGetInit } from 'redux/actions/localStorageActions';
import {
  SESSION_INIT,
  sessionError,
  sessionSuccess,
} from 'redux/actions/sessionActions';
import { configSelector } from 'redux/selectors';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { browserLang } from 'utils/environments';

export function* fetchSessionInfo(): SagaIterator {
  try {
    const config = yield select(configSelector);
    const { baseUrl, sessionToken } = config;
    const { requestMethod, getUrl, getErrorCode } = getSessionInfo;

    const response = yield call(requestMethod, getUrl(baseUrl), sessionToken);

    if (response.errors) {
      throw new Error(getErrorCode(response.errors));
    }
    const {
      callback_url,
      language,
      style,
      custom_content,
      partner_name,
      skip_url,
    } = response;

    if (language) {
      yield put(
        sessionSuccess({
          callbackUrl: callback_url,
          language: language.toLowerCase(),
          style,
          custom_content,
          partner_name,
          skipUrl: skip_url,
        }),
      );
    } else {
      yield put(
        sessionSuccess({
          callbackUrl: callback_url,
          language: browserLang(),
          style,
          custom_content,
          partner_name,
          skipUrl: skip_url,
        }),
      );
    }
    yield put(localStorageGetInit());
  } catch (error) {
    yield put(
      sessionError({ message: error.message, language: browserLang() }),
    );
  }
}

export default function* watchfetchSessionInfoInit() {
  yield takeLatest(SESSION_INIT, fetchSessionInfo);
}
