import React from 'react';
import { StyledFlexContainer } from './FlexContainer.styled';
import IFlexContainer, { ContainerVariants } from './types';

const FlexContainer: React.FC<IFlexContainer> = ({
  variant = ContainerVariants.DEFAULT,
  children,
  ...props
}) => {
  return (
    <StyledFlexContainer variant={variant} {...props}>
      {children}
    </StyledFlexContainer>
  );
};
export default FlexContainer;
