import { ActionState } from 'redux/types';

export interface IMobileNumberInitialState {
  state: ActionState;
  errorMessage: string;
  number: string;
  verified: boolean;
  retry: number;
}

const mobileNumberInitialState: IMobileNumberInitialState = {
  state: ActionState.IDLE,
  errorMessage: '',
  number: '',
  verified: false,
  retry: 1,
};

export default mobileNumberInitialState;
