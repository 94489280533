import {
  mobileNumberAuthorizeInit,
  mobileNumberConfirmInit,
} from 'redux/actions/mobileNumberActions';
import { navigateToUniversalStart } from 'redux/actions/navigationActions';
import {
  useIdentificationMethodsSelector,
  useMobileNumberSelector,
} from 'redux/selectors';
import { ActionState } from 'redux/types';

const usePhoneVerification = () => {
  const mobileNumber = useMobileNumberSelector();
  const identificationMethods = useIdentificationMethodsSelector();
  const bankIdentAvailable = identificationMethods.bank;

  const mobileNumberConfirmationIsSuccessful =
    mobileNumber.verified && mobileNumber.state === ActionState.SUCCESS;

  return {
    mobileNumber,
    identificationMethods,
    bankIdentAvailable,
    mobileNumberConfirmationIsSuccessful,
    mobileNumberAuthorizeInit,
    mobileNumberConfirmInit,
    navigateToUniversalStart,
  };
};

export default usePhoneVerification;
