export const IDENT_METHODS_GET_INIT = 'IDENT_METHODS_GET_INIT';
export const IDENT_METHODS_GET_SUCCESS = 'IDENT_METHODS_GET_SUCCESS';
export const IDENT_METHODS_GET_ERROR = 'IDENT_METHODS_GET_ERROR';

export const IDENT_METHOD_ELIGIBILTY_INIT = 'IDENT_METHOD_ELIGIBILTY_INIT';
export const IDENT_METHOD_ELIGIBILTY_SUCCESS =
  'IDENT_METHOD_ELIGIBILTY_SUCCESS';
export const IDENT_METHOD_ELIGIBILTY_ERROR = 'IDENT_METHOD_ELIGIBILTY_ERROR';

export interface IdentificationMethodsAction {
  type:
    | typeof IDENT_METHODS_GET_SUCCESS
    | typeof IDENT_METHODS_GET_ERROR
    | typeof IDENT_METHOD_ELIGIBILTY_SUCCESS
    | typeof IDENT_METHOD_ELIGIBILTY_ERROR;
  data?: IdentMethodsSuccessData;
}

interface IdentMethodsSuccessData {
  bank?: boolean;
  idnow?: boolean;
  fourthline?: boolean;
}

export const identMethodsGetInit = () => ({
  type: IDENT_METHODS_GET_INIT,
});

export const identMethodsGetSuccess = (data: IdentMethodsSuccessData) => ({
  type: IDENT_METHODS_GET_SUCCESS,
  data,
});

export const identMethodsGetError = (data = { message: 'Error' }) => ({
  type: IDENT_METHODS_GET_ERROR,
  data,
});

export const identMethodEligibilitySuccess = (
  data: IdentMethodsSuccessData,
) => ({
  type: IDENT_METHOD_ELIGIBILTY_SUCCESS,
  data,
});

export const identMethodEligibilityError = (
  data = { errorMessage: 'Error' },
) => ({
  type: IDENT_METHOD_ELIGIBILTY_ERROR,
  data,
});
