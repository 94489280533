import styled, { css } from 'styled-components';
import IInput from './types';

export const StyledInput = styled.input<IInput>`
  padding: 1.25rem;
  font-size: ${({ theme }) => theme.fontSizes.big};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  border: ${({ theme }) =>
    `${theme.inputBorderWidth} solid ${theme.baseColors.base25}`};
  border-radius: ${({ theme }) => theme.inputBorderRadius};
  width: 100%;
  transition: all 0.25s ease;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: ${({ theme }) => theme.baseColors.base50};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.primaryDark};
    outline: none;
  }

  ${({ oneDigitInput }) =>
    oneDigitInput
      ? css`
          line-height: 15px;
          padding: 1.25rem 0.25rem;
          max-width: 3rem;
          min-width: 2rem;
          text-align: center;
        `
      : null};

  ${({ error }) =>
    error
      ? css`
          border-color: ${({ theme }) => theme.baseColors.error};
        `
      : null};
`;
