import { useDispatch } from 'react-redux';
import { identAuthorizationInit } from 'redux/actions/identificationActions';
import {
  useFileDownloadSelector,
  useIdentificationSelector,
  useSessionSelector,
} from 'redux/selectors';
import { IdentificationMethod, IdentificationStatus } from 'redux/types';

const useBankIdentificationSignDocuments = () => {
  const dispatch = useDispatch();
  const identification = useIdentificationSelector();
  const fileDownload = useFileDownloadSelector();
  const session = useSessionSelector();

  const handleSendCodeClick = () => {
    dispatch(identAuthorizationInit());
  };

  const handleFinishClick = () => {
    postMessage('finish');

    const { callbackUrl } = session;
    window.location.href = callbackUrl;
  };

  const { status, method } = identification;

  const isSuccessful = status === IdentificationStatus.SUCCESSFUL;
  const isConfirmed = status === IdentificationStatus.CONFIRMED;
  const isCofirmationRequired =
    status === IdentificationStatus.CONFIRMATION_REQUIRED;

  const isMethodBank = method === IdentificationMethod.BANK;

  return {
    identification,
    fileDownload,
    handleSendCodeClick,
    handleFinishClick,
    isSuccessful,
    isConfirmed,
    isCofirmationRequired,
    isMethodBank,
  };
};

export default useBankIdentificationSignDocuments;
