import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import FlexContainer from 'components/FlexContainer';
import Icon from 'components/Icon';
import Paragraph from 'components/Paragraph';
import { navigateToBankIdentSigning } from 'redux/actions/navigationActions';
import { defaultTheme } from 'theme';

export const BankIdentificationIbanSuccessful = () => {
  const dispatch = useDispatch();

  const handleContinueClick = () => {
    dispatch(navigateToBankIdentSigning());
  };

  return (
    <FlexContainer flexDirection="column" alignItems="flex-start">
      <h2>
        <Icon
          name="Check"
          color={defaultTheme.baseColors.success}
          marginRight="0.5rem"
          width="1.5rem"
          height="1.5rem"
          top="0.2rem"
        />
        <FormattedMessage id="message.paymentIntiationSuccessfull.title" />
      </h2>
      <Paragraph marginBottom="2.5rem" paddingLeft="2rem">
        <FormattedMessage id="message.paymentIntiationSuccessfull.description" />
      </Paragraph>
      <FlexContainer width="100%" justifyContent="flex-end">
        <Button
          onClick={handleContinueClick}
          data-test-id="paymentIntiationSuccessfull"
        >
          <FormattedMessage id="message.button.continue" />
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};
