import { fork } from 'redux-saga/effects';
import configurationSaga from './configurationSaga/configurationSaga';
import {
  watchCheckEligiblityInit,
  watchGetIdentificationMethods,
} from './eligibilitySaga/eligibilitySaga';
import {
  fileDownloadSaga,
  fileShareSaga,
} from './identificationFileSaga/identificationFileSaga';
import {
  identAuthorizationSaga,
  identConfirmationSaga,
  identPollSaga,
  identSaga,
} from './identiificationSaga/identificationSaga';
import {
  watchClearLocalStorage,
  watchClearLocalStorageOnCancellation,
  watchGetLocalStorage,
  watchSetLocalStorage,
} from './localStorageSaga/localStorageSaga';
import {
  watchAuthorizeMobileNumberInit,
  watchConfirmMobileNumberInit,
  watchGetMobileNumberInit,
} from './mobileNumberSaga/mobileNumberSagas';
import { watchNotifyPaymentInitiation } from './paymentInitiationSaga/paymentInitiationSagas';
import {
  watchRecoverIdentAuthorizationRequired,
  watchRecoverIdentConfirmationRequired,
  watchRecoverIdentConfirmed,
  watchRecoverIdentExpired,
  watchRecoverIdentFailed,
  watchRecoverIdentificationInit,
  watchRecoverIdentPending,
  watchRecoverIdentSuccessful,
} from './recoverIdentificationSaga/recoverIdentificationSaga';
import sessionInfoSaga from './sessionSaga/sessionSaga';

export default function* rootSaga() {
  yield fork(configurationSaga);
  yield fork(watchGetMobileNumberInit);
  yield fork(watchAuthorizeMobileNumberInit);
  yield fork(watchConfirmMobileNumberInit);
  yield fork(identSaga);
  yield fork(identPollSaga);
  yield fork(identAuthorizationSaga);
  yield fork(identConfirmationSaga);
  yield fork(fileDownloadSaga);
  yield fork(fileShareSaga);
  yield fork(sessionInfoSaga);
  yield fork(watchGetLocalStorage);
  yield fork(watchSetLocalStorage);
  yield fork(watchClearLocalStorage);
  yield fork(watchClearLocalStorageOnCancellation);
  yield fork(watchRecoverIdentificationInit);
  yield fork(watchRecoverIdentPending);
  yield fork(watchRecoverIdentAuthorizationRequired);
  yield fork(watchRecoverIdentConfirmationRequired);
  yield fork(watchRecoverIdentConfirmed);
  yield fork(watchRecoverIdentFailed);
  yield fork(watchRecoverIdentExpired);
  yield fork(watchRecoverIdentSuccessful);
  yield fork(watchGetIdentificationMethods);
  yield fork(watchCheckEligiblityInit);
  yield fork(watchNotifyPaymentInitiation);
}
