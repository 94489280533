export const ENDPOINTS = {
  getToken: '/oauth/token',
  persons: '/v1/persons',
};

export enum ApiErrorCode {
  INVALID_CLIENT = 'invalid_client',
  MODEL_NOT_FOUND = 'model_not_found',
  VALIDATION_ERROR = 'validation_error',
  INVALID_TOKEN = 'invalid_token',
  INVALID_TAN = 'invalid_tan',
  MATCH_FOR_PERSON_DATA_NOT_FOUND = 'match_for_person_data_not_found',
  PREVIOUS_IDENT_NOT_FOUND = 'previous_ident_not_found',
  IBAN_USAGE_LIMIT_EXCEEDED = 'iban_usage_limit_exceeded',
  PERSON_ACCOUNT_MATCH_NOT_FOUND = 'person_account_match_not_found',
  IDENTIFICATION_ATTEMPTS_EXCEEDED = 'identification_attempts_exceeded',
  INVALID_IBAN = 'invalid_iban',
  TYPE_ERROR = 'type_error',
  EXPECTATION_MISMATCH = 'expectation_mismatch',
  PAYMENT_INITIATION_EXPIRED = 'payment_initiation_expired',
  DEPENDENCY_ERROR = 'dependency_error',
  EXPIRED_SESSION = 'expired_session',
}

// This is camel case on purpose
export const DEFAULT_ERROR_CODE = 'unexpectedError';

export type ApiError = {
  code: ApiErrorCode;
  detail: string;
  id: string;
  status: number;
  title: string;
  source?: ApiErrorSource;
};

export type ApiErrorSource = {
  field: string;
  message: string;
};

export enum VideoIdentLinks {
  ANDROID_GOOGLE_PLAY_STORE = 'https://play.google.com/store/apps/details?id=de.idnow&hl=de&gl=US',
  IOS_APP_STORE = 'https://apps.apple.com/de/app/idnow-online-ident/id918081242',
}
