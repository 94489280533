import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { VideoIdentLinks } from 'api/consts';
import * as clipboard from 'clipboard-polyfill';
import Button from 'components/Button';
import { ButtonVariants } from 'components/Button/types';
import Paragraph from 'components/Paragraph';
import { IdentificationMethod } from 'redux/types';
import { StepsList } from 'sections/BankIdentificationWindow/StepsList/StepsList.styled';
import IdentificationIframe from 'sections/IdentificationIframe';
import { StyledTrailingIcon } from '../../components/Button/Button.styled';
import GooglePlayBadgeEN from '../../theme//googlePlay/googlePlayBadgeEN.png';
import GooglePlayBadgeDE from '../../theme/googlePlay/googlePlayBadgeDE.png';
import IosAppStoreBadgeDE from '../../theme/iosAppStore/iosAppStoreDE.svg';
import IosAppStoreBadgeEN from '../../theme/iosAppStore/iosAppStoreEN.svg';
import { StyledAppButtons } from './VideoIdentificationWindow.styled';

interface IVideoIdentification {
  isMobile: boolean;
  identificationReference: string;
  identificationId: string;
  identificationUrl: string;
  cancelAction: () => void;
}

const VideoIdentificationWindow: React.FC<IVideoIdentification> = ({
  isMobile,
  identificationReference,
  identificationUrl,
  cancelAction,
}) => {
  const { locale } = useIntl();
  const [copied, setCopied] = useState(false);

  const handleSetCopied = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  if (isMobile) {
    return (
      <div>
        <StepsList>
          <li>
            <FormattedMessage id="message.videoIdentification.mobile.step1" />
          </li>
          <li>
            <FormattedMessage id="message.videoIdentification.mobile.step2" />
          </li>
          <li>
            <FormattedMessage id="message.videoIdentification.mobile.step3" />
          </li>
        </StepsList>
        <Paragraph marginBottom="0">
          <small>
            <FormattedMessage id="message.videoIdentification.mobile.info" />
          </small>
        </Paragraph>
        <Button
          variant={ButtonVariants.PRIMARY}
          onClick={() => {
            clipboard
              .writeText(identificationReference)
              .then(() => handleSetCopied());
          }}
          marginTop="2rem"
          minWidth="100%"
        >
          {copied ? (
            <FormattedMessage id="message.videoIdentification.mobile.copyButton.success" />
          ) : (
            <FormattedMessage
              id="message.videoIdentification.mobile.copyButton.label"
              values={{ identificationReference }}
            />
          )}
          <StyledTrailingIcon
            name="Clipboard"
            position="absolute"
            left="90%"
            margin="0 !important"
          />
        </Button>
        <StyledAppButtons>
          <img
            src={locale === 'en' ? GooglePlayBadgeEN : GooglePlayBadgeDE}
            alt="google-play-download-link"
            onClick={() => {
              window.open(VideoIdentLinks.ANDROID_GOOGLE_PLAY_STORE);
            }}
          />
          <img
            src={locale === 'en' ? IosAppStoreBadgeEN : IosAppStoreBadgeDE}
            alt="google-play-download-link"
            onClick={() => {
              window.open(VideoIdentLinks.IOS_APP_STORE);
            }}
          />
        </StyledAppButtons>
        <Button
          variant={ButtonVariants.SECONDARY}
          onClick={() => cancelAction()}
          marginTop="2rem"
          minWidth="100%"
        >
          <FormattedMessage id="message.button.cancel" />
        </Button>
      </div>
    );
  } else {
    return (
      <IdentificationIframe
        type={IdentificationMethod.IDNOW}
        iframeUrl={identificationUrl}
        cancelAction={cancelAction}
      />
    );
  }
};

export default VideoIdentificationWindow;
