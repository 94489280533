import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import FlexContainer from 'components/FlexContainer';
import Icon from 'components/Icon';
import Paragraph from 'components/Paragraph';
import { defaultTheme } from 'theme';
import usePhoneVerificationSuccess from 'utils/hooks/usePhoneVerificationSuccess';

interface IPhoneVerificationSuccess {
  bankIdentAvailable: boolean;
}

const PhoneVerificationSuccess: React.FC<IPhoneVerificationSuccess> = ({
  bankIdentAvailable,
}) => {
  const { handleNavigateToBankIdent, handleCreateVideoIdent } =
    usePhoneVerificationSuccess();

  return (
    <Fragment>
      <h2>
        <Icon
          name="Check"
          color={defaultTheme.baseColors.success}
          marginRight="0.5rem"
          width="1.5rem"
          height="1.5rem"
          top="0.2rem"
        />
        <FormattedMessage id="message.phoneVerification.success.header" />
      </h2>

      <Paragraph marginBottom="2.5rem" paddingLeft="2rem">
        {bankIdentAvailable ? (
          <Fragment>
            <FormattedMessage id="message.phoneVerification.success.descriptionBank.part1" />
            <strong>IBAN</strong>
            <FormattedMessage id="message.phoneVerification.success.descriptionBank.part2" />
            <strong>
              <FormattedMessage id="message.phoneVerification.success.descriptionBank.part3" />
            </strong>
            <FormattedMessage id="message.phoneVerification.success.descriptionBank.part4" />
          </Fragment>
        ) : (
          <Fragment>
            <FormattedMessage id="message.phoneVerification.success.descriptionVideo.part1" />
            <strong>
              <FormattedMessage id="message.phoneVerification.success.descriptionVideo.part2" />
            </strong>
            <FormattedMessage id="message.phoneVerification.success.descriptionVideo.part3" />
            <FormattedMessage id="message.phoneVerification.success.descriptionVideo.part4" />
          </Fragment>
        )}
      </Paragraph>
      <FlexContainer width="100%" justifyContent="flex-end">
        <Button
          onClick={
            bankIdentAvailable
              ? handleNavigateToBankIdent
              : handleCreateVideoIdent
          }
          data-test-id="phoneVerificationSuccess"
        >
          <FormattedMessage id="message.button.continue" />
        </Button>
      </FlexContainer>
    </Fragment>
  );
};

export default PhoneVerificationSuccess;
