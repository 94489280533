import React from 'react';
import Iframe from 'react-iframe';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import { ButtonVariants } from 'components/Button/types';
import FlexContainer from 'components/FlexContainer';
import { IdentificationMethod } from 'redux/types';
import { IframeWrapper } from './IdentificationIframe.styled';

enum identTypeToClass {
  bank = 'po-iframe-fts',
  idnow = 'po-iframe-idnow',
}

interface IIdentIframe {
  iframeUrl: string;
  cancelAction: () => void;
  type: IdentificationMethod;
}

const IdentificationIframe: React.FC<IIdentIframe> = ({
  iframeUrl,
  cancelAction,
  type,
}) => {
  return (
    <IframeWrapper className={identTypeToClass[type]}>
      <Iframe
        url={iframeUrl}
        height="680px"
        width="100%"
        allow="microphone; camera"
      />
      <FlexContainer width="100%" justifyContent="center">
        <Button
          variant={ButtonVariants.SECONDARY}
          onClick={() => {
            cancelAction();
          }}
          marginTop="2rem"
        >
          <FormattedMessage id="message.button.cancel" />
        </Button>
      </FlexContainer>
    </IframeWrapper>
  );
};

export default IdentificationIframe;
