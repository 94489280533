import Icon from 'components/Icon';
import styled, { css } from 'styled-components';
import { layout, space } from 'styled-system';
import IButton, { ButtonSizes, ButtonVariants } from './types';

export const StyledButton = styled.button<IButton>`
  position: relative;
  background-color: var(--button-bg);
  border: ${({ theme }) => theme.inputBorderWidth} solid transparent;
  border-radius: ${({ theme }) => theme.buttonBorderRadius};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.3;
  font-family: 'Roboto', Arial, sans-serif;
  letter-spacing: 0.005em;
  padding: 1rem 1.75rem;
  transition: all 0.2s;
  ${layout}
  ${space}

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  ${({ buttonSize }) => {
    switch (buttonSize) {
      case ButtonSizes.SMALL:
        return css`
          min-height: 2.25rem;
          padding: 0.625rem 0.75rem;
          font-size: ${({ theme }) => theme.fontSizes.regular};
        `;
      default:
        return css`
          min-height: 3.75rem;
          font-size: ${({ theme }) => theme.fontSizes.medium};
        `;
    }
  }}

  ${({ variant }) => {
    switch (variant) {
      case ButtonVariants.SECONDARY:
        return css`
          --button-bg: ${({ theme }) => theme.baseColors.base05};
          color: ${({ theme }) => theme.baseColors.base100};
          font-weight: ${({ theme }) => theme.fontWeights.regular};

          &:hover {
            background-color: ${({ theme }) => theme.baseColors.base10};
          }

          &:disabled {
            color: ${({ theme }) => theme.baseColors.base50};
            opacity: 0.9;
          }
        `;
      case ButtonVariants.LINK:
        return css`
          background: transparent;
          color: ${({ theme }) => theme.colors.primaryDark};
          padding: 0;

          &:hover {
            opacity: 0.7;
          }
        `;
      default:
        return css`
          --button-bg: ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) =>
            theme.colors.primaryButtonLabel || theme.baseColors.white};

          &:hover {
            background-color: ${({ theme }) =>
              theme.colors.primaryButtonHoverBackground ||
              theme.colors.primaryDark};
          }
        `;
    }
  }}

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const StyledLeadingIcon = styled(Icon)`
  margin: 0 1.25em 0.2em -0.3em;
`;

export const StyledTrailingIcon = styled(Icon)`
  margin: 0 -0.3em 0.2em 1.25em;
`;

export const StyledCenteredIcon = styled(Icon)`
  margin: auto;
`;

export const SpinnerOverlay = styled.div`
  align-items: center;
  background-color: var(--button-bg);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;
