import Layout from 'components/Layout';
import ProgressBar from 'components/ProgressBar';
import { IdentificationMethod } from 'redux/types';
import VideoIdentificationWindow from 'sections/VideoIdentificationWindow';
import useVideoIdent from 'utils/hooks/useVideoIdent';
import Loading from '../../components/Loading/Loading';
import SkipIdentification from '../../components/SkipIdentification/SkipIdentification';
import VideoIdentificationSuccesful from './VideoIdentificationSuccessful';

const VideoIdentification = () => {
  const {
    identificationIsSuccessful,
    identificationIsPending,
    identificationUrl,
    identificationId,
    identificationReference,
    isLoading,
    isMobile,
    identCancel,
  } = useVideoIdent();

  let content = null;

  if (isLoading) {
    content = <Loading type="initializing" />;
  }

  if (identificationIsPending) {
    content = (
      <VideoIdentificationWindow
        isMobile={isMobile}
        identificationReference={identificationReference}
        identificationId={identificationId}
        identificationUrl={identificationUrl}
        cancelAction={identCancel}
      />
    );
  }
  if (identificationIsSuccessful) {
    content = (
      <VideoIdentificationSuccesful padding-top="0" padding-bottom="0" />
    );
  }

  return (
    <Layout>
      <SkipIdentification />
      {!identificationIsSuccessful && (
        <ProgressBar step={1} type={IdentificationMethod.IDNOW} />
      )}
      {content}
    </Layout>
  );
};

export default VideoIdentification;
