import React, { Fragment } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { ButtonVariants } from 'components/Button/types';
import ButtonsGroup from 'components/ButtonsGroup';
import Errors from 'components/Errors';
import TanInput, { handleOnPaste } from 'components/TanInput';
import {
  PhoneVerificationForm,
  TanInputWrapper,
  TanValidationError,
} from 'pages/PhoneVerification/PhoneVerification.styled';
import { mobileNumberAuthorizeCancelled } from 'redux/actions/mobileNumberActions';
import { IMobileNumberInitialState } from 'redux/initialState/mobileNumberInitialState';
import { ActionState } from 'redux/types';
import TanRetry from 'sections/TanRetry';
import { truncatePhoneNumber } from 'utils/formatters';
import validateTan from 'utils/validation/validateTan';
import Paragraph from '../../components/Paragraph';
import { useIdentificationMethodsSelector } from '../../redux/selectors';

interface IPhoneVerification {
  onSubmitAction: (tan: string) => void;
  retryAction: (retry: boolean) => void;
  navigateToStart: () => void;
  mobileNumber: IMobileNumberInitialState;
}

interface IFormInputs {
  tan: string[];
}

const PhoneVerificationNeeded: React.FC<IPhoneVerification> = ({
  mobileNumber,
  onSubmitAction,
  retryAction,
  navigateToStart,
}) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, errors, reset } = useForm();
  const mobileNumberConfirmationHasError =
    mobileNumber.state === ActionState.ERROR;
  const mobileNumberConfirmationHasErrorMessage = errors?.tan?.message;
  const mobileNumberConfirmationInProgress =
    mobileNumber.state === ActionState.IN_PROGRESS;
  const videoIdentAvailable = useIdentificationMethodsSelector().video;

  const onSubmit = (values: IFormInputs) => {
    dispatch(onSubmitAction(values.tan.join('')));
    // reset form to default values
    reset();
  };

  const onRetryAction = () => {
    dispatch(retryAction(true));
  };

  const handleNavigateToStart = () => {
    dispatch(mobileNumberAuthorizeCancelled());

    dispatch(navigateToStart());
  };

  return (
    <Fragment>
      <h2>
        <FormattedMessage id="message.phoneVerification.title" />
      </h2>
      <Paragraph>
        <FormattedMessage id="message.phoneVerification.description" />
        <strong>{truncatePhoneNumber(mobileNumber.number)}</strong>.
      </Paragraph>
      <PhoneVerificationForm
        onSubmit={handleSubmit(onSubmit)}
        onPaste={(e: React.ClipboardEvent) => handleOnPaste(e, reset)}
      >
        <TanInputWrapper>
          <Controller
            name="tan"
            control={control}
            defaultValue={['', '', '', '', '', '']}
            rules={{
              validate: validateTan,
            }}
            render={({ onChange, value }) => (
              <TanInput
                onChange={(v: string[]) => onChange(v)}
                value={value}
                error={!!mobileNumberConfirmationHasErrorMessage}
              />
            )}
          />
        </TanInputWrapper>
        {mobileNumberConfirmationHasErrorMessage && (
          <TanValidationError>
            <FormattedMessage id={errors.tan.message} />
          </TanValidationError>
        )}
        {mobileNumberConfirmationHasError && (
          <Errors
            errorMessages={[mobileNumber.errorMessage]}
            marginTop={'1rem'}
          />
        )}
        <TanRetry retryAction={onRetryAction}></TanRetry>

        <ButtonsGroup>
          {videoIdentAvailable && (
            <Button
              variant={ButtonVariants.SECONDARY}
              onClick={() => handleNavigateToStart()}
              minWidth="33%"
            >
              <FormattedMessage id="message.button.cancel" />
            </Button>
          )}

          <Button
            type="submit"
            loading={mobileNumberConfirmationInProgress}
            data-test-id="submitTan"
          >
            <FormattedMessage id="message.button.confirm" />
          </Button>
        </ButtonsGroup>
      </PhoneVerificationForm>
    </Fragment>
  );
};

export default PhoneVerificationNeeded;
