import { NavigationRoutesState } from 'redux/types';

export interface INavigationInitialState {
  route: NavigationRoutesState;
}

const navigationInitialState: INavigationInitialState = {
  route: NavigationRoutesState.UNIVERSAL_START,
};

export default navigationInitialState;
