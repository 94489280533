export const localizedSwisscomTermsAndConditions = (lang) =>
  lang === 'de'
    ? 'https://w3.swissdigicert.ch/TermsOfUse_Pers_EU-de.pdf'
    : 'https://w3.swissdigicert.ch/TermsOfUse_Pers_EU-en.pdf';

export const flattenMessages = (nestedMessages, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};
