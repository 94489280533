import styled from 'styled-components';

export const ViewPdfDocumentStyled = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.baseColors.base20};
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.cardBorderRadius};
  margin-bottom: 4rem;
  overflow: hidden;

  .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
  }
`;
