import {
  checkEligibility as postCheckEligibilty,
  getIdentificationMethods as getIdentMethods,
} from 'api/endpoints';
import {
  IDENT_METHODS_GET_SUCCESS,
  IdentificationMethodsAction,
  identMethodEligibilityError,
  identMethodEligibilitySuccess,
  identMethodsGetError,
  identMethodsGetSuccess,
} from 'redux/actions/identificationMethodsActions';
import {
  navigateToBankIdentStart,
  navigateToUniversalStart,
  navigateToVideoIdentStart,
} from 'redux/actions/navigationActions';
import { SESSION_SUCCESS } from 'redux/actions/sessionActions';
import { configSelector } from 'redux/selectors';
import { IdentificationMethod } from 'redux/types';
import { SagaIterator } from 'redux-saga';
import { call, cancel, put, select, takeLatest } from 'redux-saga/effects';

export function* checkEligibilty(
  action: IdentificationMethodsAction,
): SagaIterator<void> {
  const { data } = action;
  const isIDNowAllowed = data?.[IdentificationMethod.IDNOW];
  const isBankAllowed = yield call(
    getBankEligibility,
    data?.[IdentificationMethod.BANK],
  );

  yield put(
    identMethodEligibilitySuccess({
      bank: isBankAllowed,
      idnow: isIDNowAllowed,
    }),
  );

  if (isBankAllowed && isIDNowAllowed) {
    yield put(navigateToUniversalStart());
  } else if (isIDNowAllowed) {
    yield put(navigateToVideoIdentStart());
  } else if (isBankAllowed) {
    yield put(navigateToBankIdentStart());
  } else {
    yield put(
      identMethodEligibilityError({ errorMessage: 'noIdentMethodAvailable' }),
    );
  }
}

// https://github.solarisbank.de/identity/person-onboarding-ui/pull/205#issue-144622
export function* getBankEligibility(
  isBankAvailable?: boolean,
): SagaIterator<boolean> {
  // if bank is not available in the identification session, we don't need to check the customers eligibility
  if (!isBankAvailable) return false;

  const { baseUrl, sessionToken } = yield select(configSelector);
  const { requestMethod, getUrl, getRequestBody, getErrorCode } =
    postCheckEligibilty;

  const response = yield call(
    requestMethod,
    getUrl(baseUrl),
    getRequestBody('bank'),
    sessionToken,
  );

  if (response.errors) {
    yield put(
      identMethodEligibilityError({
        errorMessage: getErrorCode(response.errors),
      }),
    );
    yield cancel();
  }
  return response.eligible;
}

export function* watchCheckEligiblityInit() {
  yield takeLatest(IDENT_METHODS_GET_SUCCESS, checkEligibilty);
}

export function* getIdentificationMethods(): SagaIterator<void> {
  try {
    const config = yield select(configSelector);
    const { baseUrl, sessionToken } = config;

    const { requestMethod, getUrl, getErrorCode } = getIdentMethods;

    const response = yield call(requestMethod, getUrl(baseUrl), sessionToken);

    if (response.errors) {
      throw new Error(getErrorCode(response.errors));
    }

    yield put(identMethodsGetSuccess({ ...response }));
  } catch (error) {
    yield put(identMethodsGetError({ message: error.message }));
  }
}

export function* watchGetIdentificationMethods() {
  yield takeLatest(SESSION_SUCCESS, getIdentificationMethods);
}
