export const deErrors = {
  validationErrorSessionToken:
    'Diese Identifikationssitzung ist leider nicht mehr gültig',
  modelNotFound:
    'Wir konnten nicht alle Informationen abrufen, die für eine vollständige Identifizierung erforderlich sind.',
  problemWithAuthorization:
    'Problem mit der Autorisierung. Versuchen Sie es später erneut.',
  invalidClient: 'Ungültiger Client',
  couldNotFindPerson: 'Konnte die Person mit der angegebenen ID nicht finden',
  couldNotFindMobileNumber: 'Konnte die angegebene Handynummer nicht finden',
  invalidToken:
    'Ungültige TAN. Ein neuer Code ist bereits unterwegs zu Ihnen. Bitte versuchen Sie es noch einmal.',
  dependencyError:
    'Ungültige TAN. Ein neuer Code ist bereits unterwegs zu Ihnen. Bitte versuchen Sie es noch einmal.',
  expectationMismatch:
    'Ihr Identifizierungsprozess ist abgelaufen. \nBitte versuchen Sie es erneut.',
  invalidTan: 'Ungültige TAN',
  invalidIban: 'Ungültige IBAN. Bitte versuchen Sie es noch einmal.',
  userDataNotFound: 'Benutzerdaten nicht auf schufaCreditReport gefunden',
  userDidNotHaveQbit: 'Der Benutzer hatte kein qbit auf CreditReport',
  jointAccount:
    'Gemeinschaftskonten sind nicht unterstützt. \nBitte nutzen Sie eine andere IBAN oder fahren Sie mit Video-Identifizierung fort.',
  identificationAttemptsExceeded:
    'Für diese IBAN steht diese Identifikationsmethode nicht zur Verfügung. \nBitte versuchen Sie es mit einer anderen IBAN oder fahren Sie mit Video-Identifizierung fort.',
  matchForPersonDataNotFound:
    'Für diese IBAN steht diese Identifikationsmethode nicht zur Verfügung. \nBitte versuchen Sie es mit einer anderen IBAN oder fahren Sie mit Video-Identifizierung fort.',
  previousIdentNotFound:
    'Diese Identifikationsmethode steht nicht zur Verfügung. \nBitte fahren Sie mit Video-Identifizierung fort.',
  personAccountMatchNotFound:
    'Diese Identifikationsmethode steht nicht zur Verfügung. \nBitte fahren Sie mit Video-Identifizierung fort.',
  accessByAuthorizedHolder:
    'Sie scheinen nicht der Kontoinhaber zu sein. \nBitte nutzen Sie eine andere IBAN oder fahren Sie mit Video-Identifizierung fort.',
  ibanUsageLimitExceeded:
    'Sie haben das Limit an Versuchen überschritten. \nBitte fahren Sie mit Video-Identifizierung fort.',
  errorDuringDownload:
    'Beim Versuch, ein Dokument herunterzuladen, ist ein Fehler aufgetreten.',
  paymentError:
    'Leider konnten wir die Identifikation mit der angegebenen IBAN nicht verarbeiten.',
  sessionTimeout:
    'Leider ist Ihre Sitzung abgelaufen. Bitte versuchen Sie es erneut.',
  tanFailed: 'Sie haben die maximale Anzahl der TAN-Versuche überschritten.',
  personMissingRequiredData:
    'Ein Fehler ist aufgetreten. Bitte kontaktieren Sie den Support.',
  alreadyIdentifiedSuccessfully:
    'Sie haben sich bereits erfolgreich identifizert.',
  loginFailed:
    'Sie haben die maximale Anzahl von Anmeldeversuchen bei Ihrer Bank überschritten. \nBitte validieren Sie Ihre Anmeldedaten, bevor Sie es erneut versuchen.',
  techError:
    'Es ist ein technischer Fehler aufgetreten. \nVersuchen Sie es später noch einmal.',
  transNotPossible:
    'Leider konnte die Transaktion nicht abgeschlossen werden. Bitte versuchen Sie es erneut.',
  invalidSessionOrEnv: 'Bitte versuchen Sie es noch einmal.',
  accountSnapshotExpired:
    'Die Anmeldesitzung der Bank ist abgelaufen. \nBitte versuchen Sie es erneut.',
  accountSnapshotFailed:
    'Die Bankanmeldung oder Überweisung ist fehlgeschlagen. \nBitte versuchen Sie es erneut.',
  paymentInitiationExpired:
    'Leider konnte Ihre Bankanmeldung nicht im erwarteten Zeitrahmen abgeschlossen werden. \nBitte versuchen Sie es erneut.',
  expired:
    'Ihr Identifizierungsprozess ist abgelaufen. \nBitte versuchen Sie es erneut.',
  default:
    'Es ist ein Fehler aufgetreten. \n‘Bitte versuchen Sie es später noch einmal.',
  expiredSession: 'Bitte versuchen Sie es noch einmal.',
  shareError: 'Leider kann diese Datei nicht geteilt werden.',
  unexpectedError:
    'Es ist ein Fehler aufgetreten. Bitte starten Sie die Identifizierung neu.',
  userAbortWhileWaiting: 'Die Identifizierung wurde vom Benutzer abgebrochen.',
  idNotSupported: 'Ausweisdokumente werden nicht unterstützt.',
  noIdentMethodAvailable:
    'Es steht keine Identifizierungsoption zur Verfügung.',
};
