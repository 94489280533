import * as React from 'react';

const SvgViewDocument = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 422 512" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M324 14.097l83.9 83.884c9 8.998 14.1 21.296 14.1 33.993V464.01c0 26.494-21.499 47.99-48 47.99H86c-26.5 0-48-21.496-48-47.99V435.2h32v28.81c0 8.799 7.2 15.995 16 15.995h288c8.801 0 16-7.196 16-15.995V160.07H286c-13.3 0-24-10.698-24-23.996V32.093H86c-8.8 0-16 7.2-16 15.998V179.2H38V48.09C38 21.597 59.5.1 86 0h204.101c12.7 0 24.899 5.099 33.9 14.097zm65.5 113.978a15.747 15.747 0 00-4.2-7.398L301.4 36.793c-2.102-2.1-4.6-3.5-7.401-4.2v95.482h95.5z" />
    <path d="M153.5 247.708a59.055 59.055 0 00-16.65 2.67 29.612 29.612 0 013.858 14.413 29.94 29.94 0 01-2.272 11.441 29.905 29.905 0 01-6.47 9.7 29.818 29.818 0 01-21.105 8.756 29.507 29.507 0 01-14.39-3.865 59.727 59.727 0 001.153 36.624 59.622 59.622 0 0022.315 29.035 59.482 59.482 0 0035.052 10.486 59.48 59.48 0 0034.557-12.023 59.628 59.628 0 0021.017-29.992 59.728 59.728 0 00-.458-36.638 59.634 59.634 0 00-21.76-29.456 59.481 59.481 0 00-34.847-11.15zm151.645 51.997C276.241 243.219 219.02 205 153.5 205c-65.52 0-122.758 38.245-151.645 94.71a17.302 17.302 0 000 15.585C30.758 371.78 87.98 410 153.5 410c65.52 0 122.757-38.245 151.645-94.71a17.288 17.288 0 000-15.585zM153.5 384.375c-52.58 0-100.782-29.361-126.814-76.875 26.032-47.514 74.23-76.875 126.814-76.875 52.584 0 100.782 29.361 126.814 76.875-26.026 47.514-74.23 76.875-126.814 76.875z" />
  </svg>
);

export default SvgViewDocument;
