import styled, { css } from 'styled-components';
import { IStyledCheckbox } from './types';

export const StyledLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 2.25rem;
  cursor: pointer;
  max-width: 30em;
  line-height: 1.4;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  small {
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    color: ${({ theme }) => theme.baseColors.base50};
  }
`;

export const StyledError = styled.div`
  padding-left: 2.25rem;
`;

export const StyledCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: ${({ theme }) => theme.baseColors.white};
  border: 2px solid ${({ theme }) => theme.baseColors.base50};
  border-radius: ${({ theme }) => theme.inputBorderRadius};

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const StyledCheckbox = styled.input.attrs(() => ({
  type: 'checkbox',
}))<IStyledCheckbox>`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;

  &:checked ~ ${StyledCheckmark} {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    border-color: ${({ theme }) => theme.colors.primaryDark};

    &:after {
      border: solid white;
      border-width: 0 3px 3px 0;
      text-shadow: 0 0 3px black;
      display: block;
      height: 0.6rem;
      left: 0.4rem;
      top: 0.15rem;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 0.3rem;
    }
  }

  ${({ disabled }) =>
    disabled
      ? css`
          & ~ ${StyledCheckmark} {
            background-color: ${({ theme }) => theme.baseColors.base05};
            border-color: ${({ theme }) => theme.baseColors.base10};
          }

          &:checked ~ ${StyledCheckmark} {
            background-color: ${({ theme }) => theme.baseColors.base25};
            border-color: ${({ theme }) => theme.baseColors.base25};

            &:after {
              border-color: ${({ theme }) => theme.baseColors.base05};
            }
          }
        `
      : null};

  ${({ error }) =>
    error
      ? css`
          & ~ ${StyledCheckmark} {
            border-color: ${({ theme }) => theme.baseColors.error};
          }
        `
      : null};
`;
