import React, { ChangeEvent, Fragment } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/Button';
import { ButtonSizes, ButtonVariants } from 'components/Button/types';
import ButtonsGroup from 'components/ButtonsGroup';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import InputField from 'components/InputField';
import Message from 'components/Message';
import { formatIban, stripWhiteSpace } from 'utils/formatters';
import { localizedSwisscomTermsAndConditions } from 'utils/locales/utils';
import validateIban from 'utils/validation/validateIban';
import { useIdentificationMethodsSelector } from '../../redux/selectors';
import { IFormInputs } from './BankIdentificationIban';
import {
  InputWrapper,
  StyledBankIdentificationIbanForm,
} from './BankIdentificationIban.styled';

interface IBankIdentiificationIbanForm {
  onSubmit: SubmitHandler<IFormInputs>;
  navigateToVideoIdentStart: () => void;
  error?: boolean;
  errorMessage?: string;
  loading?: boolean;
  hasMethodBank?: boolean;
}

export const BankIdentificationIbanForm: React.FC<
  IBankIdentiificationIbanForm
> = ({
  onSubmit,
  navigateToVideoIdentStart,
  error = false,
  errorMessage = '',
  loading = false,
}) => {
  const {
    control,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });
  const { locale } = useIntl();
  const videoIdentAvailable = useIdentificationMethodsSelector().video;

  return (
    <Fragment>
      <h2>
        <FormattedMessage id="message.paymentInitiation.title" />
      </h2>
      <p>
        <FormattedMessage id="message.paymentInitiation.description.part1" />
        <strong>
          <FormattedMessage id="message.paymentInitiation.description.part2" />
        </strong>
        <FormattedMessage id="message.paymentInitiation.description.part3" />
      </p>
      <StyledBankIdentificationIbanForm onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper className="iban-input">
          <Controller
            name="iban"
            control={control}
            defaultValue=""
            rules={{ validate: validateIban }}
            render={({ onChange, value }) => (
              <InputField errorMessage={errors?.iban?.message}>
                <Input
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChange(stripWhiteSpace(e.target.value))
                  }
                  value={formatIban(value)}
                  checked={value}
                  style={{ textTransform: 'uppercase' }}
                  placeholder="DEXX XXXX XXXX XXXX XXXX XX"
                  error={!!errors?.iban?.message}
                  data-test-id="ibanInput"
                />
              </InputField>
            )}
          />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name="terms_and_conditions_signed"
            control={control}
            defaultValue={false}
            rules={{
              required: 'message.paymentInitiation.termsAndConditions.required',
            }}
            render={({ onChange, value }) => (
              <Checkbox
                label={
                  <div data-test-id="acceptSwisscom">
                    <FormattedMessage
                      id="message.paymentInitiation.termsAndConditions.label"
                      values={{
                        a: (chunks: {}) => (
                          <a
                            target="_blank"
                            href={localizedSwisscomTermsAndConditions(locale)}
                            rel="noreferrer"
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                    <br />
                    <small>
                      <FormattedMessage id="message.paymentInitiation.termsAndConditions.hint" />
                    </small>
                  </div>
                }
                error={!!errors?.terms_and_conditions_signed?.message}
                value={value}
                errorMessage={errors?.terms_and_conditions_signed?.message}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onChange(e.target.checked)
                }
              />
            )}
          />
        </InputWrapper>
        {error ? <Message type="error">{errorMessage}</Message> : null}
        <ButtonsGroup>
          {videoIdentAvailable && (
            <Button
              variant={ButtonVariants.LINK}
              buttonSize={ButtonSizes.SMALL}
              onClick={navigateToVideoIdentStart}
            >
              <FormattedMessage id="message.paymentInitiation.buttonIdnow" />
            </Button>
          )}

          <Button
            type="submit"
            loading={loading}
            disabled={!isValid}
            trailingIcon="ArrowRight"
            data-test-id="submitIban"
          >
            <FormattedMessage id="message.button.next" />
          </Button>
        </ButtonsGroup>
      </StyledBankIdentificationIbanForm>
    </Fragment>
  );
};
