import styled from 'styled-components';

export const StyledSpinner = styled.div<{ scale: number }>`
  display: inline-block;
  transform: scale(${({ scale }) => scale});

  svg {
    animation: spin 1.1s linear infinite;

    circle#bar {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
