import React from 'react';
import Errors from 'components/Errors';
import Layout from 'components/Layout';
import Loading from 'components/Loading/Loading';

interface ILoadingPage {
  errorMessages: string[];
}

const LoadingPage: React.FC<ILoadingPage> = ({ errorMessages }) => {
  const sanitizedErrors = errorMessages.filter((n) => n);
  return (
    <Layout>
      <Loading type="noText" />
      {sanitizedErrors.length > 0 && <Errors errorMessages={sanitizedErrors} />}
    </Layout>
  );
};

export default LoadingPage;
