import styled from 'styled-components';

export const StyledAppButtons = styled.div`
  display: grid;
  grid-template-columns: 1.1fr 1fr;
  gap: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  max-width: 20rem;

  img {
    height: auto;
    width: 100%;
  }
`;
