import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import { ButtonVariants } from 'components/Button/types';
import ButtonsGroup from 'components/ButtonsGroup';
import { IIdentificationInitialState } from 'redux/initialState/identificationInitialState';
import { IMobileNumberInitialState } from 'redux/initialState/mobileNumberInitialState';
import { ActionState } from 'redux/types';

interface IButtonOptions {
  mobileNumber: IMobileNumberInitialState;
  identification: IIdentificationInitialState;
  callbackAction: () => void;
  callbackActionText?: string;
  mobileNumberAuthorizationAction: () => void;
  bankIdentAction: () => void;
}

const ButtonOptions: React.FC<IButtonOptions> = ({
  mobileNumber,
  identification,
  callbackAction,
  callbackActionText = 'message.button.cancel',
  mobileNumberAuthorizationAction,
  bankIdentAction,
}) => {
  const { verified } = mobileNumber;

  const submitInProgress = [mobileNumber.state, identification.state].includes(
    ActionState.IN_PROGRESS,
  );

  const renderBankIdentButtonOptions = () => {
    return verified ? (
      <Button onClick={bankIdentAction} data-test-id="continueBankIdent">
        <FormattedMessage id="message.button.continue" />
      </Button>
    ) : (
      <Button
        onClick={mobileNumberAuthorizationAction}
        loading={submitInProgress}
        data-test-id="startBankIdent"
      >
        <FormattedMessage id="message.button.start" />
      </Button>
    );
  };

  return (
    <ButtonsGroup>
      <Button
        variant={ButtonVariants.SECONDARY}
        onClick={callbackAction}
        data-test-id="cancelBankIdent"
      >
        <FormattedMessage id={callbackActionText} />
      </Button>
      {renderBankIdentButtonOptions()}
    </ButtonsGroup>
  );
};

export default ButtonOptions;
