import styled from 'styled-components';

export const IframeWrapper = styled.div`
  max-width: 100rem;
  margin: 2.5rem -2rem 0 -2rem;

  &.po-iframe-fts {
    width: calc(100% + 4rem);
  }

  &.po-iframe-idnow {
    width: calc(80vw + 4rem);
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    &.po-iframe-idnow > iframe {
      height: 95rem;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    &.po-iframe-fts > iframe {
      height: 48rem;
    }
    &.po-iframe-idnow {
      width: calc(100% + 4rem);
    }
  }

  iframe {
    border: none;
  }
`;
