import { ActionState } from 'redux/types';
import { DefaultTheme } from 'styled-components';

export interface ISessionInitialState {
  state: ActionState;
  callbackUrl: string;
  status: string;
  errorMessage: string;
  language: string;
  style: Partial<DefaultTheme>;
  custom_content: {
    en?: ICustomContent;
    de?: ICustomContent;
  };
  partner_name: string | null;
  skipUrl: string | null;
}

export interface ICustomContent {
  support_url?: string;
  support_phone?: string;
}

const sessionInitialState: ISessionInitialState = {
  state: ActionState.IDLE,
  callbackUrl: '',
  status: '',
  errorMessage: '',
  language: 'en',
  style: {},
  custom_content: {},
  partner_name: null,
  skipUrl: null,
};

export default sessionInitialState;
