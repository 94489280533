import {
  authorizeMobileNumber as authorizeMobileNumberApi,
  confirmMobileNumber as confirmMobileNumberApi,
  getMobileNumber as getMobileNumberApi,
} from 'api/endpoints';
import {
  IMobileNumberAction,
  MOBILE_NUMBER_AUTHORIZE_INIT,
  MOBILE_NUMBER_CONFIRM_INIT,
  MOBILE_NUMBER_GET_INIT,
  mobileNumberAuthorizeError,
  mobileNumberAuthorizeSuccess,
  mobileNumberConfirmError,
  mobileNumberConfirmInit,
  mobileNumberConfirmSuccess,
  mobileNumberGetError,
  mobileNumberGetSuccess,
} from 'redux/actions/mobileNumberActions';
import { navigateToMobileNumber } from 'redux/actions/navigationActions';
import { configSelector, mobileNumberSelector } from 'redux/selectors';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

export function* getMobileNumber(): SagaIterator {
  try {
    const config = yield select(configSelector);
    const { baseUrl, sessionToken } = config;

    const { requestMethod, getUrl, getErrorCode } = getMobileNumberApi;

    const response = yield call(requestMethod, getUrl(baseUrl), sessionToken);

    if (response.errors) {
      throw new Error(getErrorCode(response.errors));
    }
    const { number, verified } = response;
    yield put(mobileNumberGetSuccess(number, verified));
  } catch (error) {
    yield put(mobileNumberGetError({ message: error.message }));
  }
}

export function* watchGetMobileNumberInit() {
  yield takeLatest(MOBILE_NUMBER_GET_INIT, getMobileNumber);
}

export function* authorizeMobileNumber(): SagaIterator {
  try {
    const config = yield select(configSelector);
    const { baseUrl, sessionToken } = config;

    const { requestMethod, getUrl, getRequestBody, getErrorCode } =
      authorizeMobileNumberApi;

    const response = yield call(
      requestMethod,
      getUrl(baseUrl),
      getRequestBody(),
      sessionToken,
    );

    if (response.errors) {
      throw new Error(getErrorCode(response.errors));
    }
    const { number, verified } = response;
    yield put(mobileNumberAuthorizeSuccess(number, verified));
    yield put(navigateToMobileNumber());
  } catch (error) {
    yield put(mobileNumberAuthorizeError({ message: error.message }));
  }
}

export function* watchAuthorizeMobileNumberInit() {
  yield takeLatest(MOBILE_NUMBER_AUTHORIZE_INIT, authorizeMobileNumber);
}

export function* confirmMobileNumber(
  action: IMobileNumberAction,
): SagaIterator {
  try {
    const config = yield select(configSelector);
    const { baseUrl, sessionToken } = config;
    const { token } = action.data!;

    if (token) {
      const { requestMethod, getUrl, getRequestBody, getErrorCode } =
        confirmMobileNumberApi;

      const response = yield call(
        requestMethod,
        getUrl(baseUrl),
        getRequestBody(token),
        sessionToken,
      );

      if (response.number) {
        const { number, verified } = response;

        yield put(mobileNumberConfirmSuccess(number, verified));
      }

      if (response.errors) {
        throw new Error(getErrorCode(response.errors));
      }
    }
  } catch (error) {
    const { retry } = yield select(mobileNumberSelector);
    const { token } = action.data!;
    const { message } = error;
    // There is a bug in the sB API whereby requesting multiple TAN's and attempting to submit their values may fail for technical limitation reasons.
    // To play it safe and provide a good experience we automatically retry the request on behalf of the user to be sure
    if (retry >= 1 && message === 'invalidTan' && token) {
      yield put(mobileNumberConfirmInit(token));
    } else {
      yield put(mobileNumberConfirmError({ message }));
    }
  }
}

export function* watchConfirmMobileNumberInit() {
  yield takeLatest(MOBILE_NUMBER_CONFIRM_INIT, confirmMobileNumber);
}
