import styled from 'styled-components';
import { space, typography } from 'styled-system';
import IParagraph from './types';

export const StyledParagraph = styled.p<IParagraph>`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  line-height: 1.5;
  margin-bottom: 1.5em;
  margin-top: 0.5em;
  white-space: pre-line;
  ${typography};
  ${space};
`;
