import { MutableRefObject, useEffect, useRef } from 'react';

type RefType<T> =
  | ((instance: T | null) => void)
  | MutableRefObject<T | null>
  | null;

const useCombinedRefs = <T>(...refs: RefType<T>[]) => {
  const targetRef = useRef<T | null>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

export default useCombinedRefs;
