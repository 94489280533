export const MOBILE_NUMBER_GET_INIT = 'MOBILE_NUMBER_GET_INIT';
export const MOBILE_NUMBER_GET_SUCCESS = 'MOBILE_NUMBER_GET_SUCCESS';
export const MOBILE_NUMBER_GET_ERROR = 'MOBILE_NUMBER_GET_ERROR';

export const MOBILE_NUMBER_AUTHORIZE_INIT = 'MOBILE_NUMBER_AUTHORIZE_INIT';
export const MOBILE_NUMBER_AUTHORIZE_SUCCESS =
  'MOBILE_NUMBER_AUTHORIZE_SUCCESS';
export const MOBILE_NUMBER_AUTHORIZE_ERROR = 'MOBILE_NUMBER_AUTHORIZE_ERROR';

export const MOBILE_NUMBER_CONFIRM_INIT = 'MOBILE_NUMBER_CONFIRM_INIT';
export const MOBILE_NUMBER_CONFIRM_SUCCESS = 'MOBILE_NUMBER_CONFIRM_SUCCESS';
export const MOBILE_NUMBER_CONFIRM_ERROR = 'MOBILE_NUMBER_CONFIRM_ERROR';
export const MOBILE_NUMBER_AUTHORIZE_CANCELLED =
  'MOBILE_NUMBER_AUTHORIZE_CANCELLED';

export interface IMobileNumberAction {
  type:
    | typeof MOBILE_NUMBER_GET_INIT
    | typeof MOBILE_NUMBER_GET_SUCCESS
    | typeof MOBILE_NUMBER_GET_ERROR
    | typeof MOBILE_NUMBER_AUTHORIZE_INIT
    | typeof MOBILE_NUMBER_AUTHORIZE_SUCCESS
    | typeof MOBILE_NUMBER_AUTHORIZE_ERROR
    | typeof MOBILE_NUMBER_CONFIRM_INIT
    | typeof MOBILE_NUMBER_CONFIRM_SUCCESS
    | typeof MOBILE_NUMBER_CONFIRM_ERROR
    | typeof MOBILE_NUMBER_AUTHORIZE_CANCELLED;
  data?: {
    number?: string;
    verified?: boolean;
    message?: string;
    token?: string;
    retry?: boolean;
  };
}

export const mobileNumberGetInit = () => ({
  type: MOBILE_NUMBER_GET_INIT,
});

export const mobileNumberGetSuccess = (number: string, verified: boolean) => ({
  type: MOBILE_NUMBER_GET_SUCCESS,
  data: { number, verified },
});

export const mobileNumberGetError = (data = { message: 'Error' }) => ({
  type: MOBILE_NUMBER_GET_ERROR,
  data,
});

export const mobileNumberAuthorizeInit = () => ({
  type: MOBILE_NUMBER_AUTHORIZE_INIT,
});

export const mobileNumberAuthorizeSuccess = (
  number: string,
  verified: boolean,
) => ({
  type: MOBILE_NUMBER_AUTHORIZE_SUCCESS,
  data: { number, verified },
});

export const mobileNumberAuthorizeError = (data = { message: 'Error' }) => ({
  type: MOBILE_NUMBER_AUTHORIZE_ERROR,
  data,
});

export const mobileNumberConfirmInit = (token: string) => ({
  type: MOBILE_NUMBER_CONFIRM_INIT,
  data: { token },
});

export const mobileNumberConfirmSuccess = (
  number: string,
  verified: boolean,
) => ({
  type: MOBILE_NUMBER_CONFIRM_SUCCESS,
  data: { number, verified },
});

export const mobileNumberConfirmError = (data = { message: 'Error' }) => ({
  type: MOBILE_NUMBER_CONFIRM_ERROR,
  data,
});

export const mobileNumberAuthorizeCancelled = () => ({
  type: MOBILE_NUMBER_AUTHORIZE_CANCELLED,
});
