import * as React from 'react';

const SvgClipboard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.105 25.046H5.685c-.506 0-.948-.198-1.327-.594a1.938 1.938 0 01-.569-1.384V1.978c0-.528.19-.99.569-1.385C4.737.198 5.179 0 5.684 0h10.99L24 7.645v15.423c0 .527-.19.989-.568 1.384-.38.396-.822.593-1.327.593zM15.726 8.502V1.977H5.684v21.091h16.421V8.502h-6.379zM1.895 29c-.506 0-.948-.198-1.327-.593A1.938 1.938 0 010 27.023v-20.4h1.895v20.4H17.62V29H1.895zm3.79-27.023v6.525-6.525 21.091-21.09z"
      fill="#fff"
    />
  </svg>
);

export default SvgClipboard;
