import React, { useRef } from 'react';
import useCombinedRefs from 'utils/hooks/useCombinedRefs';
import useFocusingRef from 'utils/hooks/useFocusingRef';
import { StyledInput } from './Input.styled';
import IInput from './types';

const Input = React.forwardRef<HTMLInputElement, IInput>(
  (
    {
      oneDigitInput,
      error,
      type,
      isFocused,
      name,
      onChange = () => {},
      ...props
    },
    forwardedRef,
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const combinedRefs = useCombinedRefs<HTMLInputElement>(
      forwardedRef,
      innerRef,
    );

    useFocusingRef<HTMLInputElement>(innerRef, isFocused);

    const finalProps = {
      oneDigitInput,
      type: oneDigitInput ? 'number' : type,
      error,
      name,
      id: name,
      ref: combinedRefs,
      onChange,
      ...props,
    };
    if (oneDigitInput) {
      finalProps.maxLength = 1;
      finalProps.onChange = (event) => {
        if (event.target.value.length > 1) return;
        onChange(event);
      };
    }
    return <StyledInput {...finalProps} />;
  },
);

export default Input;
