import { breakPoints } from 'theme';
import { PaymentInitiationProviderTypes } from '../redux/types';
import { stringContainsSubstring } from './validation/helpers';

export enum AppEnv {
  Testing = 'Testing',
  Staging = 'Staging',
  Sandbox = 'Sandbox',
  Production = 'Production',
}

export enum hostEnv {
  Testing = 'person-onboarding.solaris-testing.de',
  Staging = 'person-onboarding.solaris-staging.de',
  Sandbox = 'person-onboarding.solaris-sandbox.de',
  Production = 'person-onboarding.solarisbank.de',
}

export enum ApiEnv {
  Testing = 'https://person-onboarding-api.solaris-testing.de',
  Staging = 'https://person-onboarding-api.solaris-staging.de',
  Sandbox = 'https://person-onboarding-api.solaris-sandbox.de',
  Production = 'https://person-onboarding-api.solarisbank.de',
}

export enum SentryDSN {
  Testing = 'https://3149bf1c07464d199eb03f5063294738@o617258.ingest.sentry.io/4505130772070400',
  Staging = 'https://52f6a0c0f5d04c6188e5c7337c7a5340@o617258.ingest.sentry.io/4505130772070400',
  Sandbox = 'https://0ab3dd26e28b48349d7ac608a561fec4@o617258.ingest.sentry.io/4505130772070400',
  Production = 'https://d430c1e5fe644c46918371588ab5efa1@o617258.ingest.sentry.io/4505130772070400',
}

export const appEnvironment = (): AppEnv => {
  const domain = window.location.host;

  switch (true) {
    case domain.includes(hostEnv.Staging):
      return AppEnv.Staging;
    case domain.includes(hostEnv.Sandbox):
      return AppEnv.Sandbox;
    case domain.includes(hostEnv.Production):
      return AppEnv.Production;
    default:
      return AppEnv.Testing;
  }
};

// PLACEHOLDER
// RESOLVED AND CLEAN WHEN WE HAVE MULTIPLE ENVS ACTUALLY RUNNING
export function environmentApi(): ApiEnv {
  return ApiEnv[appEnvironment()];
}

export function environmentSpecificSentryDSN(): SentryDSN {
  return SentryDSN[appEnvironment()];
}

export const resolvePaymentInitiationProvider = (
  url: string,
): PaymentInitiationProviderTypes => {
  if (stringContainsSubstring(url, 'fts-payment-initiation-wizard')) {
    return PaymentInitiationProviderTypes.FTS;
  }
  if (stringContainsSubstring(url, 'finleap.cloud')) {
    return PaymentInitiationProviderTypes.FLC;
  }
  return PaymentInitiationProviderTypes.NONE;
};

export function browserLang(): string {
  const supportedLangs = ['en', 'de'];
  const lang = navigator.language;
  const defaultLang = 'en';

  if (lang) {
    const langPrefixWithoutCountry = lang.split('-')[0];

    return supportedLangs.includes(langPrefixWithoutCountry)
      ? langPrefixWithoutCountry
      : defaultLang;
  } else {
    return defaultLang;
  }
}

export function detectBrowserWidth(): number {
  return Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  );
}

export function isMobile(viewPortWidth: number): boolean {
  return viewPortWidth <= breakPoints.md;
}
