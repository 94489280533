import { File } from 'redux/initialState/identificationFileInitialState';

export const LOAD_FILE_LIST = 'LOAD_FILE_LIST';
export const FILE_DOWNLOAD_INIT = 'FILE_DOWNLOAD_INIT';
export const FILE_SHARE_INIT = 'FILE_SHARE_INIT';
export const FILE_DOWNLOAD_SUCCESS = 'FILE_DOWNLOAD_SUCCESS';
export const FILE_SHARE_SUCCESS = 'FILE_SHARE_SUCCESS';
export const FILE_DOWNLOAD_ERROR = 'FILE_DOWNLOAD_ERROR';
export const FILE_SHARE_ERROR = 'FILE_SHARE_ERROR';

export interface IFileDownloadAction {
  type:
    | typeof LOAD_FILE_LIST
    | typeof FILE_DOWNLOAD_INIT
    | typeof FILE_SHARE_INIT
    | typeof FILE_DOWNLOAD_SUCCESS
    | typeof FILE_SHARE_SUCCESS
    | typeof FILE_DOWNLOAD_ERROR
    | typeof FILE_SHARE_ERROR;
  data?: {
    message?: string;
    files: File[];
    id?: string;
    fileName?: string;
    fileUrl: string;
  };
}

export const loadFileList = (files: File[]) => ({
  type: LOAD_FILE_LIST,
  data: { files },
});

export const fileDownloadInit = (id: string, fileName: string) => ({
  type: FILE_DOWNLOAD_INIT,
  data: { id, fileName },
});

export const fileShareInit = (id: string, fileName: string) => ({
  type: FILE_SHARE_INIT,
  data: { id, fileName },
});

export const fileDownloadSuccess = (id: string, fileUrl: string) => ({
  type: FILE_DOWNLOAD_SUCCESS,
  data: { id, fileUrl },
});

export const fileShareSuccess = (id: string) => ({
  type: FILE_SHARE_SUCCESS,
  data: { id },
});

export const fileDownloadError = (data = { message: 'Error' }, id: string) => ({
  type: FILE_DOWNLOAD_ERROR,
  data: { ...data, id },
});

export const fileShareError = (data = { message: 'Error' }, id: string) => ({
  type: FILE_SHARE_ERROR,
  data: { ...data },
});
