import styled from 'styled-components';

export const StyledCountdownTimer = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  line-height: 1.5;
  font-family: 'Roboto', Arial, sans-serif;
  letter-spacing: 0.005em;
  margin: 0;
  padding: 0.5rem 0;
  color: ${({ theme }) => theme.baseColors.base50};
`;

export const CountdownClock = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
