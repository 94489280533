import {
  CONFIG_INIT,
  configError,
  configSuccess,
} from 'redux/actions/configActions';
import { mobileNumberGetInit } from 'redux/actions/mobileNumberActions';
import { sessionInit } from 'redux/actions/sessionActions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  detectBrowserWidth,
  environmentApi,
  isMobile,
} from '../../../utils/environments';

export function* configureApplication(): SagaIterator {
  try {
    const hrefParams = window.location.href.split('/');
    const sessionToken = hrefParams[3];
    const showSkipForSession =
      new URLSearchParams(window.location.search).get('ssb') === 'true';
    const baseUrl = yield call(environmentApi);
    const viewPortWidth = yield call(detectBrowserWidth);

    if (!sessionToken || !baseUrl || !viewPortWidth) {
      throw new Error('invalidSessionOrEnv');
    }
    yield put(
      configSuccess({
        sessionToken,
        baseUrl,
        isMobile: isMobile(viewPortWidth),
        showSkip: showSkipForSession,
      }),
    );
    yield put(sessionInit());
    yield put(mobileNumberGetInit());
  } catch (error) {
    yield put(configError({ message: error.message }));
  }
}

export default function* watchconfigureApplicationInit() {
  yield takeLatest(CONFIG_INIT, configureApplication);
}
