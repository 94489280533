import { ApiErrorCode } from 'api/consts';
import { getIdent } from 'api/endpoints';
import {
  getIdentError,
  getIdentSuccess,
  identPollInit,
  identReset,
} from 'redux/actions/identificationActions';
import { loadFileList } from 'redux/actions/identificationFileActions';
import { localStorageReset } from 'redux/actions/localStorageActions';
import {
  navigateToBankIdentIban,
  navigateToBankIdentSigning,
  navigateToBankIdentStart,
  navigateToVideoIdentStart,
  navigateToVideoIdentVideo,
} from 'redux/actions/navigationActions';
import {
  IRecoverIdentificationActions,
  RECOVER_IDENT_AUTHORIZATION_REQUIRED,
  RECOVER_IDENT_CONFIRMATION_REQUIRED,
  RECOVER_IDENT_CONFIRMED,
  RECOVER_IDENT_EXPIRED,
  RECOVER_IDENT_FAILED,
  RECOVER_IDENT_PENDING,
  RECOVER_IDENT_PROCESS_INIT,
  RECOVER_IDENT_SUCCESSFUL,
  recoverIdentAuthorizationRequired,
  recoverIdentConfirmationRequired,
  recoverIdentConfirmed,
  recoverIdentExpired,
  recoverIdentFailed,
  recoverIdentPending,
  recoverIdentProcessError,
  recoverIdentProcessSuccess,
  recoverIdentSuccessful,
} from 'redux/actions/recoverIdentificationActions';
import { DocumentForSign } from 'redux/initialState/identificationInitialState';
import { configSelector, identificationSelector } from 'redux/selectors';
import { IdentificationMethod, IdentificationStatus } from 'redux/types';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { resolvePaymentInitiationProvider } from 'utils/environments';
import { snakeToCamelCase } from 'utils/formatters';

export function* recoverIdentificationInit(
  action: IRecoverIdentificationActions,
): SagaIterator {
  try {
    const { id } = action!.data!;

    const config = yield select(configSelector);
    const { baseUrl, sessionToken } = config;

    const { requestMethod, getUrl, getErrorCode } = getIdent;

    if (id) {
      const response = yield call(
        requestMethod,
        getUrl(baseUrl, id),
        sessionToken,
      );

      if (response.errors) {
        throw new Error(getErrorCode(response.errors));
      }

      if (response.documents && response.documents.length > 0) {
        yield put(
          loadFileList(
            response.documents.map((d: DocumentForSign) => ({
              id: d.id,
              downloadInProgress: false,
              errorMessage: '',
            })),
          ),
        );
      }

      const {
        status,
        method,
        iban,
        url,
        current_reference_token,
        reference,
        documents,
        failure_reason,
      } = response;

      const paymentInitiationProvider = resolvePaymentInitiationProvider(url);

      const errorMessage = failure_reason
        ? snakeToCamelCase(failure_reason)
        : '';
      const referenceValue = current_reference_token || reference;

      yield put(
        getIdentSuccess(
          id,
          status,
          method,
          paymentInitiationProvider,
          iban,
          url,
          documents,
          referenceValue,
          errorMessage,
        ),
      );
      switch (status) {
        case IdentificationStatus.PENDING:
          return yield put(recoverIdentPending());
        case IdentificationStatus.AUTHORIZATION_REQUIRED:
          return yield put(recoverIdentAuthorizationRequired());
        case IdentificationStatus.CONFIRMATION_REQUIRED:
          return yield put(recoverIdentConfirmationRequired());
        case IdentificationStatus.CONFIRMED:
          return yield put(recoverIdentConfirmed());
        case IdentificationStatus.SUCCESSFUL:
          return yield put(recoverIdentSuccessful());
        case IdentificationStatus.EXPIRED:
          return yield put(recoverIdentExpired());
        case IdentificationStatus.FAILED:
          return yield put(recoverIdentFailed());
        case IdentificationStatus.ABORTED:
          return yield put(recoverIdentFailed());
        default:
          return yield put(
            recoverIdentProcessError({
              errorMessage: 'unknown ident status encountered',
            }),
          );
      }
    }
  } catch (error) {
    const { message } = error;
    yield put(localStorageReset());
    if (message === snakeToCamelCase(ApiErrorCode.MODEL_NOT_FOUND)) {
      yield put(recoverIdentFailed());
    } else {
      yield put(getIdentError({ errorMessage: message }));
    }
  }
}

// the routines below outline the UI's dependencies per identification status, there are two things to keep in mind:
// 1. Any documents will have been loaded in recoverIdentificationInit saga
// 2. the App only has 2 pages related to identification, each of these pages dynamically handles multiple states
// StartIdentification = CREATED||PENDING
// SignDocument = AUTHORIZATION_REQUIRED||CONFIRMATION_REQUIRED||CONFIRMED|SUCCESSFUL
// Failure cases will reset the entire process

export function* pendingRoutine(): SagaIterator {
  // pending idents have no dependencies
  const { method } = yield select(identificationSelector);
  if (method === IdentificationMethod.BANK) {
    yield put(navigateToBankIdentIban());
  } else {
    yield put(navigateToVideoIdentVideo());
  }
  yield put(recoverIdentProcessSuccess());
}

export function* authorizationRequiredRoutine(): SagaIterator {
  // authorization_required has no indirect dependencies
  yield put(navigateToBankIdentSigning());
  yield put(recoverIdentProcessSuccess());
}

export function* confirmationRequiredRoutine(): SagaIterator {
  // confirmation_required has a dependency on mobile_number endpoint
  yield put(navigateToBankIdentSigning());
  yield put(recoverIdentProcessSuccess());
}

export function* confirmedRoutine(): SagaIterator {
  yield put(identPollInit());
  yield put(navigateToBankIdentSigning());
  yield put(recoverIdentProcessSuccess());
}

export function* failedRoutine(): SagaIterator {
  // if the retrieved ident is failed navigate to ident page where user is given retry options
  const { method } = yield select(identificationSelector);
  if (method === IdentificationMethod.BANK) {
    yield put(navigateToBankIdentStart());
  } else {
    yield put(navigateToVideoIdentStart());
  }
  yield put(recoverIdentProcessSuccess());
}

export function* expiredRoutine(): SagaIterator {
  // if the retrieved ident is exired we reset the process and clear localStorage
  yield put(localStorageReset());
  yield put(identReset());
  yield put(navigateToBankIdentStart());
  yield put(recoverIdentProcessSuccess());
}

export function* successfulRoutine(): SagaIterator {
  // if the retrieved ident is successful redirect to documentSigning to show success
  const { method } = yield select(identificationSelector);
  if (method === IdentificationMethod.BANK) {
    yield put(identPollInit());
    yield put(navigateToBankIdentSigning());
  } else {
    yield put(navigateToVideoIdentVideo());
  }
  yield put(recoverIdentProcessSuccess());
}

export function* watchRecoverIdentificationInit() {
  yield takeLatest(RECOVER_IDENT_PROCESS_INIT, recoverIdentificationInit);
}

export function* watchRecoverIdentAuthorizationRequired() {
  yield takeLatest(
    RECOVER_IDENT_AUTHORIZATION_REQUIRED,
    authorizationRequiredRoutine,
  );
}
export function* watchRecoverIdentConfirmationRequired() {
  yield takeLatest(
    RECOVER_IDENT_CONFIRMATION_REQUIRED,
    confirmationRequiredRoutine,
  );
}

export function* watchRecoverIdentConfirmed() {
  yield takeLatest(RECOVER_IDENT_CONFIRMED, confirmedRoutine);
}
export function* watchRecoverIdentPending() {
  yield takeLatest(RECOVER_IDENT_PENDING, pendingRoutine);
}
export function* watchRecoverIdentFailed() {
  yield takeLatest(RECOVER_IDENT_FAILED, failedRoutine);
}
export function* watchRecoverIdentExpired() {
  yield takeLatest(RECOVER_IDENT_EXPIRED, expiredRoutine);
}
export function* watchRecoverIdentSuccessful() {
  yield takeLatest(RECOVER_IDENT_SUCCESSFUL, successfulRoutine);
}
