import styled from 'styled-components';

export const FieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

export const FieldLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.baseColors.base75};
`;

export const FieldInfo = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.baseColors.base50};
`;

export const FieldErrorMessage = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.baseColors.error};
  margin-top: 0.5rem;
`;
